import httpClient from './httpClient';

const prefix = 'core'

export default {
    async getClaimExpense(){
        const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/claim_expense`)
        return data
    },
    async isReadClaimExpense(){
        const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/claim_expense/is_read`)
        return data
    },
    async isReadClaimExpenseId(id){
        const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/claim_expense/is_read/`+id);
        return data
    },
    async unActiveNotification (body){
        const response = await httpClient({ requiresAuth: true }).put(`${prefix}/unactive`, body);

        return response
    }
}
