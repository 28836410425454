import { layouts } from "chart.js";

let car_fields = {
    title: {
        create: 'เพิ่มรถ',
        edit: 'แก้ไขรถ'
    },
    inputs: {
        divider_1: {
            input_type: 'divider',
            label: 'ข้อมูลรถ',
            col_span: 23
        },
        company_3rd: {
            input_type: 'checkbox',
            label_key: 'name',
            required: false,
            col_span: 2
        },
        car_company_id: {
            input_type: 'select',
            search_select: true,
            modelname: 'CarCompany',
            label_key: 'fullname',
            // placeholder: 'บริษัทรถ',
            // required: true,
            required: false,
            col_span: 21
        },
        car_type_id: {
            input_type: 'select',
            search_select: true,
            modelname: 'CarType',
            label_key: 'name',
            placeholder: 'ชนิดรถ',
            // required: true,
            required: true,
            col_span: 11
        },
        car_no: {
            input_type: 'text',
            placeholder: 'เบอร์รถ',
            // required: true,
            col_span: 12
        },
        plate_no: {
            input_type: 'text',
            placeholder: 'เลขทะเบียนรถ',
            // required: true,
            required: true,
            col_span: 11
        },
        driver_id: {
            input_type: 'select',
            search_select: true,
            modelname: 'Driver',
            label_key: 'firstname',
            label_function: (driver) => {
                return `${driver.firstname} ${driver.lastname}`},
            placeholder: 'คนขับรถ',
            search_select: true,
            // required: true,
            required: false,
            col_span: 12
        },
        car_brand: {
            input_type: 'text',
            placeholder: 'ยี่ห้อรถ',
            // required: true,
            required: false,
            col_span: 8
        },
        car_model: {
            input_type: 'text',
            placeholder: 'รุ่นรถ',
            // required: true,
            required: false,
            col_span: 7
        },
        chassis_number: {
            input_type: 'text',
            placeholder: 'เลขตัวถัง',
            // required: true,
            required: false,
            col_span: 8
        },
        car_source_id: {
            input_type: 'select',
            search_select: true,
            modelname: 'CarSource',
            label_key: 'name',
            placeholder: 'หมวดหมู่รถ',
            required: false,
            col_span: 11
        },
        car_payment: {
            input_type: 'text',
            placeholder: 'ค่างวดรถ',
            // required: true,
            required: false,
            col_span: 12
        },
        number_car_installments: {
            input_type: 'text',
            placeholder: 'จำนวนงวด',
            // required: true,
            required: false,
            col_span: 11
        },
        final_installment: {
            input_type: 'date',
            placeholder: 'ชำระงวดสุดท้าย',
            // required: true,
            required: false,
            col_span: 12
        },
        topic_truck_size: {
        },
        car_width: {
            input_type: 'text',
            placeholder: 'กว้าง',
            // required: true,
            required: false,
            col_span: 2
        },
        car_length: {
            input_type: 'text',
            placeholder: 'ยาว',
            // required: true,
            required: false,
            col_span: 2
        },
        car_height: {
            input_type: 'text',
            placeholder: 'สูง',
            // required: true,
            required: false,
            col_span: 2
        },
        loadable_weight: {
            input_type: 'text',
            placeholder: 'น้ำหนักที่บรรทุกได้ (หน่วยเป็นตัน)',
            // required: true,
            required: false,
            col_span: 11
        },
        head_registration: {
            input_type: 'text',
            placeholder: 'ทะเบียนหัว',
            // required: true,
            required: false,
            col_span: 11
        },
        tail_registration: {
            input_type: 'text',
            placeholder: 'ทะเบียนหาง',
            // required: true,
            required: false,
            col_span: 12
        },
        remark: {
            input_type: 'textarea',
            placeholder: 'รายละเอียดรถเพิ่มเติม',
            required: false,
            col_span: 23
        },
        topic_document: {
            input_type: 'sub-divider',
            label: 'เอกสารที่เกี่ยวข้อง',
            col_span: 23
        },
        car_book_page: {
            input_type: 'file',
            label: 'หน้าเล่มรถ',
            col_span: 10,
            layout: 'vertical'
        },
        car_pic: {
            input_type: 'file',
            label: 'รูปรถ',
            col_span: 10,
            layout: 'vertical'
        },
        // car_group_id: {
        //     input_type: 'sub_select',
        //     placeholder: 'ประเภทที่นั่งรถ',
        //     modelname: 'CarGroup',
        //     label_key: 'name',
        //     parent: {
        //         list: 'CarType',
        //         sub_list_name: 'car_groups',
        //         key: 'car_type_id'
        //     },
        //     // required: true,
        //     col_span: 11,
        // },
        // car_year: {
        //     input_type: 'text',
        //     placeholder: 'ปีรถ',
        //     // required: true,
        //     required: false,
        //     col_span: 11
        // },
        // parking_id: {
        //     input_type: 'select',
        //     search_select: true,
        //     modelname: 'Parking',
        //     label_key: 'name',
        //     placeholder: 'ที่จอดรถ',
        //     // required: true,
        //     required: false,
        //     col_span: 11
        // },
    }
};

export default car_fields