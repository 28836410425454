<template>
  <div style="padding: 15px; height: 100vh;">
    <div class="app-content">
    <a-card title="แชทพนักงานขับรถ" style="display: flex; flex-direction: column;">
      <a-row style="margin-bottom: 2rem; display:flex; align-items:center">
        <a-input
        v-model:value="driverName"
        placeholder="ค้นหาแชทพนักงานขับรถ"
        style="width: 20%;"
        @keyup.enter="findDriversByName"
        />
        <a-button type="primary" @click="findDriversByName">ค้นหา</a-button>
        <button @click="this.isFilterUsersByRead = !this.isFilterUsersByRead" :class="{'unread-active': isFilterUsersByRead, 'unread-inactive': !isFilterUsersByRead}" >
          <a-badge :dot="true" placement="start" status="error" :offset="[-4, 8]" text="!">
            <MessageOutlined :style="{ color: isFilterUsersByRead ? 'white' : 'black' }" />
          </a-badge>
          ยังไม่ได้อ่าน</button>
        <!-- <a-button @click="filterUsersByRead" danger>ยังไม่ได้อ่าน</a-button> -->
      </a-row>
      <a-row>
        <a-col :span="6" style="overflow-y: auto; height: 100%;">
          <Chatlist :users="filteredUsers" @userSelected="handleUserSelected" />
        </a-col>
        <a-col :span="18" style="height: 100%;">
          <ChatUser
            v-if="selectedUser"
            :targetUser="selectedUser"
            :socket="socketService"
          />
        </a-col>
      </a-row>
    </a-card>
  </div>
  </div>
</template>

<script>
import DriversChannelService from '../../api/DriversChannelService';
import Chatlist from '../../components/chat_list.vue';
import ChatUser from '../../components/chat_user.vue';
import socketService from '../../api/SocketService';
import { MessageOutlined } from '@ant-design/icons-vue';

export default {
  name: 'Chat',
  components: {
    ChatUser,
    Chatlist,
    MessageOutlined
  },
  data() {
    return {
      driverName: '',
      users: [],
      isFilterUsersByRead:false,
      selectedUser: null,
      socketService,
    };
  },
  mounted() {
    this.connectToSocket();
    this.fetchAllDrivers();
  },
  watch:{
    driverName:{
      immediate: false,
      handler: 'findDriversByName',
    },
    users:{
      immediate: false,
      handler(newUsers) {
        if (this.selectedUser && !newUsers.find(user => user.id === this.selectedUser.id)) {
          this.selectedUser = null
        }
      }
    }
  },
  computed:{
    filteredUsers() {
      if (this.isFilterUsersByRead) {
        return this.users.filter(user => user.latestMessage && !user.latestMessage.isRead && user.latestMessage.sentBy.role === 'Driver');
      }
      return this.users;
    }},
  methods: {
    async findDriversByName() {
      try {
        if (!this.driverName.trim()) {
          await this.fetchAllDrivers();
        } else {
          const response = await DriversChannelService.getAllDriversChannelWithDriverByName(this.driverName);
          this.users = response;
        }
      } catch (error) {
        console.error('Error while searching for drivers:', error);
      }
    },
    async fetchAllDrivers() {
      try {
        const response = await DriversChannelService.getAllDriversChannelWithDriver();
        this.users = response
      } catch (error) {
        console.error('Error while fetching drivers:', error);
      }
    },
    handleUserSelected(user) {
      this.selectedUser = user;
    },
    connectToSocket() {
      this.socketService.connect();
      this.socketService.onBroadcast((message) => {
        if(message.type == 'send'){
          const userIndex = this.users.findIndex((user) => user.channel_id === message.data.channelId);
          if (userIndex !== -1) {
            const updatedUser = { ...this.users[userIndex], latestMessage: message.data };
            this.users.splice(userIndex, 1);
            this.users.unshift(updatedUser);
          }
        } else if (message.type == 'mark'){
          const userIndex = this.users.findIndex((user) => user.latestMessage.messageId === message.data.messageId);
          if (userIndex !== -1) {
            this.users[userIndex].latestMessage.isRead = message.data.isRead;
          }
        }
      });
    },
    disconnectFromSocket() {
      this.socketService.disconnect();
    },
  },
};
</script>
<style scoped>
.unread-inactive {
  background-color: white;
  color: black;
  margin-left: 1rem;
  border-width: 1px;
  border-radius: 0.5rem;
  border-color: black;
  height: 32px;
}
.unread-active{
  background-color: black;
  color: white;
  margin-left: 1rem;
  border-width: 1px;
  border-radius: 0.5rem;
  border-color: white;
  height: 32px;
}
</style>
