
import httpClient from './httpClient';
const prefix = 'core'

export default {
  async bookingStatus(company_id) {
    const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/bookings/status/${company_id}`)
    return data
  },
  async bookingCarStatus(company_id, business) {
    const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/booking-cars/status/${company_id}`,{ params: { business }, });
    return data;
},
  async get_client_no() {
    const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/client-no`)

    return data
  },
  async check_duplicate_username(modelname, query) {
    const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/check-duplicate-username/${modelname}?${query}`)

    return data
  },
  async check_duplicate(modelname, query) {
    const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/check-duplicate/${modelname}?${query}`)

    return data
  },
  async get_all (modelname, query) {
    const { data } = await httpClient({ requiresAuth: true }).get(`/all/${modelname}${query? '?'+query : ''}`)

    return data
  },
  async get_core (modelname, query) {
    const { data } = await httpClient({ requiresAuth: true }).get(`/core/${modelname}${query? '?'+query : ''}`)

    return data
  },
  async get_id (modelname, id) {
    const { data } = await httpClient({ requiresAuth: true }).get(`/id/${modelname}?id=${id}`)

    return data
  },
  async create_all (modelname, body) {
    const data  = await httpClient({ requiresAuth: true }).post(prefix+`/all/${modelname}`, body)

    return data
  },
  async update_all (modelname, body, id) {
    const data = await httpClient({ requiresAuth: true }).put(prefix+`/all/${modelname}/${id}`, body)

    return data
  },
  async delete_all (modelname, id) {
    const { data } = await httpClient({ requiresAuth: true }).delete(prefix+`/all/${modelname}/${id}`)

    return data
  },
  async delete (modelname, id) {
    const { data } = await httpClient({ requiresAuth: true }).delete(prefix+`/${modelname}/${id}`)

    return data
  },
  async resource_car () {
    const { data } = await httpClient({ requiresAuth: true }).get('/cars')

    return data
  },
  async places( query ) {
    const { data } = await httpClient({ requiresAuth: true }).get(`/places?name=${query}`)

    return data
  },
  async savePassword(body) {
		const { data } = await httpClient({ requiresAuth: true }).post(prefix + `/save_password`, body);

		return data;
	},
  async updateColorById(id) {
    const { data } = await httpClient({ requiresAuth: true }).put(prefix +`/colors/${id}`);
    return data;
  },
  async getCardId() {
    const { data } = await httpClient({ requiresAuth: true }).get(prefix +`/cardId`);
    return data;
  },
}