import httpClient from './httpClient';

const prefix = 'accounting'

export default {
    async getAccountingAll () {
        const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/getAccountingAll`)
        return dataAccounting
    },
    async getAccountingByCompany () {
        const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/getAccountingByCompany`)
        return data
    },
    async getAccountingByCompanyAndId (id) {
        const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/getAccountingByCompanyAndId/${id}`)
        return data
    },

    async getFromIdWithRelationById (id) {
        const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/getFromIdWithRelationById?id=${id}`)
        return data
    },

    async createAccounting (body) {
        const { data } = await httpClient({ requiresAuth: true }).post(`${prefix}/createAccounting`, body)
        return data
    },

    async updateAccountingPermissionById (body, id) {
        const { data } = await httpClient({ requiresAuth: true }).put(`${prefix}/updateAccountingPermissionById/${id}`, body)
        return data
    },

    async updateAccounting (body, id) {
        let payload = {
            data: body.data[0]
        }
        const { data } = await httpClient({ requiresAuth: true }).put(`${prefix}/updateAccounting/${id}`,payload)
        return data
    },

    async updateAccountingIsActiveById (body, id) {
        const { data } = await httpClient({ requiresAuth: true }).put(`${prefix}/updateAccountingIsActiveById/${id}`, body)
        return data
    },

    async deleteAccounting (id) {
        const { data } = await httpClient({ requiresAuth: true }).delete(`${prefix}/deleteAccounting/${id}`)
        return data
    }
}