
<template>
        
    <div style="height: 100vh;padding: 15px;">
            <div class="app-content">
            <a-card>
                <a-row v-if="!business" style="justify-content: space-between;">
                    <h3>จัดการหมายเหตุ</h3>

                    <a-row style="justify-content: space-between">
                        <a-button v-if="editable" @click="add_remark_type()" style="margin-right: 20px;" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">Add</span></template>
                            เพิ่มประเภทหมายเหตุ
                        </a-button>

                        <a-button v-if="editable" @click="add_remark()" style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">Add</span></template>
                            เพิ่มหมายเหตุ
                        </a-button>
                    </a-row>
                </a-row>
                <a-row v-if="business" style="justify-content: space-between;">
                    <h3>จัดการหมายเหตุ</h3>
                </a-row>
            </a-card>
            <a-row v-if="business" style="display: flex; justify-content: flex-end; margin-bottom: 16px; margin-top: 16px;">
                    <a-button v-if="editable" @click="add_remark_type()" style="margin-right: 20px;" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">Add</span></template>
                            เพิ่มประเภทหมายเหตุ
                        </a-button>

                        <a-button v-if="editable" @click="add_remark()" style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">Add</span></template>
                            เพิ่มหมายเหตุ
                        </a-button>
                    </a-row>
            <a-row style="margin-top: 20px;">
                <div v-for="({ id, remark_name, flag_check }, i) in remark_types">
                    <div v-if="flag_check == 'active'" >
                        <a-table 
                            :columns="columns" :data-source="getRemarkList(this.remark_list_by_type[i])" bordered size="small"
                            :scroll="{ x: 'calc(100px + 50%)', y: '100%' }" 
                            :pagination="{ pageSize: 5 }">

                            <template #headerCell="{ column }">
                                <template v-if="column.key === 'remark'">
                                    <a style="font-size: 15px; color: black;align-items: center; text-align: center;">ประเภทหมายเหตุ : {{ remark_name }}</a>
                                </template>

                                    <template v-if="column.key === 'edit'">
                                        <a @click="edit_remark_type(id,remark_name)"><span style="font-size: 18px; color: red;" class="material-symbols-outlined">drive_file_rename_outline</span></a>
                                    </template>
                                    <template v-if="column.key === 'delete'">
                                        <a @click="remove_remark(id,remark_name)"><span style="font-size: 18px; color: red;" class="material-symbols-outlined">delete</span></a>
                                    </template>
                            </template>

                            <template #bodyCell="{ column, record, index }">
                                <template v-if="column.key === 'id'">
                            </template>

                                <template v-if="column.key === 'edit'">
                                    <a @click="edit_remark(record.id, record)"><span style="font-size: 18px" class="material-symbols-outlined">drive_file_rename_outline</span></a>
                                </template>
                                <template v-if="column.key === 'delete'">
                                    <a @click="remove_remark(record.id,'remark',record.remark_type)"><span style="font-size: 18px" class="material-symbols-outlined">delete</span></a>
                                </template>

                                <template v-else-if="column.key === 'is_default'">
                                    {{record.is_default ? 'ใช่' : 'ไม่ใช่'}}
                                </template>
                                <template v-else-if="column.key === 'position'">
                                    <!-- sort up, down icon button  -->
                                    <span>
                                        <a class="material-symbols-outlined" @click="moveDown(record,i)"> 
                                            arrow_downward
                                        </a>
                                        <a class="material-symbols-outlined" @click="moveUp(record,i)"> 
                                            arrow_upward
                                        </a>
                                        <!-- {{ record.index }} -->
                                    </span>
                                </template> 
                            </template>
                        </a-table>
                    </div>
                </div>
            </a-row>

            

        </div>

        <a-drawer
            :visible="show_add_remark"
            :closable="true"
            title="เพิ่มหมายเหตุ"
            placement="right"
            @close="onClose"
        >
            <a-form :form="create_remark">
                <a-form-item style="margin:0px;" label="เป็นค่าเริ่มต้น" name="is_default" />
                <a-switch v-if="create_remark.is_default == 1" v-model:checked="checked"/>
                <a-switch v-else v-model:checked="create_remark.is_default"/>

                <a-form-item  style="margin: 0px" label="ประเภทหมายเหตุ" name="is_default"
                    :rules="[
                        {
                            required: true,
                            message: 'กรุณาเลือกประเภทหมายเหตุ',
                        },
                    ]"
                />
               
                <a-select style="width: 330px;" v-model:value="create_remark.remark_type" placeholder="ประเภทหมายเหตุ">
                    <a-select-option
                        v-for="({ id, remark_name }, i) in remark_types"
                        :key="i"
                        :value="id"
                        >{{ remark_name }}
                        </a-select-option>
                </a-select>

                <a-form-item  style="margin: 0" label="หมายเหตุ" name="is_default"
                    :rules="[
                        {
                            required: true,
                            message: 'กรุณากรอกหมายเหตุ',
                        },
                    ]"
                />
                <a-textarea v-model:value="create_remark.remark" placeholder="หมายเหตุ" />
                <a-row justify="end">
                    <a-button style="margin: 5px 0" type="primary" @click="remark_submit()">บันทึก</a-button>
                </a-row>
            </a-form>
        </a-drawer>


        <a-drawer
            :visible="show_add_remark_type"
            :closable="true"
            title="เพิ่มประเภทหมายเหตุ"
            placement="right"
            @close="onClose"
        >
            <a-form :form="create_remark_type">
                <a-input 
                    name="remark_name" 
                    placeholder="ประเภทหมายเหตุ"  
                    v-model:value="create_remark_type.remark_name"
                    />
                <a-row justify="end">
                    <a-button style="margin: 5px 0; margin-top: 15px;" type="primary" @click="remark_submit()">บันทึก</a-button>
                </a-row>
            </a-form>
        </a-drawer>

    </div>
</template>
<script>
import BookingService from '../../api/BookingService';
import Swal from 'sweetalert2';
import SystemService from '../../api/SystemService';
import Utility from '../../utility'
import dragula from "dragula";
import "dragula/dist/dragula.css";
import UserService from '../../api/UserService';
import CompanyService from '../../api/CompanyService';
export default {
    name: 'Booking-Remark',
    data () {
        return {
            editable: Utility.get_permission_editable_with_key('quotation'),
            checked: true,
            search_input: null,
            remarks_list: [],
            remark_types: [],
            remark_list_by_type: [],
            show_add_remark: false,
            show_add_remark_type: false,
            is_edit: false,
            business: false,
            is_edit_type: false,
            create_remark: {
                is_default: false,
                remark: null,
                company_id: JSON.parse(localStorage.getItem('companyData')).id
            },
            create_remark_type: {
                remark_name: null,
                flag_check: 'active',
                company_id: JSON.parse(localStorage.getItem('companyData')).id
            },
            text:{
                0: 'ไม่เป็นค่าเริ่มต้น',
                1: 'เป็นค่าเริ่มต้น'
            },
            columns: [{
                title: '',
                colSpan: 0,
                dataIndex: 'position',
                key: 'position',
                width: 100,
                align: 'center',
            },{
                colSpan: 2,
                dataIndex: 'remark',
                key: 'remark',
                width: 800,
               
            },{
                title: 'เป็นค่าเริ่มต้น',
                rowSpan: 2,
                dataIndex: 'is_default',
                key: 'is_default',
                align: 'center',
                width: 100
            },{
                title: 'ตัวเลือก',
                width: 100,
                align: 'center',
                children: [
                    {
                        title: 'แก้ไข',
                        key: 'edit',
                        align: 'center',
                    },
                    {
                        title: 'ลบ',
                        key: 'delete',
                        align: 'center',
                    },
                ]
            }],
        }
    },
    methods: {
        async get_company() {
			const company = await UserService.get_user_profile();
            const businessType = await CompanyService.getBusinessId(company.company_id)
            if ( businessType.data === 2)
            {
                this.business = true
            }
        },
        filter_columns(columns){
            return columns.filter((c)=> !this.editable ? c.key != 'id' : true)
        },
        async remove_remark(id,type = "remark",type_id) {
            let message = type == "remark" ? 'หมายเหตุ' : 'ประเภทหมายเหตุ'
            Swal.fire({
                title: `คุณต้องการลบ${message}นี้ใช่หรือไม่?`,
                text: `คุณจะไม่สามารถกู้คืน${message}นี้ได้`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: `ใช่, ลบ${message}นี้!`,
                cancelButtonText: 'ยกเลิก'
            }).then((result) => {
                if (result.isConfirmed) {
                    if(type != "remark"){
                        let editData = {
                            remark_name: type,
                            flag_check: 'inactive'
                        }
                        SystemService.update_all('BookingRemarkType', { data: editData }, id).then(() => {
                            this.init_remarks()
                            Swal.fire(
                                'ลบประเภทหมายเหตุสำเร็จ!',
                                'ประเภทหมายเหตุของคุณถูกลบแล้ว',
                                'success'
                            )
                        })
                    }else{
                        BookingService.delete_remark(id).then(() => {
                            BookingService.update_remark_index(type_id).then(() => {
                                this.init_remarks()
                                Swal.fire(
                                    'ลบหมายเหตุสำเร็จ!',
                                    'หมายเหตุของคุณถูกลบแล้ว',
                                    'success'
                                )
                            })
                        })
                    }
            }
            })
        },
        onClose(){
            if(this.show_add_remark_type == true){
                this.show_add_remark_type = false
                this.create_remark_type.remark_name = null
            }else if(this.show_add_remark == true){
                this.show_add_remark = false
                this.is_edit = false
                this.create_remark = {
                    is_default: false,
                    remark: null,
                    company_id: JSON.parse(localStorage.getItem('companyData')).id
                }
            }
            this.init_remarks()
        },
        add_remark_type(type = 'remark') {
            this.show_add_remark_type = true
        },
        add_remark(type = 'remark') {
            this.show_add_remark = true
        },
        edit_remark_type(id, record) {
            this.show_add_remark_type = true
            this.is_edit_type = id
            this.create_remark_type.remark_name = record
        },
        edit_remark(id, record) {
            this.show_add_remark = true
            this.is_edit = id
            this.create_remark = record
            this.checked = record.is_default == 1 ? true : false
            
        },
        async remark_submit() {
            let is_success = false;
            if (this.is_edit) { // edit remark
                if (this.create_remark.remark != '') {
                    this.create_remark.is_default = this.checked ? 1 : 0;
                    await SystemService.update_all('BookingRemark', { data: this.create_remark }, this.is_edit);
                    is_success = true;
                    this.is_edit = false;
                    this.show_add_remark = false;
                }
            } else if (this.is_edit_type) { // edit remark type
                if (this.create_remark_type.remark_name && this.create_remark_type.remark_name.trim() != '') {
                    await SystemService.update_all('BookingRemarkType', { data: this.create_remark_type }, this.is_edit_type);
                    is_success = true;
                    this.show_add_remark_type = false;
                    this.create_remark_type.remark_name = null;
                }
            } else { // create remark or remark type
                if (this.show_add_remark == true) { // create remark
                    if (this.create_remark.remark != null && this.create_remark.remark_type != undefined) {
                        let filter_type = this.remarks_list.filter((item) => item.remark_type == this.create_remark.remark_type); // filter by type and count item
                        this.create_remark.index = filter_type.length; // set index
                        this.create_remark.is_default = this.checked ? 1 : 0;
                        await SystemService.create_all('BookingRemark', { data: [this.create_remark] });
                        is_success = true;
                        this.show_add_remark = false;
                        this.create_remark = {
                            is_default: false,
                            remark: null,
                            company_id: JSON.parse(localStorage.getItem('companyData')).id
                        };
                    }
                } else if (this.show_add_remark_type == true) { // create remark type
                    if (this.create_remark_type.remark_name && this.create_remark_type.remark_name.trim() != '') {
                        await SystemService.create_all('BookingRemarkType', { data: [this.create_remark_type] });
                        is_success = true;
                        this.show_add_remark_type = false;
                        this.create_remark_type.remark_name = null;
                    }
                }
            }

            if (is_success) {
                Swal.fire({
                    icon: 'success',
                    title: 'เพิ่ม/แก้ไขหมายเหตุสำเร็จ',
                    showConfirmButton: false,
                    timer: 1500
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
                    showConfirmButton: false,
                    timer: 1500
                });
            }
            this.init_remarks();
        },
        getRemarkList(list){
            return list?.sort((a, b) => {
                return a.index - b.index
            })
        },
        async setIndex(){
            this.remark_types = await SystemService.get_core('getBookingRemarkType')
            this.init_remarks()
        },
        async init_remarks() {
            this.remarks_list = await BookingService.get_remarks();
            this.remark_types = await SystemService.get_core('getBookingRemarkType')
            this.remark_types = this.remark_types.filter((item) => item.flag_check == 'active')

            this.remark_list_by_type = []
            for(let i = 0 ; i < this.remark_types.length; i++){
                let list = this.remarks_list.filter((item) => {
                    return item.remark_type == this.remark_types[i].id
                })
                this.remark_list_by_type.push(list)
            }
        },
        async moveUp(record,type){
            const find_index = this.remark_list_by_type[type].findIndex((item) => item.id == record.id)
            if(find_index != 0){
                let old_list = JSON.parse(JSON.stringify(this.remark_list_by_type[type]))

                this.remark_list_by_type[type][find_index].index = this.remark_list_by_type[type][find_index].index - 1
                this.remark_list_by_type[type][find_index - 1].index = this.remark_list_by_type[type][find_index - 1].index + 1
            
                var new_list = this.remark_list_by_type[type].filter((item, i) => {
                    return item.index != old_list[i].index
                })
    
                for(let i = 0; i < new_list.length; i++){
                    await SystemService.update_all('BookingRemark', { data: { index: new_list[i].index } }, new_list[i].id)
                }
            }
        },
        async moveDown(record,type){
            const find_index = this.remark_list_by_type[type].findIndex((item) => item.id == record.id)
            if(find_index != this.remark_list_by_type[type].length - 1){
                let old_list = JSON.parse(JSON.stringify(this.remark_list_by_type[type]))
                this.remark_list_by_type[type][find_index].index = this.remark_list_by_type[type][find_index].index + 1
                this.remark_list_by_type[type][find_index + 1].index = this.remark_list_by_type[type][find_index + 1].index - 1
                
                var new_list = this.remark_list_by_type[type].filter((item, i) => {
                    return item.index != old_list[i].index
                })

                for(let i = 0; i < new_list.length; i++){
                    await SystemService.update_all('BookingRemark', { data: { index: new_list[i].index } }, new_list[i].id)
                }
            }
        },
        getIndexInParent(el) {
            return Array.from(el.parentNode.children).indexOf(el);
        },
    },
    mounted() {
        this.setIndex()
        this.get_company();
        // this.init_remarks()

    }
}
</script>

<style>
</style>