import httpClient from './httpClient';

const prefix = 'core'

export default {
    async getLeaveDriver(type){
        const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/leave/${type}`)
        return data
    },
    async isReadLeaves(){
        const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/leaves/is_read`)
        return data
    },
    async isReadLeaveId(id){
        const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/leaves/is_read/`+id)
        return data
    }
}
