import httpClient from './httpClient';

const prefix = 'core'

export default {
    async getProjectAll(){
        const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/project-all`)
        return data
    },

    async getProjectTypeAll(){
        const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/projectTypeAll`)
        return data
    },

    async getOillRangeAll(){
        const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/oilRangesAll`)
        return data
    },

    async getProjectById(id){
        const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/project/`+id)
        return data
    },

    async createProject(body){
        const { data } = await httpClient({ requiresAuth: true }).post(`${prefix}/createProject`,body)
        return data
    },

    async updateProject(body,id){
        const { data } = await httpClient({ requiresAuth: true }).put(`${prefix}/updateProject/${id}`,body)
        return data
    }
}
