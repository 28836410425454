<template>
    <div style="padding: 15px;height: 100vh">
        <div v-if="render_type != 'edit_user_admin'" class="app-content">
            <a-spin :spinning="loading">
                <a-card :title="title">
                    <template #extra>
                        <a-space>
                            <router-link :to="`/app/project/create`">
                                <a-button v-if="button_title" style="display: flex;"
                                    type="primary" size="large">
                                    <template #icon
                                    ><span class="material-symbols-outlined">Add</span></template
                                    >
                                    {{ button_title }}
                                </a-button>
                            </router-link>
                            <a-button ref="import_file" @click="export_file()">
                                <span class="material-symbols-outlined">download</span>Export
                            </a-button>
                        </a-space>
                    </template>

                    <a-row>
                        <a-space>
                            <a-space>
                            <a-input-search v-if="input" v-model:value="search_input"
                                :placeholder="`ค้นหาโปรเจกต์`" enter-button="ค้นหา" style="width: 260px;"/>
                            </a-space>                            
                        </a-space>
                        <a-button @click="clear_search" type="dashed" style="margin-left: 1rem">CLEAR</a-button>
                    </a-row>

                    <br>
                    <a-row>
                        <a-table :columns="columns" :data-source="filtered_list(projectList)" bordered size="small" :scroll="{ x: 'calc(100px + 50%)', y: '100%' }">
                            <template #bodyCell="{ column, record }">
                                <template v-if="column.key === 'project_no'">
                                    <div> {{ record.project_no }} </div>
                                </template>
                                <template v-if="column.key === 'client_id'">
                                    <div> {{ getClientName(record.client_id) }} </div>
                                </template>
                                <template v-if="column.key === 'project_type_id'">
                                    <div> {{ getProjectTypeName(record.project_type_id) }} </div>
                                </template>
                                <template v-if="column.key === 'booking_type_id'">
                                    <div> {{ getBookingTypeName(record.booking_type_id) }} </div>
                                </template>
                                <template v-if="column.key === 'price_type_id'">
                                    <div> {{ getPriceTypeName(record.price_type_id) }} </div>
                                </template>
                                <template v-if="column.key === 'id'">
                                    <div>
                                        <a-tooltip>
                                            <template #title>แก้ไข</template>
                                            <a @click="go_to_edit(record.id)"
                                            ><span style="font-size: 14px" class="material-symbols-outlined"
                                                >drive_file_rename_outline</span
                                            ></a
                                            >
                                        </a-tooltip>
                                        <a-divider type="vertical"></a-divider>
                                        <a @click="remove_project_user(record.id)">
                                            <span class="material-symbols-outlined">
                                                delete
                                            </span>
                                        </a>
                                    </div>
                                </template>
                            </template>
                        </a-table>
                    </a-row>
                    <a-modal v-model:visible="showModalLeaveUserData" @ok="showModalLeaveUserData=false" >
                        <div style="display: flex; flex-direction: column ;justify-items:start;align-items: start">
                            <a-form-item label="ชื่อ-นามสกุล ผู้ส่งคำขอ" name="leave_user_no" style="width: 98%; margin-top: 5%;">
                                <a-input :value="selectedLeave.user_name" placeholder="ชื่อ-นามสกุล ผู้ส่งคำขอ"></a-input>
                            </a-form-item>
                            <a-form-item label="เลขกำกับใบลา" name="leave_user_no" style="width: 98%;">
                                <a-input :value="selectedLeave.leave_user_no" placeholder="เลขกำกับใบลา"></a-input>
                            </a-form-item>
                            <a-form-item label="วันที่แจ้งลา" name="leave_user_no" style="width: 98%;">
                                <a-input :value="render_date(selectedLeave.leave_user_timestamp)" placeholder="วันที่แจ้งลา"></a-input>
                            </a-form-item>
                            <a-form-item label="วันที่ลาเริ่มต้น" name="leave_user_no" style="width: 98%;">
                                <a-input :value="render_date(selectedLeave.time_start)" placeholder="วันที่ลาเริ่มต้น"></a-input>
                            </a-form-item>
                            <a-form-item label="วันที่ลาสิ้นสุด" name="leave_user_no" style="width: 98%;">
                                <a-input :value="render_date(selectedLeave.time_end)" placeholder="วันที่ลาสิ้นสุด"></a-input>
                            </a-form-item>
                            <a-form-item label="จำนวนวันลา" name="leave_user_no" style="width: 98%;">
                                <a-input :value="selectedLeave.leave_user_amount" placeholder="จำนวนวันลา"></a-input>
                            </a-form-item>
                            <a-form-item label="ประเภทวันลา" name="leave_user_no" style="width: 98%;">
                                <a-input :value="getProjectTypeName(selectedLeave.leave_type_id)" placeholder="ประเภทวันลา"></a-input>
                            </a-form-item>
                            <a-form-item label="การอนุมัติ" name="leave_user_no" style="width: 98%;">
                                <a-input :value="getApproveStatusName(selectedLeave.approve_status)" placeholder="การอนุมัติ"></a-input>
                            </a-form-item>
                            <p>เอกสารที่เกี่ยวข้อง:</p>
                            <a-image :src="selectedLeave.photo_1" style="max-width: 150px; max-height: 150px; width: auto; height: auto; margin-top: -5px; margin-bottom: 15px;"/>
                            <a-form-item label="หมายเหตุ" name="leave_user_remark" style="width: 98%;">
                                <a-textarea :value="selectedLeave.leave_user_remark" placeholder="หมายเหตุ" :rows="3"></a-textarea>
                            </a-form-item>
                        </div>
                        <template #footer>
                            <a-button type="primary" @click="showModalLeaveUserData=false">
                                ปิด
                            </a-button>
                        </template> 
                    </a-modal> 
                </a-card>
            </a-spin>
        </div>
    </div>
</template>

<script>
import Utility from '../../utility'
import Swal from 'sweetalert2'
import SystemService from '../../api/SystemService'
import AdminService from '../../api/AdminService';
import dayjs from 'dayjs'
import ProjectService from '../../api/ProjectService';
import AdministrativeService from '../../api/AdministrativeService';
import ClientService from '../../api/ClientService.';
import XLSX from "xlsx-js-style";

export default {
    name: 'Project',
    computed: {
        
    },
    data() {
        return {
            leave_user: [],
            projectList: [],
            client_list: [],
            selectedLeave: {},
            priceTypes: [{'id':1,'name':'ตามระยะทาง'},{'id':2,'name':'เรทราคา'}],
            showModalLeaveUserData: false,
            timeStart: null,
            timeEnd: null,
            loading: false,
            search_business_type: null,

            img_placeholder: require('@/assets/images/place-holder/placeholder.jpg'),
            show_table_list: [],
            projectType: [],
            admins: [],
            fileList: [],
            title: 'จัดการโปรเจกต์ลูกค้า',
            button_title: 'เพิ่มโปรเจกต์',
            input: 'Username / ชื่อบริษัท',
            search_input: null,
            columns: [
                {
                    title: 'ลำดับ',
                    dataIndex: 'orderNumber',
                    key: 'orderNumber',
                    width: 200,
                    align: 'center',
                },
                {
                    title: 'รหัสโปรเจกต์',
                    dataIndex: 'project_no',
                    key: 'project_no',
                    width: 120,
                    align: 'center',
                }, 
                {
                    title: 'ลูกค้า/บริษัท',
                    dataIndex: 'client_id',
                    key: 'client_id',
                    width: 180,
                    align: 'center',
                }, 
                {
                    title: 'ชื่อโปรเจกต์',
                    dataIndex: 'project_name',
                    key: 'project_name',
                    width: 150,
                    align: 'center',
                }, 
                {
                    title: 'ประเภทโปรเจกต์',
                    dataIndex: 'project_type_id',
                    key: 'project_type_id',
                    width: 120,
                    align: 'center',
                }, 
                {
                    title: 'ประเภทสินค้า',
                    dataIndex: 'product_type',
                    key: 'product_type',
                    width: 90,
                    align: 'center',
                }, 
                {
                    title: 'ประเภทงานขนส่ง',
                    dataIndex: 'booking_type_id',
                    key: 'booking_type_id',
                    width: 150,
                    align: 'center',
                }, 
                {
                    title: 'ตามระยะทาง/เรทราคา',
                    dataIndex: 'price_type_id',
                    key: 'price_type_id',
                    width: 210,
                    align: 'center',
                }, 
                {
                    title: 'ผู้ดูแลโปรเจกต์ฝ่ายบริหาร',
                    dataIndex: 'admins',
                    key: 'admins',
                    width: 210,
                    align: 'center',
                }, 
                {
                    title: 'ตัวเลือก',
                    dataIndex: 'id',
                    key: 'id',
                    width: 130,
                    align: 'center',
                    fixed: 'right'
                }
            ],
        }
    },
    watch: {

    },
    methods: {
        render_date(datetime) {
            return dayjs(datetime).format('YYYY/MM/DD')
        },
        getApproveStatusName(status) {
            if (status == 1) {
                return 'อนุมัติ';
            } else if (status == 2) {
                return 'ไม่อนุมัติ';
            } else {
                return '';
            }
        },
        remove_project_user(id){
            Swal.fire({
                title: 'คุณต้องการลบใช่หรือไม่??',
                text: `คุณจะไม่สามารถกู้คืนได้`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'ใช่',
                cancelButtonText: 'ยกเลิก'
                }).then(async (result) => {
                if (result.isConfirmed) {
                    await SystemService.delete_all('Project', id)
                    this.onRefresh()
                }
            })
        },
        showLeaveDetails(record) {
            this.selectedLeave = record;
            this.showModalLeaveUserData = true;
        },
        onRefresh() {
             this.init_project()
        },
        async handleChangeApproveStatus(id, value) {
            await SystemService.update_all('LeaveAdmin', { data: { approve_status: value } }, id);
            this.onRefresh();
        },
        async export_file() {
            this.$message.loading({ content: "กำลังโหลด...", key: "loading" });
            const projectData = [...this.projectList];
            const data = projectData.map((item) => {
                return {
                    project_no: item.project_no??'',
                    client_id: this.getClientName(item.client_id)??'',
                    project_name: item.project_name??'',
                    project_type_id: this.getProjectTypeName(item.project_type_id)??'',
                    product_type: item.product_type??'',
                    booking_type_id: this.getBookingTypeName(item.booking_type_id)??'',
                    price_type_id: this.getPriceTypeName(item.price_type_id)??'',
                    admins: item.admins??'',
                };
            });

            const headersColumn = [
                { header: "รหัสโปรเจกต์", key: "project_no" },
                { header: "ลูกค้า/บริษัท", key: "client_id" },
                { header: "ชื่อโปรเจกต์", key: "project_name" },
                { header: "ประเภทโปรเจกต์", key: "project_type_id" },
                { header: "ประเภทสินค้า", key: "product_type" },
                { header: "ประเภทงานขนส่ง", key: "booking_type_id" },
                { header: "ตามระยะทาง/เรทราคา", key: "price_type_id" },
                { header: "ผู้ดูแลโปรเจกต์ฝ่ายบริหาร", key: "admins" },
            ];

            const workBook = XLSX.utils.book_new();
            const workSheet = XLSX.utils.json_to_sheet(data, { header: headersColumn.map((h) => h.key) });
            XLSX.utils.sheet_add_aoa(workSheet, [headersColumn.map((h) => h.header)], { origin: "A1" });

            const margin = 5;
            workSheet["!cols"] = headersColumn.map((header) => ({
                wch: header.header.length + margin,
            }));

            const range = XLSX.utils.decode_range(workSheet["!ref"]);
            const bgColor = "#e09c9c"; 

            for (let R = range.s.r; R <= range.e.r; ++R) {
                for (let C = range.s.c; C <= range.e.c; ++C) {
                    const cellAddress = XLSX.utils.encode_cell({ c: C, r: R });
                    let cell = workSheet[cellAddress];
                    if (!cell) {
                        cell = { v: "" }; // Add an empty cell if it doesn't exist
                        workSheet[cellAddress] = cell;
                    }

                    if (R === 0) {
                        cell.s = {
                            font: { bold: true },
                            fill: { fgColor: { rgb: bgColor.replace("#", "") } },
                            border: {
                                bottom: { style: "thin" },
                                right: { style: "thin" },
                                left: { style: "thin" },
                                top: { style: "thin" },
                            },
                            alignment: { horizontal: "center", vertical: "center" },
                        };
                    } else {
                        cell.s = {
                            font: { bold: false },
                            border: {
                                bottom: { style: "thin" },
                                right: { style: "thin" },
                                left: { style: "thin" },
                                top: { style: "thin" },
                            },
                            alignment: { horizontal: "center", vertical: "center" },
                        };
                    }
                }
            }

            XLSX.utils.book_append_sheet(workBook, workSheet, "Report");
            XLSX.writeFile(workBook, "Project.xlsx");
            this.$message.success({ content: "สร้างไฟล์สำเร็จ", key: "loading" });
        },
        clear_search() {
            this.search_input = null;
            this.timeStart = null;
            this.timeEnd = null;
        },
        render_date(datetime) {
            return dayjs(datetime).format('YYYY/MM/DD')
        },
        async go_to_edit(id) {
            this.$router.push(`/app/project/${id}`);
        },
        filtered_list(array) {
            return array.filter(({ project_name }) => {
                let matchesName = true;
                if (this.search_input!=null && this.search_input!='') {
                    matchesName = project_name?.toLowerCase().includes(this.search_input.toLowerCase());
                }
                return matchesName;
            });
        },
        async init_project () {
            this.projectList = await ProjectService.getProjectAll();
            this.projectList = this.projectList.map((project, index) => {
                return {
                    ...project,
                    orderNumber: index + 1 // Add 1 because index starts at 0
                };
            });
            this.projectType = await ProjectService.getProjectTypeAll();
            this.client_list = await ClientService.getClientByCompany();
        },
        getProjectTypeName(id) {
            const projectType = this.projectType?.find(type => type.id === id);
            return projectType ? projectType.name : '';
        },
        getBookingTypeName(id) {
            const bookingTypeList = [
                {'id':1,'name':'ขาเดียว'},
                {'id':2,'name':'ไปกลับ'},
                {'id':3,'name':'การขนส่งแบบวิ่งเก็บ (Milkrun)'},]
            const bookingType = bookingTypeList?.find(type => type.id === id);
            return bookingType ? bookingType.name : '';
        },
        getClientName(id) {
            const clientName = this.client_list.find(type => type.id === id);
            return clientName ? clientName.fullname : '';
        },
        getPriceTypeName(id) {
            const priceTypeName = this.priceTypes.find(type => type.id === id);
            return priceTypeName ? priceTypeName.name : '';
        },
    },
    mounted() {
        this.init_project()
    }
}
</script>

<style>

.permission-table .ant-table-row-level-0 td:first-child,
.permission-table .ant-table-row-level-0 td:nth-child(2),
.permission-table .ant-table-row-level-0 td:nth-child(3) {
    background-color: #f9f9f9 !important;
}
.permission-table .ant-table-row-expand-icon::before,
.permission-table .ant-table-row-expand-icon::after 
{
    content: '' !important;
    border: none !important;
    background: none; /* ปิด background */
    transform: none !important;
    transition: none !important;
    top: auto !important
}
.permission-table .ant-table-row-expand-icon:not(.ant-table-row-expand-icon-expanded)::before {
    content: '>' !important;
}

.permission-table .ant-table-row-expand-icon.ant-table-row-expand-icon-expanded::before {
    content: 'v' !important;
}
</style>