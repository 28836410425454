<template>
    <div class="app-content">
        <a-card :title="title">
            <template #extra>
                <a-button style="display: flex" @click="clear_model_data()" size="large">
                    <template #icon>
                        <span class="material-symbols-outlined">arrow_back_ios</span>
                    </template>
                     ย้อนกลับ
                </a-button>
            </template>
            <a-form :model="model_form" :layout="form_layout" :onFinish="submit_model_data">
                <a-collapse class="add_car" style="border-radius: 4px" v-model:activeKey="activeKey">
                    <a-collapse-panel key="1" :header="`โปรเจกต์`">
                        <template #extra>
                            <p v-if="requireProjectFields" style="margin: 0; color: grey">
                                {{ `(ต้องระบุอีก ${requireProjectFields})` }}
                            </p>
                        </template>
                        <a-row>
                            <a-col :style="input_type !== 'divider' ? { margin: '1rem' } : {}" :span="col_span" v-for="
                                    {
                                        col_span, non_editable, input_type, search_select, modelname, label_key, label, placeholder, required,
                                        target_key, target_modelname, drawer_title, label_function, table_columns, drawer_fields, drawer_button_text, drawer_button_text_edit, parent, null_on_change, check_duplicate, check_rule,
                                        on_change
                                    }, key in default_fields.Project.inputs">
                                <div v-if="input_type== 'label'">{{ label }}</div>
                                <a-form-item v-else :name="key" :label="placeholder" :has-feedback="check_duplicate"
                                    :rules="check_duplicate? check_rule: (non_editable ? null : [{ required, message: `โปรดระบุ ${placeholder}` }])">
                                    <a-select v-if="key === 'client_id'" 
                                        show-search v-model:value="model_form[key]" 
                                        @change="handleChange(null_on_change , on_change)" 
                                        :options="client_list.map((e) => ({ value: e.id, label: e.fullname }))"
                                        :filter-option="filterOption"
                                        :placeholder=" 'เลือก'+placeholder || ''">
                                        <a-select-option v-for="option in client_list" :key="option.id" :value="option.id">
                                            {{ option.fullname }}
                                        </a-select-option>
                                    </a-select>
                                    <a-select v-else-if="key === 'project_type_id'" 
                                        show-search v-model:value="model_form[key]" 
                                        @change="handleChange(null_on_change , on_change)" 
                                        :options="projectType.map((e) => ({ value: e.id, label: e.name }))"
                                        :filter-option="filterOption"
                                        :placeholder=" 'เลือก'+placeholder || ''">
                                        <a-select-option v-for="option in projectType" :key="option.id" :value="option.id">
                                            {{ option.name }}
                                        </a-select-option>
                                    </a-select>

                                    <a-select v-else-if="key === 'booking_type_id'" 
                                        show-search v-model:value="model_form[key]" 
                                        @change="handleChange(null_on_change , on_change)" 
                                        :options="bookingTypeList.map((e) => ({ value: e.id, label: e.name }))"
                                        :filter-option="filterOption"
                                        :placeholder=" 'เลือก'+placeholder || ''">
                                        <a-select-option v-for="option in bookingTypeList" :key="option.id" :value="option.id">
                                            {{ option.name }}
                                        </a-select-option>
                                    </a-select>
                                    <a-checkbox-group v-else-if="key == 'company_3rd'" v-model:value="model_form[key]" style="margin-top: 12px;">                                        <a-checkbox
                                            @change="handleChange('disable', $event, field)"
                                            v-model="model_form[key]"
                                            value="บริษัทรถร่วม"
                                        >
                                            บริษัทรถร่วม
                                        </a-checkbox>
                                    </a-checkbox-group>
                                    <div v-else-if="key=='blank'"></div>
                                    <a-divider v-else-if="input_type == 'divider'" orientation="left">{{ label
                                    }}</a-divider>
                                    <a-divider v-else-if="input_type == 'sub-divider'" orientation="left">
                                        <h5>{{ label }}</h5>
                                    </a-divider>
                                    <TableCreateDrawer v-else-if="input_type == 'table'" :divider="drawer_title"
                                        :table_columns="table_columns" :target_object="model_form"
                                        :target_key="target_key" :drawer_title="drawer_title"
                                        :drawer_fields="drawer_fields.inputs"
                                        :drawer_button_text="drawer_button_text"
                                        :drawer_button_text_edit="drawer_button_text_edit"
                                        :target_modelname="target_modelname" />
                                    <a-date-picker v-else-if="input_type == 'date'" v-model:value="model_form[key]"
                                        :placeholder="placeholder" style="display: flex;"
                                        :disabled="action_type == 'create' ? false : non_editable" />
                                    <a-date-picker v-else-if="input_type == 'datetime'" v-model:value="model_form[key]"
                                        :placeholder="placeholder"
                                        :show-time="{ format: 'HH:mm' }"
                                        format="DD/MM/YYYY HH:mm"
                                        :disabled="action_type == 'create' ? false : non_editable" />
                                    <a-date-picker v-else-if="input_type == 'month'" v-model:value="model_form[key]"
                                        :placeholder="placeholder" style="display: flex;"
                                        picker="month"
                                        format="MMMM"
                                        :disabled="action_type == 'create' ? false : non_editable" />
                                    <a-switch v-else-if="input_type == 'switch'" 
                                        v-model:checked="model_form[key]"
                                        :placeholder="placeholder"
                                        :disabled="action_type == 'create' ? false : non_editable" /> 
                                    <a-textarea v-else-if="input_type == 'textarea'" v-model:value="model_form[key]"
                                        :placeholder="placeholder"
                                        :disabled="action_type == 'create' ? false : non_editable" />
                                    <div v-else-if="input_type == 'photos'" class="clearfix">
                                        <a-upload v-model:file-list="model_form[target_key]"
                                            action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                            @change="upload_files($event, key)" method="GET"
                                            @remove="remove_uploaded_file($event)"
                                            list-type="picture-card">
                                            <div v-if="model_form[target_key]?.length < 5"
                                                style="height: 104px; width: 104px; padding: 2rem">
                                                <span style="font-size: 20px"
                                                    class="material-symbols-outlined">add</span>
                                                <div style="margin-top: 8px">Upload</div>
                                            </div>
                                        </a-upload>
                                    </div>
                                    <div v-else-if="input_type == 'photo'" style="display: flex; flex-direction: column; align-items: center; gap:1rem">
                                        <div>{{ label }}</div>
                                        <a-upload 
                                            v-model:file-list="fileList"
                                            :show-upload-list="false"
                                            action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                            @change="upload_files($event, key)" 
                                            method="GET"
                                            :before-upload="setFileType"
                                            accept="image/*"
                                        >
                                            <div v-if="model_form[key]" class="upload-container">
                                                <img :src="model_form[key]" class="uploaded-image" alt="avatar" @click="showPreview(model_form[key])"/>
                                                <div class="icon-container">
                                                    <span
                                                        class="material-symbols-outlined preview-icon"
                                                        @click="handleIconClick('preview', model_form[key], $event)"
                                                    >
                                                        visibility
                                                    </span>
                                                    <a :href="model_form[key]" download @click.stop>
                                                        <span class="material-symbols-outlined download-icon"
                                                    >
                                                            download
                                                        </span>
                                                    </a>
                                                    <span
                                                        class="material-symbols-outlined delete-icon"
                                                        @click="handleIconClick('delete', key, $event)"
                                                    >
                                                        delete
                                                    </span>
                                                </div>
                                            </div>
                                            <div v-else style="display: flex; flex-direction: column; align-items: center;">
                                                <p class="ant-upload-drag-icon">
                                                    <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                                                </p>
                                                คลิกเพื่ออัพโหลดรูปภาพ
                                            </div>
                                        </a-upload>
                                        <a-modal v-model:visible="previewVisible" :footer="null" :mask="false" @cancel="previewVisible = false" class="custom-modal" style="background-color: none !important" >
                                            <img :src="previewImage" alt="Preview" style="width: 100%;" />
                                        </a-modal>
                                    </div>
                                    <!-- upload_file -->
                                    <div v-else-if="input_type == 'file'" style="display: flex; flex-direction: column; align-items: center; gap:1rem">
                                        <div>{{ label }}</div>
                                        <a-upload 
                                            v-model:file-list="fileList" 
                                            v-if="!model_form[key]"
                                            action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                            method="GET"
                                            :show-upload-list="false"
                                            @change="upload_file_car($event, key)"
                                        >
                                            <img v-if="model_form[key]" :src="model_form[key]"
                                                style="width: 100%; height: 100px;"
                                                alt="avatar"
                                            />
                                            <div style="display: flex; flex-direction: column; align-items: center;">
                                                <p class="ant-upload-drag-icon">
                                                    <span class="material-symbols-outlined" 
                                                        style="font-size: 5rem;">upload_file</span>
                                                </p>
                                                อัพโหลดไฟล์
                                            </div>
                                        </a-upload>
                                        <a :href="model_form[key][0].file" v-else="model_form[key]"
                                            target="_blank" rel="doc"
                                        >
                                            {{ model_form[key][0].file_name }}
                                        </a>
                                        <a-button danger type="text" @click="(model_form[key] = '')" style="padding: 0; height: 18px;"
                                            v-if="model_form[key]">
                                            <span class="material-symbols-outlined">close</span>
                                        </a-button>
                                    </div>
                                    <div  v-else-if="input_type == 'table_files'" >
                                        <a-table
                                            :columns="[
                                                {
                                                    title: 'ชื่อเอกสาร',
                                                    dataIndex: 'name',
                                                    key: 'name',
                                                    width: 400,
                                                },
                                                {
                                                    title: 'ไฟล์เอกสาร',
                                                    dataIndex: 'file',
                                                    key: 'file',
                                                },
                                                {
                                                    title: 'ลบ',
                                                    dataIndex: 'id',
                                                    key: 'id',
                                                    width: 100,
                                                    align: 'center',
                                                }
                                            ]"
                                            :dataSource="data_raw_files"
                                            :pagination="false"
                                            :scroll="{ y: 240 }"
                                        >
                                            <template #bodyCell="{ column, record, index}" >
                                                <template v-if="column.key === 'name'">
                                                    <a-form-item>
                                                        <a-input v-model:value="record.name"
                                                        />
                                                    </a-form-item>
                                                </template>
                                                <template v-if="column.key === 'file'">
                                                    <a-form-item>
                                                    <a :href="record.file" target="_blank" rel="doc">{{ record.file_name }}</a>
                                                    </a-form-item>
                                                </template>
                                                <template v-if="column.key === 'id'">
                                                    <a-form-item>
                                                        <a-button danger type="text" @click="(data_raw_files.splice(index, 1))">
                                                            <span class="material-symbols-outlined">delete</span>
                                                        </a-button>
                                                    </a-form-item>
                                                </template>
                                            </template>
                                        </a-table>
                                        <a-upload v-model:file-list="model_form[key]"
                                            action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                            method="GET"
                                            @change="upload_file_multiple($event, key)"
                                            :show-upload-list="false"
                                            >
                                            <a-button style="margin-top: 10px;">
                                                <span class="material-symbols-outlined">add</span>
                                                เพิ่มไฟล์
                                            </a-button>
                                        </a-upload>
                                    </div>
                                    
                                    <a-input v-else v-model:value="model_form[key]" :placeholder="placeholder"
                                        :type="input_type"
                                        :disabled="key === 'client_no' ? true : (action_type == 'create' ? false : non_editable)"
                                    />
                                </a-form-item>
                            </a-col>
                        </a-row>
                    </a-collapse-panel>

                    <a-collapse-panel key="2" :header="`ตามระยะทาง/เรทราคา`">
                        <template #extra>
                            <p v-if="requireOilFields" style="margin: 0; color: grey">
                                {{ `(ต้องระบุอีก ${requireOilFields})` }}
                            </p>
                        </template>
                        <a-row>
                            <a-col style="margin: 1rem" :span="col_span" v-for="
                                    {
                                        col_span, non_editable, input_type, search_select, modelname, label_key, label, placeholder, required,
                                        target_key, target_modelname, drawer_title, label_function, table_columns, drawer_fields, drawer_button_text, drawer_button_text_edit, parent, null_on_change, check_duplicate, check_rule,
                                        on_change,price_type
                                    }, key in default_fields.PriceRate.inputs">
                                <div v-if="input_type== 'label'" style="font-weight: bold; color: #1d39c4;">{{ label }}</div>
                                <div v-else-if="price_type">
                                    <a-form-item
                                        v-if="key == 'price_type_id'"
                                        label="ตามระยะทาง/เรทราคา :"
                                        :rules="[
                                            { required: true, message: 'โปรดเลือกข้อมูล', trigger: 'change' }
                                        ]"
                                        label-colon="false"
                                        prop="price_type_id"
                                    >
                                        <div style="display: flex; align-items: center;">
                                            <a-radio-group 
                                                v-model:value="model_form[key]"
                                                :options="priceTypes?.map(option => ({ label: option.name, value: option.id }))"
                                            />
                                        </div>
                                    </a-form-item>
                                    <div v-if="model_form['price_type_id'] == 1">
                                        <TableCreateDrawer 
                                        v-if="DistanceRate.drawer_fields.inputs.car_type_id.options.length > 0"
                                        :divider="'ตารางตามระยะทาง'"
                                        :table_columns="DistanceRate.columns" :target_object="model_form"
                                        :target_key="'distanceRate'" :drawer_title="'ข้อมูลช่วงน้ำมัน'"
                                        :drawer_fields="DistanceRate.drawer_fields.inputs"
                                        :drawer_button_text="DistanceRate.drawer_button_text"
                                        :drawer_button_text_edit="DistanceRate.drawer_button_text_edit"
                                        :target_modelname="DistanceRate.target_modelname" />
                                    </div>
                                    <div v-if="model_form['price_type_id'] == 2">
                                        <TableImportExport :divider="'ตารางเรทราคา'"
                                        :table_columns="price_rate_drawer_fields.columns" :target_object="model_form"
                                        :target_key="'priceRate'" :drawer_title="'ข้อมูลเรทราคา'"
                                        :drawer_fields="price_rate_drawer_fields.drawer_fields.inputs"
                                        :drawer_button_text="price_rate_drawer_fields.drawer_button_text"
                                        :drawer_button_text_edit="price_rate_drawer_fields.drawer_button_text_edit"
                                        :target_modelname="price_rate_drawer_fields.target_modelname"
                                        />
                                    </div>
                                </div>
                                <a-form-item v-else :name="key" :label="placeholder" :has-feedback="check_duplicate"
                                    :rules="check_duplicate? check_rule: (non_editable ? null : [{ required, message: `โปรดระบุ ${placeholder}` }])">
                                    <a-select v-if="key === 'oil_range_id'" 
                                        show-search v-model:value="model_form[key]" 
                                        @change="handleChange('oil_range_id' , model_form[key])" 
                                        :options="oilRangeList.map((e) => ({ value: e.id, label: e.range }))"
                                        :filter-option="filterOption"
                                        :placeholder=" 'เลือก'+placeholder || ''">
                                        <a-select-option v-for="option in oilRangeList" :key="option.id" :value="option.id">
                                            {{ option.range }}
                                        </a-select-option>
                                    </a-select>
                                    <a-checkbox-group v-else-if="key == 'company_3rd'" v-model:value="model_form[key]" style="margin-top: 12px;">                                        <a-checkbox
                                            @change="handleChange('disable', $event, field)"
                                            v-model="model_form[key]"
                                            value="บริษัทรถร่วม"
                                        >
                                            บริษัทรถร่วม
                                        </a-checkbox>
                                    </a-checkbox-group>
                                    <div v-else-if="key=='blank'"></div>
                                    <a-divider v-else-if="input_type == 'divider'" orientation="left">{{ label
                                    }}</a-divider>
                                    <a-divider v-else-if="input_type == 'sub-divider'" orientation="left">
                                        <h5>{{ label }}</h5>
                                    </a-divider>
                                    <TableCreateDrawer v-else-if="input_type == 'table'" :divider="drawer_title"
                                        :table_columns="table_columns" :target_object="model_form"
                                        :target_key="target_key" :drawer_title="drawer_title"
                                        :drawer_fields="drawer_fields.inputs"
                                        :drawer_button_text="drawer_button_text"
                                        :drawer_button_text_edit="drawer_button_text_edit"
                                        :target_modelname="target_modelname" />
                                    <a-date-picker v-else-if="input_type == 'date'" v-model:value="model_form[key]"
                                        :placeholder="placeholder" style="display: flex;"
                                        :disabled="action_type == 'create' ? false : non_editable" />
                                    <a-date-picker v-else-if="input_type == 'datetime'" v-model:value="model_form[key]"
                                        :placeholder="placeholder"
                                        :show-time="{ format: 'HH:mm' }"
                                        format="DD/MM/YYYY HH:mm"
                                        :disabled="action_type == 'create' ? false : non_editable" />
                                    <a-date-picker v-else-if="input_type == 'month'" v-model:value="model_form[key]"
                                        :placeholder="placeholder" style="display: flex;"
                                        picker="month"
                                        format="MMMM"
                                        :disabled="action_type == 'create' ? false : non_editable" />
                                    <a-switch v-else-if="input_type == 'switch'" 
                                        v-model:checked="model_form[key]"
                                        :placeholder="placeholder"
                                        :disabled="action_type == 'create' ? false : non_editable" /> 
                                    <a-textarea v-else-if="input_type == 'textarea'" v-model:value="model_form[key]"
                                        :placeholder="placeholder"
                                        :disabled="action_type == 'create' ? false : non_editable" />
                                    <div v-else-if="input_type == 'photos'" class="clearfix">
                                        <a-upload v-model:file-list="model_form[target_key]"
                                            action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                            @change="upload_files($event, key)" method="GET"
                                            @remove="remove_uploaded_file($event)"
                                            list-type="picture-card">
                                            <div v-if="model_form[target_key]?.length < 5"
                                                style="height: 104px; width: 104px; padding: 2rem">
                                                <span style="font-size: 20px"
                                                    class="material-symbols-outlined">add</span>
                                                <div style="margin-top: 8px">Upload</div>
                                            </div>
                                        </a-upload>
                                    </div>
                                    <div v-else-if="input_type == 'photo'" style="display: flex; flex-direction: column; align-items: center; gap:1rem">
                                        <div>{{ label }}</div>
                                        <a-upload 
                                            v-model:file-list="fileList"
                                            :show-upload-list="false"
                                            action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                            @change="upload_files($event, key)" 
                                            method="GET"
                                            :before-upload="setFileType"
                                            accept="image/*"
                                        >
                                            <div v-if="model_form[key]" class="upload-container">
                                                <img :src="model_form[key]" class="uploaded-image" alt="avatar" @click="showPreview(model_form[key])"/>
                                                <div class="icon-container">
                                                    <span
                                                        class="material-symbols-outlined preview-icon"
                                                        @click="handleIconClick('preview', model_form[key], $event)"
                                                    >
                                                        visibility
                                                    </span>
                                                    <a :href="model_form[key]" download @click.stop>
                                                        <span class="material-symbols-outlined download-icon"
                                                    >
                                                            download
                                                        </span>
                                                    </a>
                                                    <span
                                                        class="material-symbols-outlined delete-icon"
                                                        @click="handleIconClick('delete', key, $event)"
                                                    >
                                                        delete
                                                    </span>
                                                </div>
                                            </div>
                                            <div v-else style="display: flex; flex-direction: column; align-items: center;">
                                                <p class="ant-upload-drag-icon">
                                                    <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                                                </p>
                                                คลิกเพื่ออัพโหลดรูปภาพ
                                            </div>
                                        </a-upload>
                                        <a-modal v-model:visible="previewVisible" :footer="null" :mask="false" @cancel="previewVisible = false" class="custom-modal" style="background-color: none !important" >
                                            <img :src="previewImage" alt="Preview" style="width: 100%;" />
                                        </a-modal>
                                    </div>
                                    <!-- upload_file -->
                                    <div v-else-if="input_type == 'file'" style="display: flex; flex-direction: column; align-items: center; gap:1rem">
                                        <div>{{ label }}</div>
                                        <a-upload 
                                            v-model:file-list="fileList" 
                                            v-if="!model_form[key]"
                                            action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                            method="GET"
                                            :show-upload-list="false"
                                            @change="upload_file_car($event, key)"
                                        >
                                            <img v-if="model_form[key]" :src="model_form[key]"
                                                style="width: 100%; height: 100px;"
                                                alt="avatar"
                                            />
                                            <div style="display: flex; flex-direction: column; align-items: center;">
                                                <p class="ant-upload-drag-icon">
                                                    <span class="material-symbols-outlined" 
                                                        style="font-size: 5rem;">upload_file</span>
                                                </p>
                                                อัพโหลดไฟล์
                                            </div>
                                        </a-upload>
                                        <a :href="model_form[key][0].file" v-else="model_form[key]"
                                            target="_blank" rel="doc"
                                        >
                                            {{ model_form[key][0].file_name }}
                                        </a>
                                        <a-button danger type="text" @click="(model_form[key] = '')" style="padding: 0; height: 18px;"
                                            v-if="model_form[key]">
                                            <span class="material-symbols-outlined">close</span>
                                        </a-button>
                                    </div>
                                    <div  v-else-if="input_type == 'table_files'" >
                                        <a-table
                                            :columns="[
                                                {
                                                    title: 'ชื่อเอกสาร',
                                                    dataIndex: 'name',
                                                    key: 'name',
                                                    width: 400,
                                                },
                                                {
                                                    title: 'ไฟล์เอกสาร',
                                                    dataIndex: 'file',
                                                    key: 'file',
                                                },
                                                {
                                                    title: 'ลบ',
                                                    dataIndex: 'id',
                                                    key: 'id',
                                                    width: 100,
                                                    align: 'center',
                                                }
                                            ]"
                                            :dataSource="data_raw_files"
                                            :pagination="false"
                                            :scroll="{ y: 240 }"
                                        >
                                            <template #bodyCell="{ column, record, index}" >
                                                <template v-if="column.key === 'name'">
                                                    <a-form-item>
                                                        <a-input v-model:value="record.name"
                                                        />
                                                    </a-form-item>
                                                </template>
                                                <template v-if="column.key === 'file'">
                                                    <a-form-item>
                                                    <a :href="record.file" target="_blank" rel="doc">{{ record.file_name }}</a>
                                                    </a-form-item>
                                                </template>
                                                <template v-if="column.key === 'id'">
                                                    <a-form-item>
                                                        <a-button danger type="text" @click="(data_raw_files.splice(index, 1))">
                                                            <span class="material-symbols-outlined">delete</span>
                                                        </a-button>
                                                    </a-form-item>
                                                </template>
                                            </template>
                                        </a-table>
                                        <a-upload v-model:file-list="model_form[key]"
                                            action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                            method="GET"
                                            @change="upload_file_multiple($event, key)"
                                            :show-upload-list="false"
                                            >
                                            <a-button style="margin-top: 10px;">
                                                <span class="material-symbols-outlined">add</span>
                                                เพิ่มไฟล์
                                            </a-button>
                                        </a-upload>
                                    </div>
                                    <a-input v-else v-model:value="model_form[key]" :placeholder="placeholder"
                                        :type="input_type"
                                        :disabled="key === 'client_no' ? true : (action_type == 'create' ? false : non_editable)"
                                    />
                                    <a-input v-else v-model:value="model_form[key]" :placeholder="placeholder"
                                        :type="input_type"
                                        :disabled="key === 'client_no' ? true : (action_type == 'create' ? false : non_editable)"
                                    />
                                </a-form-item>
                            </a-col>
                        </a-row>
                    </a-collapse-panel>
                    <a-collapse-panel key="3" :header="`รายละเอียดเงื่อนไขการชำระเงินและผู้ติดต่อ`">
                        <a-row>
                            <a-col style="margin: 1rem" :span="col_span" v-for="
                                    {
                                        col_span, non_editable, input_type, search_select, modelname, label_key, label, placeholder, required,
                                        target_key, target_modelname, drawer_title, label_function, table_columns, drawer_fields, drawer_button_text, drawer_button_text_edit, parent, null_on_change, check_duplicate, check_rule,
                                        on_change
                                    }, key in default_fields.ContactInfomation.inputs">
                                <div v-if="input_type== 'label'" style="font-weight: bold; color: #1d39c4;">{{ label }}</div>
                                <a-form-item v-else :name="key" :label="placeholder" :has-feedback="check_duplicate"
                                    :rules="check_duplicate? check_rule: (non_editable ? null : [{ required, message: `โปรดระบุ ${placeholder}` }])">
                                    <a-select v-if="key === 'payment_method_id'" 
                                        show-search v-model:value="model_form[key]" 
                                        @change="handleChange(null_on_change , on_change)" 
                                        :options="paymentMethodList.map((e) => ({ value: e.id, label: e.name }))"
                                        :filter-option="filterOption"
                                        :placeholder=" 'เลือก'+placeholder || ''">
                                        <a-select-option v-for="option in paymentMethodList" :key="option.id" :value="option.id">
                                            {{ option.name }}
                                        </a-select-option>
                                    </a-select>
                                    <a-input v-else-if="key=='confirm_date'||key=='collect_date'||key=='document_date'" v-model:value="model_form[key]" :placeholder="placeholder"
                                        :type="input_type"
                                        :disabled="key === 'client_no' ? true : (action_type == 'create' ? false : non_editable)"
                                        :min="1"
                                        :max="31"
                                        @input="validateDateInput($event, key)"
                                    />
                                    <a-checkbox-group v-else-if="key == 'company_3rd'" v-model:value="model_form[key]" style="margin-top: 12px;">                                        <a-checkbox
                                            @change="handleChange('disable', $event, field)"
                                            v-model="model_form[key]"
                                            value="บริษัทรถร่วม"
                                        >
                                            บริษัทรถร่วม
                                        </a-checkbox>
                                    </a-checkbox-group>
                                    <div v-else-if="key=='blank'"></div>
                                    <a-divider v-else-if="input_type == 'divider'" orientation="left">{{ label
                                    }}</a-divider>
                                    <a-divider v-else-if="input_type == 'sub-divider'" orientation="left">
                                        <h5>{{ label }}</h5>
                                    </a-divider>
                                    <TableCreateDrawer v-else-if="input_type == 'table'" :divider="drawer_title"
                                        :table_columns="table_columns" :target_object="model_form"
                                        :target_key="target_key" :drawer_title="drawer_title"
                                        :drawer_fields="drawer_fields.inputs"
                                        :drawer_button_text="drawer_button_text"
                                        :drawer_button_text_edit="drawer_button_text_edit"
                                        :target_modelname="target_modelname" />
                                    <a-date-picker v-else-if="input_type == 'date'" v-model:value="model_form[key]"
                                        :placeholder="placeholder" style="display: flex;"
                                        :disabled="action_type == 'create' ? false : non_editable" />
                                    <a-date-picker v-else-if="input_type == 'datetime'" v-model:value="model_form[key]"
                                        :placeholder="placeholder"
                                        :show-time="{ format: 'HH:mm' }"
                                        format="DD/MM/YYYY HH:mm"
                                        :disabled="action_type == 'create' ? false : non_editable" />
                                    <a-date-picker v-else-if="input_type == 'month'" v-model:value="model_form[key]"
                                        :placeholder="placeholder" style="display: flex;"
                                        picker="month"
                                        format="MMMM"
                                        :disabled="action_type == 'create' ? false : non_editable" />
                                    <a-switch v-else-if="input_type == 'switch'" 
                                        v-model:checked="model_form[key]"
                                        :placeholder="placeholder"
                                        :disabled="action_type == 'create' ? false : non_editable" /> 
                                    <a-textarea v-else-if="input_type == 'textarea'" v-model:value="model_form[key]"
                                        :placeholder="placeholder"
                                        :disabled="action_type == 'create' ? false : non_editable" />
                                    <div v-else-if="input_type == 'photos'" class="clearfix">
                                        <a-upload v-model:file-list="model_form[target_key]"
                                            action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                            @change="upload_files($event, key)" method="GET"
                                            @remove="remove_uploaded_file($event)"
                                            list-type="picture-card">
                                            <div v-if="model_form[target_key]?.length < 5"
                                                style="height: 104px; width: 104px; padding: 2rem">
                                                <span style="font-size: 20px"
                                                    class="material-symbols-outlined">add</span>
                                                <div style="margin-top: 8px">Upload</div>
                                            </div>
                                        </a-upload>
                                    </div>
                                    <div v-else-if="input_type == 'photo'" style="display: flex; flex-direction: column; align-items: center; gap:1rem">
                                        <div>{{ label }}</div>
                                        <a-upload 
                                            v-model:file-list="fileList"
                                            :show-upload-list="false"
                                            action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                            @change="upload_files($event, key)" 
                                            method="GET"
                                            :before-upload="setFileType"
                                            accept="image/*"
                                        >
                                            <div v-if="model_form[key]" class="upload-container">
                                                <img :src="model_form[key]" class="uploaded-image" alt="avatar" @click="showPreview(model_form[key])"/>
                                                <div class="icon-container">
                                                    <span
                                                        class="material-symbols-outlined preview-icon"
                                                        @click="handleIconClick('preview', model_form[key], $event)"
                                                    >
                                                        visibility
                                                    </span>
                                                    <a :href="model_form[key]" download @click.stop>
                                                        <span class="material-symbols-outlined download-icon"
                                                    >
                                                            download
                                                        </span>
                                                    </a>
                                                    <span
                                                        class="material-symbols-outlined delete-icon"
                                                        @click="handleIconClick('delete', key, $event)"
                                                    >
                                                        delete
                                                    </span>
                                                </div>
                                            </div>
                                            <div v-else style="display: flex; flex-direction: column; align-items: center;">
                                                <p class="ant-upload-drag-icon">
                                                    <span class="material-symbols-outlined" style="font-size: 5rem;">upload_file</span>
                                                </p>
                                                คลิกเพื่ออัพโหลดรูปภาพ
                                            </div>
                                        </a-upload>
                                        <a-modal v-model:visible="previewVisible" :footer="null" :mask="false" @cancel="previewVisible = false" class="custom-modal" style="background-color: none !important" >
                                            <img :src="previewImage" alt="Preview" style="width: 100%;" />
                                        </a-modal>
                                    </div>
                                    <!-- upload_file -->
                                    <div v-else-if="input_type == 'file'" style="display: flex; flex-direction: column; align-items: center; gap:1rem">
                                        <div>{{ label }}</div>
                                        <a-upload 
                                            v-model:file-list="fileList" 
                                            v-if="!model_form[key]"
                                            action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                            method="GET"
                                            :show-upload-list="false"
                                            @change="upload_file_car($event, key)"
                                        >
                                            <img v-if="model_form[key]" :src="model_form[key]"
                                                style="width: 100%; height: 100px;"
                                                alt="avatar"
                                            />
                                            <div style="display: flex; flex-direction: column; align-items: center;">
                                                <p class="ant-upload-drag-icon">
                                                    <span class="material-symbols-outlined" 
                                                        style="font-size: 5rem;">upload_file</span>
                                                </p>
                                                อัพโหลดไฟล์
                                            </div>
                                        </a-upload>
                                        <a :href="model_form[key][0].file" v-else="model_form[key]"
                                            target="_blank" rel="doc"
                                        >
                                            {{ model_form[key][0].file_name }}
                                        </a>
                                        <a-button danger type="text" @click="(model_form[key] = '')" style="padding: 0; height: 18px;"
                                            v-if="model_form[key]">
                                            <span class="material-symbols-outlined">close</span>
                                        </a-button>
                                    </div>
                                    <div  v-else-if="input_type == 'table_files'" >
                                        <a-table
                                            :columns="[
                                                {
                                                    title: 'ชื่อเอกสาร',
                                                    dataIndex: 'name',
                                                    key: 'name',
                                                    width: 400,
                                                },
                                                {
                                                    title: 'ไฟล์เอกสาร',
                                                    dataIndex: 'file',
                                                    key: 'file',
                                                },
                                                {
                                                    title: 'ลบ',
                                                    dataIndex: 'id',
                                                    key: 'id',
                                                    width: 100,
                                                    align: 'center',
                                                }
                                            ]"
                                            :dataSource="data_raw_files"
                                            :pagination="false"
                                            :scroll="{ y: 240 }"
                                        >
                                            <template #bodyCell="{ column, record, index}" >
                                                <template v-if="column.key === 'name'">
                                                    <a-form-item>
                                                        <a-input v-model:value="record.name"
                                                        />
                                                    </a-form-item>
                                                </template>
                                                <template v-if="column.key === 'file'">
                                                    <a-form-item>
                                                    <a :href="record.file" target="_blank" rel="doc">{{ record.file_name }}</a>
                                                    </a-form-item>
                                                </template>
                                                <template v-if="column.key === 'id'">
                                                    <a-form-item>
                                                        <a-button danger type="text" @click="(data_raw_files.splice(index, 1))">
                                                            <span class="material-symbols-outlined">delete</span>
                                                        </a-button>
                                                    </a-form-item>
                                                </template>
                                            </template>
                                        </a-table>
                                        <a-upload v-model:file-list="model_form[key]"
                                            action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                            method="GET"
                                            @change="upload_file_multiple($event, key)"
                                            :show-upload-list="false"
                                            >
                                            <a-button style="margin-top: 10px;">
                                                <span class="material-symbols-outlined">add</span>
                                                เพิ่มไฟล์
                                            </a-button>
                                        </a-upload>
                                    </div>
                                    
                                    <a-input v-else v-model:value="model_form[key]" :placeholder="placeholder"
                                        :type="input_type"
                                        :disabled="key === 'client_no' ? true : (action_type == 'create' ? false : non_editable)"
                                    />
                                </a-form-item>
                            </a-col>
                        </a-row>
                    </a-collapse-panel>
                    <a-collapse-panel key="4" :header="`เอกสารที่เกี่ยวข้อง`">
                        <a-row>
                            <a-col style="margin: 1rem" :span="col_span" v-for="
                                    {
                                        col_span, non_editable, input_type, search_select, modelname, label_key, label, placeholder, required,
                                        target_key, target_modelname, drawer_title, label_function, table_columns, drawer_fields, drawer_button_text, drawer_button_text_edit, parent, null_on_change, check_duplicate, check_rule,
                                        on_change
                                    }, key in default_fields.Documentation.inputs">
                                <div v-if="input_type== 'label'" style="font-weight: bold; color: #1d39c4;">{{ label }}</div>
                                <a-form-item v-else :name="key" :label="placeholder" :has-feedback="check_duplicate"
                                    :rules="check_duplicate? check_rule: (non_editable ? null : [{ required, message: `โปรดระบุ ${placeholder}` }])">
                                    <div v-if="input_type == 'table_files'">
                                        <a-table
                                            :columns="[
                                                {
                                                    title: 'ชื่อเอกสาร',
                                                    dataIndex: 'name',
                                                    key: 'name',
                                                    width: 400,
                                                },
                                                {
                                                    title: 'ไฟล์เอกสาร',
                                                    dataIndex: 'file',
                                                    key: 'file',
                                                },
                                                {
                                                    title: 'ลบ',
                                                    dataIndex: 'id',
                                                    key: 'id',
                                                    width: 100,
                                                    align: 'center',
                                                }
                                            ]"
                                            :dataSource="data_raw_files"
                                            :pagination="false"
                                            :scroll="{ y: 240 }"
                                        >
                                            <template #bodyCell="{ column, record, index}" >
                                                <template v-if="column.key === 'name'">
                                                    <a-form-item>
                                                        <a-input v-model:value="record.name"
                                                        />
                                                    </a-form-item>
                                                </template>
                                                <template v-if="column.key === 'file'">
                                                    <a-form-item>
                                                    <a :href="record.file" target="_blank" rel="doc">{{ record.file_name }}</a>
                                                    </a-form-item>
                                                </template>
                                                <template v-if="column.key === 'id'">
                                                    <a-form-item>
                                                        <a-button danger type="text" @click="(data_raw_files.splice(index, 1))">
                                                            <span class="material-symbols-outlined">delete</span>
                                                        </a-button>
                                                    </a-form-item>
                                                </template>
                                            </template>
                                        </a-table>
                                        <a-upload v-model:file-list="model_form[key]"
                                            action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                            method="GET"
                                            @change="upload_file_multiple($event, key)"
                                            :show-upload-list="false"
                                            >
                                            <a-button style="margin-top: 10px;">
                                                <span class="material-symbols-outlined">add</span>
                                                เพิ่มไฟล์
                                            </a-button>
                                        </a-upload>
                                    </div>
                                    <a-divider v-else-if="input_type == 'divider'" orientation="left">{{ label
                                    }}</a-divider>
                                </a-form-item>
                            </a-col>
                        </a-row>
                    </a-collapse-panel>
                    <a-collapse-panel key="5" :header="`ผู้ดูแลโปรเจกต์ฝ่ายบริหาร`">
                        <template #extra>
                            <p v-if="requireAdminsFields" style="margin: 0; color: grey">
                                {{ `(ต้องระบุอีก ${requireAdminsFields})` }}
                            </p>
                        </template>
                        <a-row>
                            <a-col style="margin: 1rem" :span="col_span" v-for="
                                    {
                                        col_span, non_editable, input_type, search_select, modelname, label_key, label, placeholder, required,
                                        target_key, target_modelname, drawer_title, label_function, table_columns, drawer_fields, drawer_button_text, drawer_button_text_edit, parent, null_on_change, check_duplicate, check_rule,
                                        on_change
                                    }, key in default_fields.Admins.inputs">
                                <div v-if="input_type== 'label'" style="font-weight: bold; color: #1d39c4;">{{ label }}</div>
                                <a-form-item v-else :name="key" :label="placeholder" :has-feedback="check_duplicate"
                                    :rules="check_duplicate? check_rule: (non_editable ? null : [{ required, message: `โปรดระบุ ${placeholder}` }])">
                                    <a-select v-if="key === 'admins'" 
                                        show-search v-model:value="model_form[key]" 
                                        @change="handleChange(null_on_change , on_change)" 
                                        :options="admins_list.map((e) => ({ value: e.fullname, label: e.fullname }))"
                                        :filter-option="filterOption"
                                        >
                                        <a-select-option v-for="option in admins_list" :key="option.id" :value="option.fullname">
                                            {{ option.fullname }}
                                        </a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-col>
                        </a-row>
                    </a-collapse-panel>                    
                </a-collapse>
                <a-row justify="end" style="margin-top: 14px;">
                    <a-form-item>
                        <a-button type="primary" size="large" html-type="submit">
                            <template #icon>
                                <span style="font-size: 20px; color: white" class="material-symbols-outlined">save</span>
                            </template>
                            บันทึกข้อมูล
                        </a-button>
                    </a-form-item>
                </a-row>
            </a-form>
        </a-card>
    </div>
</template>
<script>
import Utility from '../../utility'
import Swal from 'sweetalert2'
import SystemService from '../../api/SystemService'
import AdminService from '../../api/AdminService';
import TableCreateDrawer from '@/components/table_create_drawer_project.vue'
import TableImportExport from '@/components/table_import_export.vue'
import { ReloadOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
import dayjs from 'dayjs'
import ProjectService from '../../api/ProjectService';
import AdministrativeService from '../../api/AdministrativeService';
import ClientService from '../../api/ClientService.';
import project_fields from '../../field_config/project_fields';
import priceRateFields from '../../components/price_rate_drawer_field';
import CarTypeService from '../../api/CarTypeService';
export default {
    components: {
        ReloadOutlined, 
        TableCreateDrawer,
        TableImportExport, 
    },
    name: 'ProjectCreateEdit',
    computed: {
        title() {
            return this.$route.params.id != 'create' ?
                'แก้ไขโปรเจกต์ลูกค้า' :
                'เพิ่มโปรเจกต์ลูกค้า'
        },
        requireProjectFields() {
            const fieldsToCheck = ['client_id', 'project_name'];
            return fieldsToCheck.filter(field => !this.model_form[field]).length;
        },
        requireOilFields() {
            const fieldsToCheck = ['oil_range_id', 'price_type_id'];
            return fieldsToCheck.filter(field => !this.model_form[field]).length;
        },
        requireAdminsFields() {
            const fieldsToCheck = ['admins'];
            return fieldsToCheck.filter(field => !this.model_form[field]).length;
        },
    },
    data() {
        return {
            leave_user: [],
            projectList: [],
            client_list: [],
            admins_list: [],
            selectedLeave: {},
            model_form: {},
            form_layout:'vertical',
            priceTypes: [{'id':1,'name':'ตามระยะทาง'},{'id':2,'name':'เรทราคา'}],
            showModalLeaveUserData: false,
            timeStart: null,
            timeEnd: null,
            loading: false,
            search_business_type: null,
            activeKey: [],
            price_rate_drawer_fields: [],
            default_fields: {
                Project: {
                    ...project_fields,
                    inputs: {
                        ...project_fields.inputs || {},
                    }
                },
                PriceRate:{
                    inputs:{
                        divider_3: {
                            input_type: 'divider',
                            label: 'ช่วงน้ำมัน',
                            col_span: 22
                        },
                        oil_range_id: {
                            input_type: 'select',
                            modelname: 'OilRange',
                            label_key: 'name',
                            placeholder: 'ช่วงน้ำมัน',
                            required: true,
                            col_span: 23
                        },
                        divider_4: {
                            input_type: 'divider',
                            label: 'ตามระยะทาง/เรทราคา',
                            col_span: 23
                        },
                        price_type_id: {
                            input_type: 'radio',
                            placeholder: 'ตามระยะทาง/เรทราคา :',
                            required: true,
                            price_type: true,
                            col_span: 23,
                        },
                    }
                },
                ContactInfomation:{
                    inputs:{
                        divider_5: {
                            input_type: 'divider',
                            label: 'เงื่อนไขการชำระเงิน',
                            col_span: 23
                        },
                        collect_date: {
                            input_type: 'number',
                            placeholder: 'วันที่เก็บเงินลูกค้า ทุกวันที่',
                            // required: true,
                            required: false,
                            col_span: 11
                        },
                        confirm_date: {
                            input_type: 'number',
                            placeholder: 'วันที่คอนเฟิร์ม ทุกวันที่',
                            // required: true,
                            required: false,
                            col_span: 11
                        },
                        document_date: {
                            input_type: 'number',
                            placeholder: 'วันที่ต้องส่งเอกสาร ทุกวันที่',
                            // required: true,
                            required: false,
                            col_span: 11
                        },
                        credit_amount: {
                            input_type: 'number',
                            placeholder: 'จำนวนเครดิต',
                            // required: true,
                            required: false,
                            col_span: 11
                        },
                        payment_method_id: {
                            input_type: 'select',
                            modelname: 'PaymentMethod',
                            label_key: 'name',
                            placeholder: 'วิธีการชำระเงิน',
                            col_span: 11
                        },
                        divider_6: {
                            input_type: 'divider',
                            label: 'ผู้ติดต่อ',
                            col_span: 23
                        },
                        contact_name: {
                            input_type: 'text',
                            placeholder: 'ชื่อผู้ติดต่อ ฝ่ายดูแลโปรเจกต์',
                            //required: true,
                            col_span: 11
                        },
                        contact_tel: {
                            input_type: 'text',
                            placeholder: 'เบอร์โทร',
                            // required: true,
                            col_span: 11
                        },
                        contact_email: {
                            input_type: 'text',
                            placeholder: 'อีเมล',
                            // required: true,
                            col_span: 11
                        },
                        blank: {
                            col_span: 11
                        },
                        account_name: {
                            input_type: 'text',
                            placeholder: 'ชื่อผู้ติดต่อ ฝ่ายบัญชี',
                            //required: true,
                            col_span: 11
                        },
                        account_tel: {
                            input_type: 'text',
                            placeholder: 'เบอร์โทร',
                            // required: true,
                            col_span: 11
                        },
                        account_email: {
                            input_type: 'text',
                            placeholder: 'อีเมล',
                            // required: true,
                            col_span: 11
                        },
                    }
                },
                Documentation:{
                    inputs:{
                        divider_7: {
                            input_type: 'divider',
                            label: 'เอกสารจากฝั่งลูกค้า',
                            col_span: 23
                        },
                        data_raw_files: {
                            input_type: 'table_files',
                            required: false,
                            col_span: 23,                        
                        },
                    }
                },
                Admins:{
                    inputs:{
                        admins: {
                            input_type: 'select',
                            modelname: 'Admin',
                            label_key: 'fullname',
                            placeholder: 'ผู้ดูแลโปรเจกต์ฝ่ายบริหาร',
                            required: true,
                            col_span: 23
                        },
                    }
                }
            },
            data_raw_files: [],
            bookingTypeList : [
                {'id':1,'name':'ขาเดียว'},
                {'id':2,'name':'ไปกลับ'},
                {'id':3,'name':'การขนส่งแบบวิ่งเก็บ (Milkrun)'},
            ],
            paymentMethodList : [
                {'id':1,'name':'โอน'},
                {'id':2,'name':'เช็ค/เงินสด'},
            ],
            DistanceRate:{
                drawer_title:'ข้อมูลช่วงน้ำมัน',
                drawer_button_text:'เพิ่มตามระยะทาง',
                drawer_button_text_edit:'แก้ไขตามระยะทาง',
                target_modelname:'DistanceRate',
                columns:
                    [
                        {
                            title: 'ลำดับ',
                            dataIndex: 'index',
                            key: 'index',
                            align: 'center',
                            width: 75,
                        },
                        {
                            title: 'ต้นทาง',
                            dataIndex: 'start_place',
                            key: 'start_place',
                            align: 'center',
                            width: 250,
                        },
                        {
                            title: 'ช่วงระยะทาง',
                            dataIndex: 'distance',
                            key: 'distance',
                            align: 'center',
                            width: 150,
                        },
                        {
                            title: 'ชนิดรถ',
                            dataIndex: 'car_type_id',
                            key: 'car_type_id',
                            align: 'center',
                            width: 150,
                        },
                        {
                            title: 'ราคา',
                            dataIndex: 'price_amount',
                            key: 'price_amount',
                            align: 'center',
                            width: 135,
                        },
                        {
                            title: 'ตัวเลือก',
                            dataIndex: 'id',
                            key: 'id',
                            width: 110,
                            align: 'center',
                            fixed: 'right'
                        },
                    ],
                drawer_fields: {
                    inputs: {
                        start_place: {
                            input_type: 'text',
                            placeholder: 'ต้นทาง',
                            required: true,
                            col_span: 23
                        },
                        distance_1:{
                            input_type: 'number',
                            placeholder: 'ช่วงระยะทาง1',
                            required: true,
                            col_span: 13
                        },
                        distance_2:{
                            input_type: 'number',
                            placeholder: 'ช่วงระยะทาง2',
                            required: true,
                            col_span: 10
                        },
                        car_type_id: {
                            input_type: 'select',
                            modelname: 'CarType',
                            label_key: 'name',
                            placeholder: 'ชนิดรถ',
                            required: true,
                            col_span: 23,
                            option:[],
                        },
                        price_amount: {
                            input_type: 'number',
                            placeholder: 'ราคา',
                            required: true,
                            col_span: 23
                        },
                    }
                }
            },
            img_placeholder: require('@/assets/images/place-holder/placeholder.jpg'),
            show_table_list: [],
            projectType: [],
            carTypeList: [],
            oilRangeList:[],
            fileList: [],
            project_edit_list: null,
            button_title: 'เพิ่มโปรเจกต์',
            input: 'Username / ชื่อบริษัท',
            search_input: null,
        }
    },
    watch: {

    },
    methods: {
        clear_model_data() {
            this.$emit('apply_model_data', {})
            this.$router.go(-1)
        },
        render_date(datetime) {
            return dayjs(datetime).format('YYYY/MM/DD')
        },
        async upload_file_multiple(e, key) {
            if (e.file.status != 'remove') {
                var file = e.file.originFileObj
                if (key) {
                        const vue = this
                        if (!vue.data_raw_files) {
                            vue.data_raw_files = [];
                        }
                        var reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = function () {
                            if(vue.data_raw_files.map(e => e.file_name).includes(file.name)) return
                            vue.data_raw_files.push({
                                file_name: file.name,
                                name: '',
                                file: reader.result,
                            })
                        };
                } else {
                    return await new Promise((resolve, reject) => {
                        if (file) {
                            if (typeof file == 'string') {
                                resolve(file)
                            } else {
                                const reader = new FileReader();
                                reader.readAsDataURL(file);
                                reader.onload = () => resolve(reader.result);
                                reader.onerror = error => reject(error);
                            }
                        }
                    });
                }
                return `https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6`
            }
        },
        async handleChange(type, event) {
            if (type === 'start') {
                this.start = event.target.checked;
                if (this.start) {
                    this.end = false;
                    this.model_form.driver_stoped_employment_date = null;
                }
            } else if (type === 'end') {
                this.end = event.target.checked;
                if (this.end) {
                    this.start = false;
                }
            }
            if (type === 'disable') {
                this.disabletest = !this.disabletest;
                if (this.disabletest) {
                    this.disableId = "บริษัทรถ"
                    this.car_company = await SystemService.get_all("CarCompany")
                    this.model_form.car_company_id = this.car_company[0].id
                } else {
                    this.car_company = await CarCompanyService.get_car_companies_3rd();
                    this.disableId = "บริษัทรถร่วม"
                    this.model_form.car_company_id = this.car_company[0].id
                }
            }
            if(type == 'oil_range_id'){
                if(this.project_edit_list){
                    const applyDiff = (value, diffValue) => value + (value * ((2 * diffValue) / 100));
                    const diffValue = event - this.project_edit_list.oil_range_id;
                    if(event > this.project_edit_list.oil_range_id || event < this.project_edit_list.oil_range_id){
                        this.model_form['priceRate'] = this.model_form['priceRate'].map((item)=>{
                            return{
                            ...item,
                            C_4WD: applyDiff(item.C_4WD, diffValue),
                            C_4WH: applyDiff(item.C_4WH, diffValue),
                            C_4WJ: applyDiff(item.C_4WJ, diffValue),
                            C_4WT: applyDiff(item.C_4WT, diffValue),
                            C_6WH: applyDiff(item.C_6WH, diffValue),
                            C_10WD: applyDiff(item.C_10WD, diffValue),
                            C_10WH: applyDiff(item.C_10WH, diffValue),
                            C_10WT: applyDiff(item.C_10WT, diffValue),
                            C_18WH: applyDiff(item.C_18WH, diffValue),
                            C_22WH: applyDiff(item.C_22WH, diffValue),
                            H_4WD: applyDiff(item.H_4WD, diffValue),
                            H_4WH: applyDiff(item.H_4WH, diffValue),
                            H_4WJ: applyDiff(item.H_4WJ, diffValue),
                            H_4WT: applyDiff(item.H_4WT, diffValue),
                            H_6WH: applyDiff(item.H_6WH, diffValue),
                            H_10WD: applyDiff(item.H_10WD, diffValue),
                            H_10WH: applyDiff(item.H_10WH, diffValue),
                            H_10WT: applyDiff(item.H_10WT, diffValue),
                            H_18WH: applyDiff(item.H_18WH, diffValue),
                            H_22WH: applyDiff(item.H_22WH, diffValue),
                            }
                        })
                    }
                    this.project_edit_list.oil_range_id = event;
                }
            }
            this.showEmploymentEndDate = !this.start;
        },
        getApproveStatusName(status) {
            if (status == 1) {
                return 'อนุมัติ';
            } else if (status == 2) {
                return 'ไม่อนุมัติ';
            } else {
                return '';
            }
        },
        validateDateInput(event, key) {
            const value = event.target.value;

            if (value === '' || value === null) {
                this.model_form[key] = null;
            } else {
                const numericValue = Number(value);

                if (numericValue < 1) {
                this.model_form[key] = 1;  
                } else if (numericValue > 31) {
                this.model_form[key] = 31; 
                } else {
                this.model_form[key] = numericValue; 
                }
            }
        },
        remove_leave_user(id){
            Swal.fire({
                title: 'คุณต้องการลบใช่หรือไม่??',
                text: `คุณจะไม่สามารถกู้คืนได้`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'ใช่',
                cancelButtonText: 'ยกเลิก'
                }).then(async (result) => {
                if (result.isConfirmed) {
                    await SystemService.delete_all('LeaveAdmin', id)
                    this.onRefresh()
                }
            })
        },
        showLeaveDetails(record) {
            this.selectedLeave = record;
            this.showModalLeaveUserData = true;
        },
        onRefresh() {
             this.init_leave_admins()
        },
        async handleChangeApproveStatus(id, value) {
            await SystemService.update_all('LeaveAdmin', { data: { approve_status: value } }, id);
            this.onRefresh();
        },
        async handleChangeApprover(id, fullname) {
            await SystemService.update_all('LeaveAdmin', { data: { approver: fullname} }, id);
            this.onRefresh();
        },
        clear_search() {
            this.search_input = null;
            this.timeStart = null;
            this.timeEnd = null;
        },
        render_date(datetime) {
            return dayjs(datetime).format('YYYY/MM/DD')
        },
        filtered_list(array) {
            return array.filter(({ name, time_start, time_end }) => {
                let matchesName = true;
                let matchesDateRange = true;
                if (this.search_input) {
                    matchesName = name?.toLowerCase().includes(this.search_input.toLowerCase());
                }
                // ตรวจสอบช่วงวันที่ (ไม่สนใจเวลา)
                if (this.timeStart && this.timeEnd) {
                    const filterStart = dayjs(this.timeStart).startOf('day');
                    const filterEnd = dayjs(this.timeEnd).startOf('day');
                    const start = dayjs(time_start).startOf('day');
                    const end = dayjs(time_end).startOf('day');
                    matchesDateRange = start.isSame(filterStart) && end.isSame(filterEnd);
                }
                return matchesName && matchesDateRange;
            });
        },
        async init_project () {
            this.projectList = await ProjectService.getProjectAll();
            this.projectList = this.projectList.map((project, index) => {
                return {
                    ...project,
                    orderNumber: index + 1 // Add 1 because index starts at 0
                };
            });
            this.oilRangeList = await ProjectService.getOillRangeAll();
            this.projectType = await ProjectService.getProjectTypeAll();
            this.client_list = await ClientService.getClientByCompany();
            const carTypeArray = await CarTypeService.getCarTypeByCompany();
            this.carTypeList = carTypeArray.map(data => ({
                label: data.name,
                value: data.id,
            }));
            this.DistanceRate.drawer_fields.inputs.car_type_id.options = this.carTypeList
            const adminsData = await SystemService.get_core('getAllAdminName');
            const admintrativeData = await AdministrativeService.getAdministrativeByCompany();
            this.admins_list = [...adminsData, ...admintrativeData].map(admin => {
                if (admin.firstname && admin.lastname) {
                    return {
                    ...admin,
                    fullname: `${admin.firstname} ${admin.lastname}`
                    };
                }
                return admin;
            });
            this.price_rate_drawer_fields = priceRateFields
        },
        async init_edit_project(){
            message.loading({ content: "กำลังโหลดข้อมูล...", key: "loading" });
            const idProject = this.$route.params.id;
            const projectData = await ProjectService.getProjectById(idProject);
            this.model_form = projectData[0];
            this.project_edit_list = {...projectData[0]};
            if (projectData[0]?.data_raw_files) {
                const parsedFiles = this.safeParseJSON(projectData[0].data_raw_files);
                this.data_raw_files = Array.isArray(parsedFiles) ? parsedFiles : [];
                delete this.model_form.data_raw_files
            }
            this.model_form['priceRate'] = projectData?.priceRate;
            this.model_form['distanceRate'] = projectData?.distanceRate;
            message.success({ content: "โหลดข้อมูลสำเร็จ", key: "loading" });
        },
        safeParseJSON(jsonString) {
            try {
                return jsonString ? JSON.parse(jsonString) : null;
            } catch (error) {
                console.error('Error parsing JSON:', jsonString, error);
                return null;
            }
        },
        getProjectTypeName(id) {
            const projectType = this.projectType?.find(type => type.id === id);
            return projectType ? projectType.name : '';
        },
        getBookingTypeName(id) {
            const bookingType = this.bookingTypeList?.find(type => type.id === id);
            return bookingType ? bookingType.name : '';
        },
        getClientName(id) {
            const clientName = this.client_list.find(type => type.id === id);
            return clientName ? clientName.fullname : '';
        },
        getPriceTypeName(id) {
            const priceTypeName = this.priceTypes.find(type => type.id === id);
            return priceTypeName ? priceTypeName.name : '';
        },
        async validate_model_form() {
            try {
                if (this.data_raw_files && this.data_raw_files.length) {
                    this.model_form.data_raw_files = this.data_raw_files;
                }else{
                    this.model_form.data_raw_files = [];
                }
                if (this.data_raw_files_insure && this.data_raw_files_insure.length) {
                    this.model_form.data_raw_files_insure = this.data_raw_files_insure
                }
                if (this.model_form.create_options) {
                    this.model_form.car_options = this.model_form.create_options.map((id) => {
                        return {
                            option_id: id
                        }
                    })
                    delete this.model_form.create_options
                }
                if (this.model_form.photos) {
                    for (var i = 0; i < this.model_form.photos.length; i++) {
                        this.model_form[`photo_${i + 1}`] = await this.getBase64(this.model_form.photos[i].originFileObj)
                    }
                    delete this.model_form.photos
                    delete this.model_form.create_photos
                }
                if(this.model_type == "CarCompany"){
                    this.model_form.permissions = "1";
                }
                this.model_form.company_id = this.company_id
            } catch (err) {
                console.log(err);
            }
        },
        async submit_model_data() {
            const type = this.$route.params.id == 'create' ? 'create_all' : 'update_all';
            if(this.requireProjectFields){
                this.activeKey = 1;
                this.$nextTick(() => {
                    const field = document.querySelector('#requiredFieldId'); 
                    if (field) {
                        field.focus();
                    }
                });
                return
            }
            if(this.requireOilFields){
                this.activeKey = 2;
                return
            }
            if(this.requireAdminsFields){
                this.activeKey = 5;
                return
            }
            const data = JSON.parse(JSON.stringify(this.model_form));

             try {
                Swal.fire({
                    title: 'กรุณารอสักครู่',
                    text: 'กำลังบันทึกข้อมูล',
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    willOpen: () => {
                        Swal.showLoading();
                    }
                });
                this.validate_model_form();
                data.data_raw_files = this.model_form.data_raw_files;
                if (type === 'create_all') {
                    let res = await ProjectService.createProject({ data: [data] });
                } else {
                    let res = await ProjectService.updateProject({ data: [data] }, this.model_form.id);
                }
                Swal.close();
                Swal.fire({
                    title: 'บันทึกข้อมูลสำเร็จ',
                    icon: 'success',
                    timer: 2500,
                    timerProgressBar: false,
                    showConfirmButton: false,
                });
            } catch (error) {
                Swal.close();
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    text: 'ไม่สามารถบันทึกข้อมูลได้',
                    icon: 'error',
                    confirmButtonText: 'ตกลง',
                });
                console.log(error);
            } finally {
                this.clear_model_data();
            }
        },
    },
    async mounted() {
        await this.init_project();
        if(this.$route.params.id != 'create'){
            await this.init_edit_project();
        }
    }
}
</script>

<style scoped>
.add_car {
    background-color: #e5e4e2;
}
.upload-container {
    position: relative;
    width: 100%;
    height: 100px;
}

.uploaded-image {
    width: 100%;
    height: 100%;
    transition: filter 0.3s ease;
    cursor: pointer;
}

.upload-container:hover .uploaded-image {
    filter: grayscale(100%);
}

.icon-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.upload-container:hover .material-symbols-outlined {
    color: white;
}

.ant-picker {
    width: 100%;
}
</style>