
import httpClient from './httpClient';
const prefix = 'driver'

export default {

    async getDriverAll () {
    const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/getDriverAll`)

    return data
  },
  
  async getDriverByCompanyAndId (id) {
    const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/getDriverByCompanyAndId/${id}`)

    return data
  },
  async getDriverByCompany () {
    const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/getDriverByCompany`)

    return data
  },
  async getDrivingLicenseTypeAll () {
    const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/getDrivingLicenseTypeAll`)

    return data
  },
  async getDriverWorkStatusAll () {
    const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/getDriverWorkStatusAll`)

    return data
  },
  async getDriverWorkStatusAndId (id) {
    const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/getDriverWorkStatusAndId/${id}`)

    return data
  },
  async createDriver (body) {
    const response = await httpClient({ requiresAuth: true }).post(`${prefix}/createDriver`, body)

    return response
  },

  async updateDriver (body, id) {
    const response = await httpClient({ requiresAuth: true }).put(`${prefix}/updateDriver/${id}`, body)

    return response
  },

  async updateDriverIsActiveById (body, id) {
    const response = await httpClient({ requiresAuth: true }).put(`${prefix}/updateDriverIsActiveById/${id}`, body)

    return response
  }
}