import httpClient from './httpClient';

const prefix = 'carType'

export default {
    async getCarTypeAll() {
        const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/getCarTypeAll`)
        return data
    },
    async getCarTypeByCompany () {
        const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/getCarTypeByCompany`)
        return data
    },
    async getCarTypeByCompanyAndId (id) {
        const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/getCarTypeByCompanyAndId/${id}`)
        return data
    },
}