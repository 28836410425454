<template>
    <div style="padding: 15px;height: 100vh">
        <div class="app-content">
            <a-card v-if="render_type == 'all'" title="จัดการลูกค้า">
                <template v-if="!business" #extra>
                    <a-space v-if="editable">
                        <a-button @click="go_create('Client')" v-if="editable" style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">Add</span></template>
                            เพิ่มลูกค้า
                        </a-button>
                         <!-- <a-dropdown>
                            <template #overlay>
                                <a-menu @click="import_export_dropdown">
                                    <a-menu-item key="1" v-if="editable">
                                        <a-upload
                                            style="display: flex"
                                            v-model:file-list="import_fileList"
                                            :show-upload-list="false"
                                            name="file"
                                            action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                            method="GET"
                                            @change="import_change"
                                        >
                                            <a-button ref="import_file">
                                                <span class="material-symbols-outlined">publish</span>Import
                                            </a-button>
                                        </a-upload>
                                    </a-menu-item>
                                    <a-menu-item key="2">
                                        <a-button ref="import_file">
                                            <span class="material-symbols-outlined">download</span>Export
                                        </a-button>
                                    </a-menu-item>
                                </a-menu>
                            </template>
                            <a-button style="display: flex" size="large">
                                <template #icon><span class="material-symbols-outlined">Settings</span></template>
                                ตัวเลือก
                            </a-button>
                        </a-dropdown> -->
                        <a-dropdown>
                            <template #overlay>
                                <a-menu @click="import_export_dropdown">
                                    <a-menu-item key="1">
                                        <a-upload
                                            style="display: flex"
                                            v-model:file-list="import_fileList"
                                            :show-upload-list="false"
                                            name="file"
                                            method="GET"
                                            @change="import_change"
                                        >
                                            <a-button ref="import_file">
                                                <span class="material-symbols-outlined">publish</span>Import
                                            </a-button>
                                        </a-upload>
                                    </a-menu-item>
                                    <a-menu-item key="2">
                                        <a-button ref="import_file">
                                            <span class="material-symbols-outlined">download</span>Export
                                        </a-button>
                                    </a-menu-item>
                                </a-menu>
                            </template>
                            <a-button style="display: flex" size="large">
                                <template #icon><span class="material-symbols-outlined">Settings</span></template>
                                ตัวเลือก
                            </a-button>
                        </a-dropdown>
                    </a-space>
                </template>
                <a-row v-if="business"  style="display: flex; justify-content: flex-end; margin-bottom: 16px;">
                    <a-space v-if="editable">
                        <a-button @click="go_create('Client')" v-if="editable" style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">Add</span></template>
                            เพิ่มลูกค้า
                        </a-button>
                        <a-dropdown>
                            <template #overlay>
                                <a-menu @click="import_export_dropdown">
                                    <a-menu-item key="1">
                                        <a-upload
                                            style="display: flex"
                                            v-model:file-list="import_fileList"
                                            :show-upload-list="false"
                                            name="file"
                                            method="GET"
                                            @change="import_change"
                                        >
                                            <a-button ref="import_file">
                                                <span class="material-symbols-outlined">publish</span>Import
                                            </a-button>
                                        </a-upload>
                                    </a-menu-item>
                                    <a-menu-item key="2">
                                        <a-button ref="import_file">
                                            <span class="material-symbols-outlined">download</span>Export
                                        </a-button>
                                    </a-menu-item>
                                </a-menu>
                            </template>
                            <a-button style="display: flex" size="large">
                                <template #icon><span class="material-symbols-outlined">Settings</span></template>
                                ตัวเลือก
                            </a-button>
                        </a-dropdown>
                    </a-space>
                </a-row>
                <a-row>
                    <a-space>
                        <a-input-search v-model:value="search_input" placeholder="ค้นหาลูกค้า/บริษัท"
                            enter-button="ค้นหา" />
                        <a-button @click="(search_input = null)" type="dashed">CLEAR</a-button>
                    </a-space>

                </a-row>
                <br>
                <a-row>
                    <a-table :columns="filter_columns([
                        {
                            title: 'รหัสลูกค้า',
                            dataIndex: 'client_no',
                            key: 'client_no',
                            width: 150,
                            sorter: true,
                            align: 'center',
                        }, {
                            title: 'ชื่อบริษัท/ลูกค้า',
                            dataIndex: 'fullname',
                            key: 'fullname',
                            width: 250,
                            sorter: true,
                            ellipsis: true,
                            align: 'center',
                        }, {
                            title: 'ประจำสาขาไหน',
                            dataIndex: 'client_branch_name',
                            key: 'client_branch_name',
                            width: 250,
                            sorter: true,
                            align: 'center',
                            // ellipsis: true,
                        }, {
                            title: 'ชื่อ - นามสกุล ผู้ประสานงาน',
                            dataIndex: 'client_contacts',
                            key: 'client_contacts',
                            width: 200,
                            align: 'center',
                        }, {
                            title: 'บิลชำระล่าช้า / บิลทั้งหมด',
                            dataIndex: 'billings',
                            key: 'billings',
                            width: 200,
                            align: 'center',
                        }, {
                            title: 'อีเมลองค์กร',
                            dataIndex: 'email',
                            key: 'email',
                            width: 150,
                            align: 'center'
                        },
                        //  {
                        //     title: 'อีเมลผู้ประสานงาน',
                        //     dataIndex: 'coordinator_email',
                        //     key: 'coordinator_email',
                        //     width: 150,
                        //     align: 'center'
                        // },
                        {
                            title: 'เบอร์โทร',
                            dataIndex: 'phone_no',
                            key: 'phone_no',
                            width: 125,
                            align: 'center'
                        },
                        // {
                        //     title: 'เบอร์แฟกซ์',
                        //     dataIndex: 'fax_no',
                        //     key: 'fax_no',
                        //     width: 125,
                        //     align: 'center'
                        // }, 
                        {
                            title: 'ตัวเลือก',
                            dataIndex: 'id',
                            key: 'id',
                            width: 85,
                            align: 'center',
                            fixed: 'right'
                        }
                    ])" :data-source="filtered_array(clients,'fullname')" bordered size="small" :scroll="{ x: 'calc(100px + 50%)', y: '100%' }">
                        <template #bodyCell="{ column, record }">
                            <template v-if="column.key === 'id'">
                                <div>
                                    <a @click="go_edit(record, record.modelname)"><span style="font-size: 14px"
                                            class="material-symbols-outlined">drive_file_rename_outline</span></a>
                                    <a-divider type="vertical"></a-divider>
                                    <a @click="go_remove(record.id, record.modelname)"><span style="font-size: 14px"
                                            class="material-symbols-outlined">delete</span></a>
                                </div>
                            </template>
                            <template v-else-if="column.key === 'fullname'">
                                <div style=" white-space: normal; overflow-wrap: break-word;">
                                    {{ record.fullname }}
                                </div>
                            </template>
                            <template v-else-if="column.key === 'client_no'">
                                <div>
                                    {{ record.client_no }}
                                </div>
                            </template>
                            <template v-else-if="column.key === 'client_branch_name'">
                                <div>
                                    {{ record.branches.map(branch => branch.name).join(', ') }}
                                </div>
                            </template>
                            <!-- <template v-else-if="column.key === 'coordinator_email'">
                                <div>
                                    {{ record.contacts.map(contact => contact.email)
                                                        .filter(email => email && email !== '-')
                                                        .join(', ') }}
                                </div>
                            </template> -->
                            <template v-else-if="column.key === 'client_contacts'">
                                <div class="contacts-list">
                                    {{ (record.contacts || []).map(contact => `${contact?.firstname || '-'} ${contact?.lastname || '-'}`).join(',\n') }}
                                </div>
                            </template>
                            <template v-else-if="column.key === 'billings'">
                                <div v-if="record.billings.length">
                                    <a-badge-ribbon v-if="((record.billings.filter(({ billing_status_id }) => billing_status_id == 6).length / record.billings.length) * 100) > 20" text="เกิน" color="red">
                                        <a-card :title="`${record.billings.filter(({ billing_status_id }) => billing_status_id == 6).length } / ${record.billings.length} (${Math.round(((record.billings.filter(({ billing_status_id }) => billing_status_id == 6).length / record.billings.length) * 100))}%)`" size="small"></a-card>
                                    </a-badge-ribbon>
                                    <a-card v-else :title="`${record.billings.filter(({ billing_status_id }) => billing_status_id == 6).length } / ${record.billings.length} (${Math.round(((record.billings.filter(({ billing_status_id }) => billing_status_id == 6).length / record.billings.length) * 100))}%)`" size="small"></a-card>
                                </div>
                                <div v-else>
                                    <a-card :title="`0 / 0 (0%)`" size="small"></a-card>
                                </div>
                            </template>
                        </template>
                        </a-table>
                </a-row>
            </a-card>

            <a-card v-else-if="render_type == 'TourType'" title="จัดการประเภทกลุ่มลูกค้า">
                <template  v-if="!business" #extra>
                    <a-space v-if="editable">
                        <a-button @click="go_create('TourType')" style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">Add</span></template>
                            เพิ่มประเภทกลุ่มลูกค้า
                        </a-button>
                    </a-space>
                </template>
                <a-row  v-if="business" style="display: flex; justify-content: flex-end; ">
                    <a-space v-if="editable">
                        <a-button @click="go_create('TourType')" style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">Add</span></template>
                            เพิ่มประเภทกลุ่มลูกค้า
                        </a-button>
                    </a-space>
                </a-row>

                <a-row>
                    <a-space>
                        <a-input-search v-model:value="search_input" placeholder="ค้นหาประเภทกลุ่มลูกค้า"
                            enter-button="ค้นหา" />
                        <a-button @click="(search_input = null)" type="dashed">CLEAR</a-button>
                    </a-space>

                </a-row>
                <br>
                <a-row>
                    <a-table :columns="[
                        {
                            title: 'ชื่อประเภทกลุ่มลูกค้า',
                            dataIndex: 'name',
                            key: 'name',
                            sorter: true,
                            align: 'center',

                        }, {
                            title: 'สีของประเภทกลุ่มลูกค้า',
                            dataIndex: 'color',
                            key: 'color',
                            align: 'center',
                            width: 150
                        }, {
                            title: 'ตัวเลือก',
                            dataIndex: 'id',
                            key: 'id',
                            align: 'center',
                            width: 85,
                            fixed: 'right'
                        }
                    ]" :data-source="filtered_array(tour_types, 'name')" bordered size="small"
                        :scroll="{ x: 'calc(100px + 50%)', y: '100%' }">
                        <template #bodyCell="{ column, record }">
                            <template v-if="column.key === 'id'">
                                <div>
                                    <a @click="go_edit(record, record.modelname)"><span style="font-size: 14px"
                                            class="material-symbols-outlined">drive_file_rename_outline</span></a>
                                    <a-divider type="vertical"></a-divider>
                                    <a @click="go_remove(record.id, record.modelname)"><span style="font-size: 14px"
                                            class="material-symbols-outlined">delete</span></a>
                                </div>
                            </template>
                            <template v-if="column.key === 'color'">
                                <a-tag :color="record.color" :style="{ width: '50px', height: '20px', borderRadius: '5px' }"></a-tag>
                            </template>

                        </template>

                    </a-table>
                </a-row>
            </a-card>
            <a-card v-if="render_type == 'OutsideCustomer'" title="จัดการประวัติลูกค้าภายนอก">
                <template v-if="!business" #extra>
                    <a-space v-if="editable">
                        <a-button @click="go_create('OutsideCustomer')" style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">Add</span></template>
                            เพิ่มลูกค้าภายนอก
                        </a-button>
                        <a-dropdown>
                            <template #overlay>
                                <a-menu @click="import_export_dropdown">
                                    <a-menu-item key="1">
                                        <a-upload
                                            style="display: flex"
                                            v-model:file-list="import_fileList"
                                            :show-upload-list="false"
                                            name="file"
                                            method="GET"
                                            @change="import_change_outside_clients"
                                        >
                                            <a-button ref="import_file">
                                                <span class="material-symbols-outlined">publish</span>Import
                                            </a-button>
                                        </a-upload>
                                    </a-menu-item>
                                    <a-menu-item key="3">
                                        <a-button ref="import_file">
                                            <span class="material-symbols-outlined">download</span>Export
                                        </a-button>
                                    </a-menu-item>
                                </a-menu>
                            </template>
                            <a-button style="display: flex" size="large">
                                <template #icon><span class="material-symbols-outlined">Settings</span></template>
                                ตัวเลือก
                            </a-button>
                        </a-dropdown>
                    </a-space>
                </template>
                <a-row v-if="business" style="display: flex; justify-content: flex-end; margin-bottom: 16px;">
                        <a-space v-if="editable">
                            <a-dropdown>
                                <template #overlay>
                                    <a-menu @click="import_export_dropdown">
                                        <a-menu-item key="1">
                                            <a-upload
                                                style="display: flex"
                                                v-model:file-list="import_fileList"
                                                :show-upload-list="false"
                                                name="file"
                                                method="GET"
                                                @change="import_change_outside_clients"
                                            >
                                                <a-button ref="import_file">
                                                    <span class="material-symbols-outlined">publish</span>Import
                                                </a-button>
                                            </a-upload>
                                        </a-menu-item>
                                        <a-menu-item key="3">
                                            <a-button ref="import_file">
                                                <span class="material-symbols-outlined">download</span>Export
                                            </a-button>
                                        </a-menu-item>
                                    </a-menu>
                                </template>
                                <a-button style="display: flex" size="large">
                                    <template #icon><span class="material-symbols-outlined">Settings</span></template>
                                    ตัวเลือก
                                </a-button>
                            </a-dropdown>
                        </a-space>
                    </a-row>

                <a-row style="text-align: center;">
                    <a-space>
                        <a-input-search v-model:value="search_input" placeholder="ค้นหาเลขที่ใบงาน" enter-button="ค้นหา" />
                    </a-space>
                    <a-space>
                        <div> </div>
                    </a-space>
                    <a-space>
                        <a-range-picker v-model:value="delivery_date" />
                        <a-button @click="clearSearch" type="dashed">CLEAR</a-button>
                    </a-space>
                </a-row>
                <br>
                <a-row>
                    <a-table
                        :columns="filter_columns([
                            {
                                title: 'วันที่ส่ง',
                                dataIndex: 'delivery_date',
                                key: 'delivery_date',
                                width: 150,
                                align: 'center', 
                            },
                            {
                                title: 'เลขที่ใบงาน',
                                dataIndex: 'job_id',
                                key: 'job_id',
                                width: 150,
                                align: 'center', 
                            },
                            {
                                title: 'บริษัทลูกค้า',
                                dataIndex: 'company_name',
                                key: 'company_name',
                                width: 150,
                                align: 'center', 
                            },
                            {
                                title: 'จุดรับ',
                                dataIndex: 'pick_up_name',
                                key: 'pick_up_name',
                                width: 150,
                                align: 'center', 
                            },
                            {
                                title: 'จุดส่ง',
                                dataIndex: 'delivery_name',
                                key: 'delivery_name',
                                width: 200,
                                align: 'center', 
                            },
                            {
                                title: 'เบอร์รถ',
                                dataIndex: 'truck_no',
                                key: 'truck_no',
                                width: 200,
                                align: 'center', 
                            },
                            {
                                title: 'ชื่อนามสกุลผู้ขับ',
                                dataIndex: 'driver_name',
                                key: 'driver_name',
                                width: 200,
                                align: 'center', 
                            },
                            {
                                title: 'ชนิดรถ',
                                dataIndex: 'truck_type',
                                key: 'truck_type',
                                width: 200,
                                align: 'center', 
                            },
                            {
                                title: 'ราคา/ทริป',
                                dataIndex: 'price_trips',
                                key: 'price_trips',
                                width: 200,
                                align: 'center', 
                            },
                            {
                                title: 'ค่าเบี้ยเลี้ยง',
                                dataIndex: 'allowance',
                                key: 'allowance',
                                width: 200,
                                align: 'center', 
                            },
                            {
                                title: 'เปอร์เซ็นต์',
                                dataIndex: 'percent',
                                key: 'percent',
                                width: 200,
                                align: 'center', 
                            },
                            {
                                title: 'ค่าบริหารรถ',
                                dataIndex: 'car_manage_fee',
                                key: 'car_manage_fee',
                                width: 200,
                                align: 'center', 
                            },
                            {
                                title: 'ยอดเงิน',
                                dataIndex: 'amount',
                                key: 'amount',
                                width: 200,
                                align: 'center', 
                            },
                            {
                                title: 'ตัวเลือก',
                                dataIndex: 'id',
                                key: 'id',
                                align: 'center', 
                                width: 85,
                                fixed: 'right',
                            }
                        ])"
                        :data-source="filtered_array(outside_clients, 'job_id')"
                        bordered
                        size="small"
                        :scroll="{ x: 'calc(100px + 50%)', y: '100%' }"
                    >
                        <template #bodyCell="{ column, record }">
                            <template v-if="column.key === 'id'">
                                <div style="text-align: center;">
                                    <a @click="go_edit(record, record.modelname)">
                                        <span style="font-size: 14px" class="material-symbols-outlined">drive_file_rename_outline</span>
                                    </a>
                                    <a-divider type="vertical"></a-divider>
                                    <a @click="go_remove_outside_client(record.id)">
                                        <span style="font-size: 14px" class="material-symbols-outlined">delete</span>
                                    </a>
                                </div>
                            </template>
                            <template v-if="column.key === 'delivery_date'">
                                <div style="text-align: center;">{{ formatDate(record.delivery_date) }}</div>
                            </template>
                            <template v-if="column.key === 'price_trips'">
                                <div style="text-align: center;">{{ Number(record.price_trips).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</div>
                            </template>
                            <template v-if="column.key === 'allowance'">
                                <div style="text-align: center;">{{ Number(record.allowance).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</div>
                            </template>
                            <template v-if="column.key === 'car_manage_fee'">
                                <div style="text-align: center;">{{ Number(record.car_manage_fee).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</div>
                            </template>
                            <template v-if="column.key === 'amount'">
                                <div style="text-align: center;">{{ Number(record.amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</div>
                            </template>
                            <template v-if="column.key === 'percent'">
                                <div style="text-align: center;">{{ (record.percent * 100).toLocaleString() }}%</div>
                            </template>
                        </template>
                    </a-table>
                </a-row>
            </a-card>
        </div>
    </div>
</template>

<script>
import ReportService from '../../api/ReportService'
import { message } from 'ant-design-vue';
import SystemService from '../../api/SystemService'
import Utility from '../../utility'
import Swal from 'sweetalert2'
import { exportXLSXFile, xlxsBase64toJson } from '@/components/helpers.js'
import dayjs from 'dayjs';
import { utils, writeFile } from 'xlsx';
import XLSX from "xlsx-js-style";
import UserService from '../../api/UserService';
import CompanyService
 from '../../api/CompanyService';
import ClientService from '../../api/ClientService.';
export default {
    name: 'manage',
    data() {
        return {
            editable: Utility.get_permission_editable_with_key('manage_tour'),
            search_input: null,
            clients: [],
            outside_clients: [],
            historyCustomer: [],
            tour_types: [],
            tours: [],
            import_fileList: [],
            delivery_date: null, // ใช้สำหรับกรองช่วงวันที่
            business: false,
        }
    },
    computed: {
        render_type() {
            return this.$route.params.type
        }
    },
    methods: {
        async get_company() {
			const company = await UserService.get_user_profile();
            const businessType = await CompanyService.getBusinessId(company.company_id)
            if ( businessType.data === 2)
            {
                this.business = true
            }
        },
        filtered_array(array, key) {
            return array.filter(item => {
                let matchesSearchInput = true;
                let matchesDateRange = true;

                // ตรวจสอบ search_input (ค้นหาตามคีย์)
                if (this.search_input) {
                    matchesSearchInput = item[key] && item[key].toLowerCase().includes(this.search_input.toLowerCase());
                }

                // ตรวจสอบช่วงวันที่ (กรอง delivery_date)
                if (this.delivery_date && this.delivery_date.length === 2) {
                    const startDate = dayjs(this.delivery_date[0]).startOf('day'); // วันเริ่มต้น
                    const endDate = dayjs(this.delivery_date[1]).endOf('day'); // วันสิ้นสุด

                    // แปลง delivery_date ของ item ให้เป็น dayjs object
                    const itemDate = dayjs(item.delivery_date);

                    // ตรวจสอบว่า itemDate เป็นวันที่ที่ถูกต้อง และเช็คว่าตรงตามช่วงวันที่หรือไม่
                    matchesDateRange = itemDate.isValid() && itemDate.isBetween(startDate, endDate, null, '[]');
                }

                return matchesSearchInput && matchesDateRange;
            });
        },
        // ฟังก์ชันเคลียร์ค่า search_input และ delivery_date
        clearSearch() {
            this.search_input = null;
            this.delivery_date = null;
        },
        formatDate(date) {
            return dayjs(date).format('DD/MM/YYYY'); 
        },
        filter_columns(columns){
            return columns.filter((c)=> !this.editable ? c.key != 'id' : true)
        },
        import_change(event, field) {
            const file = event.file.originFileObj;

            // ตรวจสอบประเภทของไฟล์
            const validFileTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];
            if (!validFileTypes.includes(file.type)) {
                message.error({ content: 'โปรดอัปโหลดไฟล์ Excel เท่านั้น', key: 'import', duration: 1.5 });
                return;
            }

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = async () => {
                const result = xlxsBase64toJson(reader.result.split(',')[1]);
                
                if (result && event.file.status === 'done') {
                    const chunkSize = 50; 
                    for (let i = 0; i < result.length; i += chunkSize) {
                        const chunk = result.slice(i, i + chunkSize);
                        message.loading({ content: 'กำลังนำเข้าข้อมูล...', key: 'import', duration: 0 });
                        try {
                            const res = await ReportService.import_client(chunk);
                            if (res.status === 201) {
                                // console.log(res);
                                await this.init_cus_company_list()
                                const newClientCount = res.data.created_clients.length;
                                if (newClientCount > 0) {
                                    message.success({ content: `นำเข้าข้อมูล ${result.length} รายการสำเร็จ!`, key: 'import', duration: 1.5 });
                                }else{
                                    message.error({ content: 'ไม่พบข้อมูลให้นำเข้า หรือ ข้อมูลที่นำเข้ามีชื่อลูกค้า/บริษัทซ้ำ', key: 'import', duration: 2.5 });
                                }
                                if (res.data.duplicateFullnameInFile && res.data.duplicateFullnameInFile.length > 0) {
                                    const duplicateFullnameInFile = res.data.duplicateFullnameInFile.join(', ');
                                    message.warning({ content: `ตรวจพบ fullname ซ้ำในไฟล์: ${duplicateFullnameInFile}`, key: 'import', duration: 3 });
                                }
                                if (res.data.duplicateTaxIdInFile && res.data.duplicateTaxIdInFile.length > 0) {
                                    const duplicateTaxIdInFile = res.data.duplicateTaxIdInFile.join(', ');
                                    message.warning({ content: `ตรวจพบ tax_id ซ้ำในไฟล์: ${duplicateTaxIdInFile}`, key: 'import', duration: 3 });
                                }
                            } else {
                                throw new Error(res.message);
                            }
                        } catch (err) {
                            if (err.response && err.response.data && err.response.data.message) {
                                message.error({ content: err.response.data.message, key: 'import', duration: 1.5 });
                            } else {
                                message.error({ content: 'นำเข้าข้อมูลไม่สำเร็จ!', key: 'import', duration: 1.5 });
                            }
                            console.error(err);
                        }
                    }
                } else {
                    message.error({ content: 'นำเข้าข้อมูลไม่สำเร็จ!', key: 'import', duration: 1.5 });
                }
            };
            reader.onerror = () => {
                message.error({ content: 'เกิดข้อผิดพลาดในการอ่านไฟล์', key: 'import', duration: 1.5 });
            };
        },
        import_change_outside_clients(event, field) {
            const file = event.file.originFileObj;

            const validFileTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];
            if (!validFileTypes.includes(file.type)) {
                message.error({ content: 'โปรดอัปโหลดไฟล์ Excel เท่านั้น', key: 'import', duration: 1.5 });
                return;
            }

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = async () => {
                const result = xlxsBase64toJson(reader.result.split(',')[1]);

                if (result && event.file.status === 'done') {
                    const chunkSize = 50; 
                    for (let i = 0; i < result.length; i += chunkSize) {
                        const chunk = result.slice(i, i + chunkSize);

                        message.loading({ content: 'กำลังนำเข้าข้อมูล...', key: 'import', duration: 0 });
                        try {
                            const res = await ReportService.import_outside_client(chunk);

                            if (res.status === 201) {
                                await this.init_cus_outside_list();

                                const newClientCount = res.data.created_outside_clients.length;
                                if (newClientCount > 0) {
                                    message.success({ content: `นำเข้าข้อมูลสำเร็จ! จำนวนข้อมูลใหม่ที่นำเข้า: ${newClientCount}`, key: 'import', duration: 1.5 });
                                } else {
                                    message.error({ content: 'ไม่พบข้อมูลให้นำเข้า หรือ ข้อมูลที่นำเข้ามีเลขที่ใบงานซ้ำ', key: 'import', duration: 2.5 });
                                }

                                if (res.data.duplicateJobIdsInFile && res.data.duplicateJobIdsInFile.length > 0) {
                                    const duplicateIdsInFile = res.data.duplicateJobIdsInFile.join(', ');
                                    message.warning({ content: `ตรวจพบ job_id ซ้ำในไฟล์: ${duplicateIdsInFile}`, key: 'import', duration: 3 });
                                }

                            } else {
                                throw new Error(`Unexpected status: ${res.status}`);
                            }
                        } catch (err) {
                            console.error('Import Error:', err);
                            const errorMessage = err.response?.data?.message || 'นำเข้าข้อมูลไม่สำเร็จ!';
                            message.error({ content: errorMessage, key: 'import', duration: 1.5 });
                        }
                    }
                } else {
                    message.error({ content: 'นำเข้าข้อมูลไม่สำเร็จ!', key: 'import', duration: 1.5 });
                }
            };

            reader.onerror = () => {
                message.error({ content: 'เกิดข้อผิดพลาดในการอ่านไฟล์', key: 'import', duration: 1.5 });
            };
        },
        async export_file() {
            this.$message.loading({ content: "กำลังโหลด...", key: "loading" });
            const data = await ClientService.getClientAndContactAll();
            const headersColumn = [
                { header: "ชื่อบริษัท/ลูกค้า", key: "fullname" },
                { header: "สาขา", key: "branchName" },
                { header: "ที่อยู่สาขา", key: "branchAddress" },
                { header: "เบอร์โทรสาขา", key: "branchPhone" },
                // { header: "ชื่อ - นามสกุล ผู้ประสานงาน", key: "contactName" },
                // { header: "อีเมลผู้ประสานงาน", key: "contactEmail" },
                { header: "อีเมลองค์กร", key: "email" },
                { header: "รหัสลูกค้า", key: "client_no" },
                { header: "เลขประจำตัวผู้เสียภาษี", key: "tax_id" },
                { header: "เบอร์โทร", key: "phone_no" },
                { header: "เบอร์โทรสำรอง", key: "phone_backup" },
                // { header: "เบอร์แฟกซ์", key: "fax_no" },
                { header: "ที่อยู่", key: "address" },
                { header: "ข้อมูลผู้ติดต่อฝ่ายบัญชี", key: "accounting_contact" },
                { header: "อีเมลผู้ติดต่อฝ่ายบัญชี", key: "accounting_email" },
                { header: "เบอร์โทรผู้ติดต่อฝ่ายบัญชี", key: "accounting_phone" },
                { header: "เลขบัญชีองค์กร", key: "organ_accout_number" },
                { header: "ธนาคาร", key: "bank" },
                { header: "ประเภทบัญชี", key: "account_type" },
                { header: "สาขา", key: "branch" },
                { header: "หมายเหตุ", key: "remark" },
            ];
            const workBook = XLSX.utils.book_new();
            const workSheet = XLSX.utils.json_to_sheet(data, { header: headersColumn.map(h => h.key) });
            const lastRow = data.length + 1;

            XLSX.utils.sheet_add_aoa(workSheet, [headersColumn.map(h => h.header)], { origin: 'A1' });

            headersColumn.forEach((header, index) => {
            let cell = workSheet[XLSX.utils.encode_cell({ c: index, r: 0 })];
                cell.s = {
                font: { bold: true },
                fill: header.key === "client_no" 
                ? { fgColor: { rgb: "FF0000" } } // สีแดงสำหรับ client_no
                : { fgColor: { rgb: "CCC0DA" } },
                border: { bottom: { style: "thin" }, right: { style: "thin" }, left: { style: "thin" }, top: { style: "thin" } },
                alignment: { horizontal: "center" }
                };
            });

            for (let row = 1; row < lastRow; row++) {
                for (let col = 0; col < headersColumn.length; col++) {
                    const cellAddress = XLSX.utils.encode_cell({ c: col, r: row });
                    let cell = workSheet[cellAddress];
                    if (!cell) {
                        cell = { v: "" };
                        workSheet[cellAddress] = cell;
                    }
                    if (headersColumn[col].key === "fullname" || headersColumn[col].key === "branchName" || headersColumn[col].key === "contactName"|| headersColumn[col].key === "contactEmail" || headersColumn[col].key === "email"|| headersColumn[col].key === "address") {
                        cell.s = {
                        border: { bottom: { style: "thin" }, right: { style: "thin" }, left: { style: "thin" }, top: { style: "thin" } },
                        alignment: { wrapText: true, horizontal: "left", vertical: "center" }
                        };
                    }
                    else{
                        cell.s = {
                            border: { bottom: { style: "thin" }, right: { style: "thin" }, left: { style: "thin" }, top: { style: "thin" } },
                            alignment: { wrapText: true,horizontal: "center", vertical: "center" }
                        };
                    }
                }
            }


            workSheet['!cols'] = [
                    { wch: 30 },
                    { wch: 25 },
                    { wch: 30 },
                    { wch: 30 },
                    { wch: 20 },
                    { wch: 22 },
                    { wch: 16 },
                    { wch: 18 },
                    { wch: 16 },
                    { wch: 25 },
                    { wch: 25 },
                    { wch: 25 },
                    { wch: 25 },
                    { wch: 25 },
                    { wch: 25 },
                    { wch: 25 },
                    { wch: 25 },
                    { wch: 25 },
                ];

            if (!workSheet['!ref']) {
                workSheet['!ref'] = XLSX.utils.encode_range({
                s: { r: 0, c: 0 },
                e: { r: lastRow + 1, c: headersColumn.length - 1 }
                });
            } else {
                const range = XLSX.utils.decode_range(workSheet['!ref']);
                range.e.r = lastRow + 1;
                workSheet['!ref'] = XLSX.utils.encode_range(range);
            }

            XLSX.utils.book_append_sheet(workBook, workSheet, "Report");
            XLSX.writeFile(workBook, "Clients.xlsx");
            this.$message.success({ content: "โหลดข้อมูลสำเร็จ", key: "loading" });
        },
        export_file_outside_clients() {
            const exportData = this.outside_clients.map((client, index) => ({
                delivery_date: dayjs(client.delivery_date).format('DD/MM/YYYY'),
                job_id: client.job_id,
                company_name:client.company_name,
                pick_up_name: client.pick_up_name,
                delivery_name: client.delivery_name,
                truck_no: client.truck_no,
                driver_name: client.driver_name,
                truck_type: client.truck_type,
                price_trips: client.price_trips ? Number(client.price_trips) : '', // ใช้ตัวเลขจริง
                allowance: client.allowance ? Number(client.allowance) : '', // ใช้ตัวเลขจริง
                percent: client.percent ? `${(parseFloat(client.percent) * 100).toLocaleString()}%` : '',
                car_manage_fee: '',
                amount: ''
            }));

            exportData.forEach((row, index) => {
                const excelRow = index + 2;
                row.car_manage_fee = { f: `(I${excelRow} + J${excelRow}) * K${excelRow}` };
                row.amount = { f: `(I${excelRow} + J${excelRow}) - L${excelRow}` };
            });

            const headers = {
                delivery_date: 'วันที่ส่ง',
                job_id: 'เลขที่ใบงาน',
                company_name: 'บริษัทลูกค้า',
                pick_up_name: 'จุดรับ',
                delivery_name: 'จุดส่ง',
                truck_no: 'เบอร์รถ',
                driver_name: 'ชื่อนามสกุลผู้ขับ',
                truck_type: 'ชนิดรถ',
                price_trips: 'ราคา/ทริป',
                allowance: 'ค่าเบี้ยเลี้ยง',
                percent: 'เปอร์เซ็นต์',
                car_manage_fee: 'ค่าบริหารรถ',
                amount: 'ยอดเงิน'
            };

            try {
                const ws = XLSX.utils.json_to_sheet(exportData, { header: Object.keys(headers) });

                ws['!cols'] = [
                    { wch: 12 },
                    { wch: 15 },
                    { wch: 15 },
                    { wch: 30 },
                    { wch: 30 },
                    { wch: 15 },
                    { wch: 25 },
                    { wch: 20 },
                    { wch: 12 },
                    { wch: 12 },
                    { wch: 12 },
                    { wch: 15 },
                    { wch: 15 }
                ];

                const range = XLSX.utils.decode_range(ws['!ref']);
                const lastRow = range.e.r + 2;

                ws[`I${lastRow}`] = { f: `SUM(I2:I${range.e.r + 1})` }; // รวมผล price_trips
                ws[`J${lastRow}`] = { f: `SUM(J2:J${range.e.r + 1})` }; // รวมผล allowance
                ws[`L${lastRow}`] = { f: `SUM(L2:L${range.e.r + 1})` };
                ws[`M${lastRow}`] = { f: `SUM(M2:M${range.e.r + 1})` };

                for (let R = 1; R <= lastRow; R++) {
                    const priceTripsCell = `I${R}`;
                    const allowanceCell = `J${R}`;
                    const carManageFeeCell = `L${R}`;
                    const amountCell = `M${R}`;
                    
                    if (ws[priceTripsCell]) {
                        ws[priceTripsCell].s = {
                            numFmt: '#,##0.00', // Format with commas and two decimals
                            alignment: { horizontal: 'right' } // Right align
                        };
                    }
                    if (ws[allowanceCell]) {
                        ws[allowanceCell].s = {
                            numFmt: '#,##0.00', // Format with commas and two decimals
                            alignment: { horizontal: 'right' } // Right align
                        };
                    }
                    if (ws[carManageFeeCell]) {
                        ws[carManageFeeCell].s = {
                            numFmt: '#,##0.00', // Format with commas and two decimals
                            alignment: { horizontal: 'right' } // Right align
                        };
                    }
                    if (ws[amountCell]) {
                        ws[amountCell].s = {
                            numFmt: '#,##0.00', // Format with commas and two decimals
                            alignment: { horizontal: 'right' } // Right align
                        };
                    }
                }

                // Ensure borders are applied for all cells
                for (let R = 0; R <= lastRow; R++) {
                    for (let C = 0; C <= range.e.c; C++) {
                        const cellAddress = XLSX.utils.encode_cell({ r: R, c: C });
                        if (ws[cellAddress] && ws[cellAddress].v !== "") {
                            if (!ws[cellAddress].s) ws[cellAddress].s = {};
                            ws[cellAddress].s.border = {
                                top: { style: "thin", color: { rgb: "000000" } },
                                bottom: { style: "thin", color: { rgb: "000000" } },
                                left: { style: "thin", color: { rgb: "000000" } },
                                right: { style: "thin", color: { rgb: "000000" } }
                            };

                            if (C === 10) {
                                ws[cellAddress].s.alignment = { horizontal: 'center' };
                            }
                        }
                    }
                }

                Object.values(headers).forEach((value, index) => {
                    const cellAddress = XLSX.utils.encode_cell({ c: index, r: 0 });
                    const cell = ws[cellAddress];
                    cell.v = value;

                    ws[cellAddress].s = {
                        fill: { fgColor: { rgb: 'EAF5DB' } }, // Green background
                        border: {
                            top: { style: "thin", color: { rgb: "000000" } },
                            bottom: { style: "thin", color: { rgb: "000000" } },
                            left: { style: "thin", color: { rgb: "000000" } },
                            right: { style: "thin", color: { rgb: "000000" } }
                        },
                        alignment: { horizontal: "center", vertical: "center" },
                        font: { bold: true }
                    };
                });

                ws['!ref'] = XLSX.utils.encode_range({
                    s: { r: 0, c: 0 },
                    e: { r: lastRow, c: range.e.c }
                });

                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, 'OutsideClients');
                XLSX.writeFile(wb, 'OutsideClients.xlsx');
            } catch (error) {
                console.error('Error exporting file:', error);
            }
        },
        getExcelBorders() {
            return {
                top: { style: 'thin', color: { rgb: '000000' } },
                bottom: { style: 'thin', color: { rgb: '000000' } },
                left: { style: 'thin', color: { rgb: '000000' } },
                right: { style: 'thin', color: { rgb: '000000' } }
            };
        },
        import_export_dropdown(e) {
            const { key } = e
            if (key == '1') {
                
            } else if (key == '2') {
                this.export_file()
            } else if (key == '3') {
                this.export_file_outside_clients()
            } 
        },
        // filtered_array(array, key) {
        //     return array.filter(item => {
        //         if (this.search_input) {
        //             return item[key].toLowerCase().includes(this.search_input.toLowerCase())
        //         } else {
        //             return true
        //         }
        //     })
        // },
        async go_remove(id, type) {
            Swal.fire({
                title: `คุณต้องการลบข้อมูลนี้ใช่หรือไม่? [${type}ID: ${id}]`,
                text: "คุณจะไม่สามารถกู้คืนข้อมูลนี้ได้หากลบแล้ว!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'ใช่, ลบข้อมูลนี้!',
                cancelButtonText: 'ยกเลิก'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await SystemService.delete_all(type, id)
                    this.init_cus_company_list()
                    this.init_cus_outside_list()
                    this.init_tour_tour_type()
                    this.init_tours()
                    Swal.fire(
                        'ลบข้อมูลเรียบร้อย!',
                        'ข้อมูลของคุณถูกลบแล้ว',
                        'success'
                    )
                }
            })
        },
        async go_remove_outside_client(id) {
            Swal.fire({
                title: `คุณต้องการลบข้อมูลนี้ใช่หรือไม่? [OutsideClient ID: ${id}]`,
                text: "คุณจะไม่สามารถกู้คืนข้อมูลนี้ได้หากลบแล้ว!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'ใช่, ลบข้อมูลนี้!',
                cancelButtonText: 'ยกเลิก'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        // เรียก API ลบข้อมูลลูกค้าภายนอก
                        await SystemService.delete_all('OutsideClient', id);
                        
                        // หลังจากลบแล้ว ให้รีเฟรชรายการลูกค้าภายนอก
                        await this.init_cus_outside_list();

                        // แจ้งเตือนว่าลบข้อมูลเรียบร้อย
                        Swal.fire(
                            'ลบข้อมูลเรียบร้อย!',
                            'ข้อมูลของคุณถูกลบแล้ว',
                            'success'
                        );
                    } catch (error) {
                        console.error('Error deleting OutsideClient:', error);
                        Swal.fire(
                            'เกิดข้อผิดพลาด!',
                            'ไม่สามารถลบข้อมูลได้',
                            'error'
                        );
                    }
                }
            });
        },
        go_edit(model_data, modelname) {
            this.$emit('apply_model_data', model_data)
            this.$router.push(`/app/${modelname}/${model_data.id}`)
        },
        go_create(modelname) {
            this.$router.push(`/app/${modelname}/create`)
        },
        async init_cus_company_list() {
            this.clients = await SystemService.get_all('Client')
        },
        async init_cus_outside_list() {
            this.outside_clients = await SystemService.get_all('OutsideClient')
        },
        async init_tour_tour_type() {
            this.tour_types = await SystemService.get_all('TourType')
        },
        async init_tours() {
            this.tours = await SystemService.get_all('Tour')
        }
    },
    mounted() {
        this.init_cus_company_list()
        this.init_cus_outside_list()
        this.init_tour_tour_type()
        this.init_tours()
        this.get_company();

    }
}
</script>
<style>
.ant-table-thead .center-header {
  text-align: center; /* จัดตำแหน่งหัวคอลัมน์ให้อยู่ตรงกลาง */
}

.contacts-list {
    white-space: pre-wrap; 
    word-wrap: break-word; 
}

</style>