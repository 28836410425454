import httpClient from './httpClient';

const prefix = 'leaveUser'

export default {
    async getLeaveUserAll () {
        const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/getLeaveUserAll`)
        return data
    },
    async getLeaveUserByCompany () {
        const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/getLeaveUserByCompany`)
        return data
    },
    async getLeaveUserNotiByCompany () {
        const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/getLeaveUserNotiByCompany`)
        return data
    },
    async getLeaveUserByCompanyAndId (id) {
        const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/getLeaveUserByCompanyAndId/${id}`)
        return data
    },
    async isReadLeaveUserId(id){
        const { data } = await httpClient({ requiresAuth: true }).put(`${prefix}/is_read/`+id)
        return data
    }
    // async deleteLeaveUser (id) {
    //     const { data } = await httpClient({ requiresAuth: true }).delete(`${prefix}/deleteLeaveUser/${id}`)
    //     return data
    // }
}