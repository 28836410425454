<template>
  <div class="app-content">
		<!-- <a-card :title="title"> -->
			<!-- <template #extra>
				<a-button
					style="display: flex"
					@click="clear_model_data()"
					size="large"
				>
					<template #icon>
						<span class="material-symbols-outlined">arrow_back_ios</span>
					</template>
					ย้อนกลับ
				</a-button>
			</template> -->
			<a-form
				:model="model_form"
				:layout="form_layout"
				:onFinish="submit_model_data"
			>
				<a-row justify="center">
					<a-col
						v-if="default_fields[model_type] && default_fields[model_type].photos"
						:span="8"
						style="margin: 1rem"
					>
						<a-card title="รูปภาพ" style="text-align: center">
							<a-row
								justify="center"
								v-for="{
									key,
									label,
									height,
									width,
									description,
								} in default_fields[model_type].photos"
								style="margin: 1rem 0"
							>
								<div
									style="
										display: flex;
										flex-direction: column;
										height: fit-content;
										margin-bottom: 1rem;
									"
								>
									<p>{{ label }}</p>
									<a-upload v-model:file-list="fileList" list-type="picture-card"
										class="avatar-uploader" :show-upload-list="false"
										action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
										@change="upload_files($event, key)" method="GET"
                                        :before-upload="setFileType"
                                        accept="image/*"
									>
                                        <img
                                            v-if="model_form[key]" :src="model_form[key]"
                                            :style="`height: ${height}px;width: ${width}px;object-fit: cover`"
                                            alt="avatar"
                                        />
                                        <div v-else>
                                            <p class="ant-upload-drag-icon">
                                                <span
                                                    class="material-symbols-outlined"
                                                    style="font-size: 5rem"
                                                    >upload_file</span
                                                >
                                            </p>
                                            คลิกเพื่ออัพโหลดรูปภาพ
                                        </div>
									</a-upload>
									<a-button danger type="primary" @click="(model_form[key] = '')"
										v-if="model_form[key]"
									>
										<span class="material-symbols-outlined">remove</span>
										ลบ
									</a-button>
									<p style="color: gray">{{ description }}</p>
								</div>
							</a-row>
						</a-card>
					</a-col>

					<a-col :span="15" style="margin: 1rem; width: 100%">
						<a-card title="ข้อมูล">
							<a-row>
								<a-col
									style="margin: 1rem"
									:span="col_span"
									v-for="(
										{
											col_span,
											non_editable,
											input_type,
											search_select,
											modelname,
											label_key,
											label,
											placeholder,
											required,
											target_key,
											target_modelname,
											drawer_title,
											table_columns,
											drawer_fields,
											drawer_button_text,
											drawer_button_text_edit,
											parent,
											null_on_change,
											check_duplicate,
											check_rule,
											on_change,
										},
										key
									) in default_fields[model_type]?.inputs"
								>  
									<div v-if="input_type == 'label'">{{ label }}</div>
									<a-form-item
										v-else
										:name="key"
										:label="placeholder"
										:has-feedback="check_duplicate"
										:rules="
											check_duplicate
												? check_rule
												: non_editable
												? null
												: [{ required, message: `โปรดระบุ ${placeholder}` }]
										"
									>
										<a-select
											v-if="input_type == 'select' && !search_select"
                      placeholder="เลือกแผนก"
											v-model:value="model_form[key]"
											@change="handleChange(null_on_change, on_change)"
                      :disabled="action_type == 'create' ? false : non_editable"
										>
											<a-select-option
												v-for="({ text, value }, i) in departments[model_type]"
                        :key="i"
												:value="value"
											>
                        {{ text }}
											</a-select-option>
										</a-select>
										<a-select
											v-else-if="
												input_type == 'select' &&
												search_select &&
												selectables[modelname]
											"
											v-model:value="model_form[key]"
											show-search
											:placeholder="placeholder"
											:options="
												selectables[modelname].map((e) => ({
													value: e.id,
													label: e[label_key],
												}))
											"
											:filter-option="filterOption"
											@change="handleChange(null_on_change)"
										></a-select>
										<a-select
											v-else-if="input_type == 'sub_select'"
											v-model:value="model_form[key]"
											:disabled="!model_form[parent.key]"
										>
											<a-select-option
												v-for="option in show_sub_selectables(parent)"
												:value="option.id"
												selected
											>
												{{ option[label_key] }}
											</a-select-option>
										</a-select>
										<a-checkbox-group
											v-else-if="input_type == 'checkbox'"
											v-model:value="model_form[key]"
											:options="
												selectables[modelname]?.map((option) => {
													return { label: option[label_key], value: option.id };
												})
											"
										/>
										<a-divider
											v-else-if="input_type == 'divider'"
											orientation="left"
											>{{ label }}</a-divider
										>
										<a-divider
											v-else-if="input_type == 'sub-divider'"
											orientation="left"
										>
											<h5>{{ label }}</h5>
										</a-divider>
										<TableCreateDrawer
											v-else-if="input_type == 'table'"
											:divider="drawer_title"
											:table_columns="table_columns"
											:target_object="model_form"
											:target_key="target_key"
											:drawer_title="drawer_title"
											:drawer_fields="drawer_fields.inputs"
											:drawer_button_text="drawer_button_text"
											:drawer_button_text_edit="drawer_button_text_edit"
											:target_modelname="target_modelname"
										/>
										<a-date-picker
											v-else-if="input_type == 'date'"
											v-model:value="model_form[key]"
											:placeholder="placeholder"
											:disabled="action_type == 'create' ? false : non_editable"
										/>
										<a-date-picker
											v-else-if="input_type == 'datetime'"
											v-model:value="model_form[key]"
											:placeholder="placeholder"
											:show-time="{ format: 'HH:mm' }"
											format="DD/MM/YYYY HH:mm"
											:disabled="action_type == 'create' ? false : non_editable"
										/>
										<a-date-picker
											v-else-if="input_type == 'month'"
											v-model:value="model_form[key]"
											:placeholder="placeholder"
											picker="month"
											format="MMMM"
											:disabled="action_type == 'create' ? false : non_editable"
										/>
										<a-switch
											v-else-if="input_type == 'switch'"
											v-model:checked="model_form[key]"
											:placeholder="placeholder"
											:disabled="action_type == 'create' ? false : non_editable"
										/>
										<a-textarea
											v-else-if="input_type == 'textarea'"
											v-model:value="model_form[key]"
											:placeholder="placeholder"
											:disabled="action_type == 'create' ? false : non_editable"
										/>
										<div v-else-if="input_type == 'photos'" class="clearfix">
											<a-upload
												v-model:file-list="model_form[target_key]"
												action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
												@change="upload_files($event, key)"
												method="GET"
												@remove="remove_uploaded_file($event)"
												list-type="picture-card"
										    >
												<div
													v-if="model_form[target_key]?.length < 5"
													style="height: 104px; width: 104px; padding: 2rem"
												>
													<span
														style="font-size: 20px"
														class="material-symbols-outlined"
														>add</span
													>
													<div style="margin-top: 8px">Upload</div>
												</div>
											</a-upload>
										</div>
										<div
											v-else-if="input_type == 'photo'"
											style="
												display: flex;
												flex-direction: column;
												align-items: center;
												gap: 1rem;
											"
										>
											<div>{{ label }}</div>
											<a-upload
												v-model:file-list="fileList"
												:show-upload-list="false"
												action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
												@change="upload_files($event, key)"
												method="GET"
												disabled="true"
											>
												<img
													v-if="model_form[key]"
													:src="model_form[key]"
													style="width: 100%; height: 100px"
													alt="avatar"
												/>
												<div
													v-else
													style="
														display: flex;
														flex-direction: column;
														align-items: center;
													"
												>
													<p class="ant-upload-drag-icon">
														<span
															class="material-symbols-outlined"
															style="font-size: 5rem"
															>upload_file</span
														>
													</p>
													<!-- คลิกเพื่ออัพโหลดรูปภาพ -->
												</div>
											</a-upload>
										</div>
										<!-- upload_file -->
										<div
											v-else-if="input_type == 'file'"
											style="
												display: flex;
												flex-direction: row;
												align-items: center;
											"
										>
											<a-upload
												v-model:file-list="fileList"
												v-if="!model_form[key]"
												action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
												method="GET"
												:show-upload-list="false"
												@change="upload_files($event, key)"
											>
												<a-row>
													<span class="material-symbols-outlined">upload</span>
													อัพโหลดไฟล์
												</a-row>
											</a-upload>
											<a
												:href="model_form[key]"
												v-else="model_form[key]"
												target="_blank"
												rel="doc"
											>
												{{ model_form[`${key}_file_name`] }}
											</a>
											<a-button
												danger
												type="text"
												@click="model_form[key] = ''"
												style="padding: 0; height: 18px"
												v-if="model_form[key]"
											>
												<span class="material-symbols-outlined">close</span>
											</a-button>
										</div>
										<div v-else-if="input_type == 'table_files'">
											<a-table
												:columns="[
													{
														title: 'ชื่อเอกสาร',
														dataIndex: 'name',
														key: 'name',
														width: 400,
													},
													{
														title: 'ไฟล์เอกสาร',
														dataIndex: 'file',
														key: 'file',
													},
													{
														title: 'ลบ',
														dataIndex: 'id',
														key: 'id',
														width: 100,
														align: 'center',
													},
												]"
												:dataSource="data_raw_files"
												:pagination="false"
												:scroll="{ y: 240 }"
											>
												<template #bodyCell="{ column, record, index }">
													<template v-if="column.key === 'name'">
														<a-form-item>
															<a-input v-model:value="record.name" />
														</a-form-item>
													</template>
													<template v-if="column.key === 'file'">
														<a-form-item>
															<a
																:href="record.file"
																target="_blank"
																rel="doc"
																>{{ record.file_name }}</a
															>
														</a-form-item>
													</template>
													<template v-if="column.key === 'id'">
														<a-form-item>
															<a-button
																danger
																type="text"
																@click="data_raw_files.splice(index, 1)"
															>
																<span class="material-symbols-outlined"
																	>delete</span
																>
															</a-button>
														</a-form-item>
													</template>
												</template>
											</a-table>
											<a-upload
												v-model:file-list="model_form[key]"
												action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
												method="GET"
												@change="upload_file_multiple($event, key)"
												:show-upload-list="false"
											>
												<a-button style="margin-top: 10px">
													<span class="material-symbols-outlined">add</span>
													เพิ่มไฟล์
												</a-button>
											</a-upload>
										</div>
										<div v-else>
                                            <div v-if="this.model == 'Sale'">
												<a-input
													v-model:value="model_form[key]"
													:placeholder="key == 'cotton' ? 'พนักงานขาย' : placeholder"
													:type="input_type"
													:disabled=" action_type == 'create' ? key == 'cotton' ? non_editable : false : non_editable"
												/>
											</div>
											<div v-else-if="this.model == 'TM'">
												<a-input
													v-model:value="model_form[key]"
													:placeholder="key == 'cotton' ? 'ฝ่ายจัดการรถเช่า' : placeholder"
													:type="input_type"
													:disabled=" action_type == 'create' ? key == 'cotton' ? non_editable : false : non_editable"
												/>
											</div>
											<div v-else-if="this.model == 'TSM'">
												<a-input
													v-model:value="model_form[key]"
													:placeholder="key == 'cotton' ? 'ฝ่ายซ่อมบำรุงและอุบัติเหตุ' : placeholder"
													:type="input_type"
													:disabled=" action_type == 'create' ? key == 'cotton' ? non_editable : false : non_editable"
												/>
											</div>	
											<div v-else-if="this.model == 'Administrative'">
												<a-input
													v-model:value="model_form[key]"
													:placeholder="key == 'cotton' ? 'ฝ่ายบริหาร' : placeholder"
													:type="input_type"
													:disabled="key == 'cotton' || key == 'department' ? true : false"
												/>
											</div>
											<div v-else-if="this.model == 'Accounting'">
												<a-input
													v-model:value="model_form[key]"
													:placeholder="key == 'cotton' ? 'ฝ่ายข้อมูลและการเงิน' : placeholder"
													:type="input_type"
													:disabled="key == 'cotton' ? true : false"
												/>
											</div>
											<div v-else-if="this.model == 'Operation'">
												<a-input
													v-model:value="model_form[key]"
													:placeholder="key == 'cotton' ? 'ฝ่าบปฏิบัติการ' : placeholder"
													:type="input_type"
													:disabled="key == 'cotton' ? true : false"
												/>
											</div>	
										</div>

									</a-form-item>
								</a-col>
							</a-row>

							<a-row justify="end" >
								<a-form-item>
									<a-button type="primary" size="large" html-type="submit">
										<template #icon>
											<span
												style="font-size: 20px"
												class="material-symbols-outlined"
												>save</span
											>
										</template>
										บันทึกข้อมูล
									</a-button>
								</a-form-item>
							</a-row>
						</a-card>
					</a-col>
				</a-row>
			</a-form>
		<!-- </a-card> -->
	</div>
</template>

<script>
import Swal from "sweetalert2";
import SystemService from "@/api/SystemService";
import TableCreateDrawer from "@/components/table_create_drawer.vue";
import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
dayjs.extend(buddhistEra);
import CarField from "@/field_config/car_fields";
import th from "dayjs/locale/th";
import SaleService from "@/api/SaleService";
import TMService from "@/api/TMService";
import TSMService from "@/api/TSMService";
import AdministrativeService from "@/api/AdministrativeService";
import AccountingService from "@/api/AccountingService";
import OperationService from "@/api/OperationService";

export default {
  components: { TableCreateDrawer },
  props: {
      create: Boolean,
      model: String,
      model_data: Object,
  },
  name: "CreateEditComponent",
  computed: {
      model_type() {
          return this.model;
      },
      action_type() {
          return this.create ? 'create' : 'edit';
      },
      title() {
          return this.default_fields[this.model].title[this.create ? 'create' : 'edit'];
      },
      form_layout() {
          const model_horizontal_form = ["Car"];
          return model_horizontal_form.includes(this.model_type) ? "horizontal" : "vertical";
      },
  },
  data() {
      return {
          isLoading: false,
          company_id: JSON.parse(localStorage.getItem("companyData")).id,
          fileList: [],
          data_raw_files: [],
          default_rules: [],
          default_fields: {
            Sale: {
                  title: {
                      create: "เพิ่มข้อมูลพนักงานขาย",
                      edit: "แก้ไขข้อมูลพนักงานขาย",
                  },
                  photos: [
                      {
                          key: "avatar",
                          label: "ภาพประจำตัว",
                          height: 275,
                          width: 275,
                      },
                      {
                          key: "signature",
                          label: "ลายเซ็นพนักงานขาย",
                          height: 60,
                          width: 300,
                          description: "ขนาด 400 x 60 px",
                      },
                  ],
                  inputs: {
                      username: {
                          input_type: "text",
                          placeholder: "ชื่อผู้ใช้",
                          check_duplicate: true,
                          check_rule: [
                              {
                                  required: true,
                                  validator: this.validate_duplicate,
                                  trigger: "change",
                              },
                          ],
                          required: true,
                          non_editable: true,
                          col_span: 11,
                      },
                      // password: {
                      //     input_type: "password",
                      //     placeholder: "รหัสผ่าน",
                      //     required: true,
                      //     non_editable: true,
                      //     col_span: 11,
                      // },
                      email: {
                          input_type: "email",
                          placeholder: "อีเมล",
                          required: true,
                          col_span: 11,
                      },
                      firstname: {
                          input_type: "text",
                          placeholder: "ชื่อ",
                          required: true,
                          col_span: 11,
                      },
                      lastname: {
                          input_type: "text",
                          placeholder: "นามสกุล",
                          required: true,
                          col_span: 11,
                      },
                      cotton: {
                          input_type: "text",
                          placeholder: "ฝ่าย",
                          required: false,
                          col_span: 11,
                          non_editable: true,                   
                      },
                      department: {
                          input_type: "select",
                          modelname: "DrivingLicenseType",
                          label_key: "name",
                          placeholder: "แผนก",
                          required: true,
                          col_span: 11,
                      },
                      nickname: {
                          input_type: "text",
                          placeholder: "ชื่อเล่น",
                          required: true,
                          col_span: 11,
                      },
                      phone_no: {
                          input_type: "text",
                          placeholder: "เบอร์โทร",
                          required: true,
                          col_span: 11,
                      },
                      phone_backup: {
                          input_type: "text",
                          placeholder: "เบอร์โทรสำรอง",
                          required: false,
                          col_span: 11,
                      },
                      commission: {
                          input_type: "number",
                          placeholder: "คอมมิชชั่น",
                          required: true,
                          col_span: 11,
                      },
                  },
              },
              TM: {
                title: {
                  create: "เพิ่มข้อมูลฝ่ายจัดการรถเช่า",
                  edit: "แก้ไขข้อมูลฝ่ายจัดการรถเช่า",
                },
                photos: [
                      {
                          key: "avatar",
                          label: "ภาพประจำตัว",
                          height: 275,
                          width: 275,
                      },
                      {
                          key: "signature",
                          label: "ลายเซ็น",
                          height: 60,
                          width: 300,
                          description: "ขนาด 400 x 60 px",
                      },
                ],
                inputs: {
                      username: {
                          input_type: "text",
                          placeholder: "ชื่อผู้ใช้",
                          check_duplicate: true,
                          check_rule: [
                              {
                                  required: true,
                                  validator: this.validate_duplicate,
                                  trigger: "change",
                              },
                          ],
                          required: true,
                          non_editable: true,
                          col_span: 11,
                      },
                      email: {
                          input_type: "email",
                          placeholder: "อีเมล",
                          required: true,
                          col_span: 11,
                      },
                      firstname: {
                          input_type: "text",
                          placeholder: "ชื่อ",
                          required: true,
                          col_span: 11,
                      },
                      lastname: {
                          input_type: "text",
                          placeholder: "นามสกุล",
                          required: true,
                          col_span: 11,
                      },
                      cotton: {
                          input_type: "text",
                          placeholder: "ฝ่าย",
                          required: false,
                          col_span: 11,
                          non_editable: true,                   
                      },
                      department: {
                          input_type: "select",
                          modelname: "DrivingLicenseType",
                          label_key: "name",
                          placeholder: "แผนก",
                          required: true,
                          col_span: 11,
                      },
                      nickname: {
                          input_type: "text",
                          placeholder: "ชื่อเล่น",
                          required: true,
                          col_span: 11,
                      },
                      phone_no: {
                          input_type: "text",
                          placeholder: "เบอร์โทร",
                          required: true,
                          col_span: 11,
                      },
                      phone_backup: {
                          input_type: "text",
                          placeholder: "เบอร์โทรสำรอง",
                          required: false,
                          col_span: 11,
                      },
                  },
              },
              TSM: {
                title: {
                  create: "เพิ่มข้อมูลฝ่ายซ่อมบำรุงและอุบัติเหตุ",
                  edit: "แก้ไขข้อมูลฝ่ายซ่อมบำรุงและอุบัติเหตุ",
                },
                photos: [
                      {
                          key: "avatar",
                          label: "ภาพประจำตัว",
                          height: 275,
                          width: 275,
                      },
                      {
                          key: "signature",
                          label: "ลายเซ็น",
                          height: 60,
                          width: 300,
                          description: "ขนาด 400 x 60 px",
                      },
                ],
                inputs: {
                      username: {
                          input_type: "text",
                          placeholder: "ชื่อผู้ใช้",
                          check_duplicate: true,
                          check_rule: [
                              {
                                  required: true,
                                  validator: this.validate_duplicate,
                                  trigger: "change",
                              },
                          ],
                          required: true,
                          non_editable: true,
                          col_span: 11,
                      },
                      email: {
                          input_type: "email",
                          placeholder: "อีเมล",
                          required: true,
                          col_span: 11,
                      },
                      firstname: {
                          input_type: "text",
                          placeholder: "ชื่อ",
                          required: true,
                          col_span: 11,
                      },
                      lastname: {
                          input_type: "text",
                          placeholder: "นามสกุล",
                          required: true,
                          col_span: 11,
                      },
                      cotton: {
                          input_type: "text",
                          placeholder: "ฝ่าย",
                          required: false,
                          col_span: 11,
                          non_editable: true,                   
                      },
                      department: {
                          input_type: "select",
                          modelname: "DrivingLicenseType",
                          label_key: "name",
                          placeholder: "แผนก",
                          required: true,
                          col_span: 11,
                      },
                      nickname: {
                          input_type: "text",
                          placeholder: "ชื่อเล่น",
                          required: true,
                          col_span: 11,
                      },
                      phone_no: {
                          input_type: "text",
                          placeholder: "เบอร์โทร",
                          required: true,
                          col_span: 11,
                      },
                      phone_backup: {
                          input_type: "text",
                          placeholder: "เบอร์โทรสำรอง",
                          required: false,
                          col_span: 11,
                      },
                  },
            },
            Administrative: {
                  title: {
                      create: "เพิ่มข้อมูลฝ่ายบริหาร",
                      edit: "แก้ไขข้อมูลฝ่ายบริหาร",
                  },
                  photos: [
                      {
                          key: "avatar",
                          label: "ภาพประจำตัว",
                          height: 275,
                          width: 275,
                      },
                      {
                          key: "signature",
                          label: "ลายเซ็นฝ่ายบริหาร",
                          height: 60,
                          width: 300,
                          description: "ขนาด 400 x 60 px",
                      },
                  ],
                  inputs: {
                    cotton: {
                          input_type: "text",
                          placeholder: "ฝ่าย",
                          required: false,
                          col_span: 11,                  
                      },
                      department: {
                          input_type: "text",
                          placeholder: "แผนก",
                          required: false,
                          col_span: 11,
                      },
                      position: {
                          input_type: "text",
                          modelname: "DrivingLicenseType",
                          label_key: "name",
                          placeholder: "ตำแหน่ง",
                          required: false,
                          col_span: 11,
                      },
                      username: {
                          input_type: "text",
                          placeholder: "ชื่อผู้ใช้",
                          check_duplicate: true,
                          check_rule: [
                              {
                                  required: true,
                                  validator: this.validate_duplicate,
                                  trigger: "change",
                              },
                          ],
                          required: true,
                          non_editable: true,
                          col_span: 11,
                      },
                      email: {
                          input_type: "email",
                          placeholder: "อีเมล",
                          required: true,
                          col_span: 11,
                      },
                      fullname: {
                          input_type: "text",
                          placeholder: "ชื่อ-นามสกุล",
                          required: true,
                          col_span: 11,
                      },
                      id_no: {
                          input_type: "text",
                          placeholder: "เลขบัตรประชาชน",
                          required: false,
                          col_span: 11,
                      },
                      employee_no: {
                          input_type: "text",
                          placeholder: "เลขบัตรประจำตัวพนักงาน",
                          required: false,
                          col_span: 11,
                      },
                      nickname: {
                          input_type: "text",
                          placeholder: "ชื่อเล่น",
                          required: true,
                          col_span: 11,
                      },
                      phone_no: {
                          input_type: "text",
                          placeholder: "เบอร์โทร",
                          required: true,
                          col_span: 11,
                      },
                      phone_backup: {
                          input_type: "text",
                          placeholder: "เบอร์โทรสำรอง",
                          required: false,
                          col_span: 11,
                      },
                      salaly: {
                          input_type: "number",
                          placeholder: "เงินเดือน",
                          required: false,
                          col_span: 11,
                      },
                  },
              },
              Accounting: {
                  title: {
                      create: "เพิ่มข้อมูลฝ่ายการเงินและบัญชี",
                      edit: "แก้ไขข้อมูลฝ่ายการเงินและบัญชี",
                  },
                  photos: [
                      {
                          key: "avatar",
                          label: "ภาพประจำตัว",
                          height: 275,
                          width: 275,
                      },
                      {
                          key: "signature",
                          label: "ลายเซ็นฝ่ายข้อมูลและการเงิน",
                          height: 60,
                          width: 300,
                          description: "ขนาด 400 x 60 px",
                      },
                  ],
                  inputs: {
                    cotton: {
                          input_type: "text",
                          placeholder: "ฝ่าย",
                          required: false,
                          col_span: 11,
                          non_editable: true,                   
                      },
                      department: {
                          input_type: "select",
                          modelname: "DrivingLicenseType",
                          label_key: "name",
                          placeholder: "แผนก",
                          required: true,
                          col_span: 11,
                      },
                      position: {
                          input_type: "text",
                          modelname: "DrivingLicenseType",
                          label_key: "name",
                          placeholder: "ตำแหน่ง",
                          required: false,
                          col_span: 11,
                      },
                      username: {
                          input_type: "text",
                          placeholder: "ชื่อผู้ใช้",
                          check_duplicate: true,
                          check_rule: [
                              {
                                  required: true,
                                  validator: this.validate_duplicate,
                                  trigger: "change",
                              },
                          ],
                          required: true,
                          non_editable: true,
                          col_span: 11,
                      },
                      email: {
                          input_type: "email",
                          placeholder: "อีเมล",
                          required: true,
                          col_span: 11,
                      },
                      fullname: {
                          input_type: "text",
                          placeholder: "ชื่อ-นามสกุล",
                          required: true,
                          col_span: 11,
                      },
                      id_no: {
                          input_type: "text",
                          placeholder: "เลขบัตรประชาชน",
                          required: false,
                          col_span: 11,
                      },
                      employee_no: {
                          input_type: "text",
                          placeholder: "เลขบัตรประจำตัวพนักงาน",
                          required: false,
                          col_span: 11,
                      },
                      nickname: {
                          input_type: "text",
                          placeholder: "ชื่อเล่น",
                          required: true,
                          col_span: 11,
                      },
                      phone_no: {
                          input_type: "text",
                          placeholder: "เบอร์โทร",
                          required: true,
                          col_span: 11,
                      },
                      phone_backup: {
                          input_type: "text",
                          placeholder: "เบอร์โทรสำรอง",
                          required: false,
                          col_span: 11,
                      },
                      salaly: {
                          input_type: "number",
                          placeholder: "เงินเดือน",
                          required: false,
                          col_span: 11,
                      },
                  },
              },
              Operation: {
                  title: {
                      create: "เพิ่มข้อมูลฝ่ายปฏิบัติการ",
                      edit: "แก้ไขข้อมูลฝ่ายปฏิบัติการ",
                  },
                  photos: [
                      {
                          key: "avatar",
                          label: "ภาพประจำตัว",
                          height: 275,
                          width: 275,
                      },
                      {
                          key: "signature",
                          label: "ลายเซ็นฝ่ายปฏิบัติการ",
                          height: 60,
                          width: 300,
                          description: "ขนาด 400 x 60 px",
                      },
                  ],
                  inputs: {
                    cotton: {
                          input_type: "text",
                          placeholder: "ฝ่าย",
                          required: false,
                          col_span: 11,
                          non_editable: true,                   
                      },
                      department: {
                          input_type: "select",
                          modelname: "DrivingLicenseType",
                          label_key: "name",
                          placeholder: "แผนก",
                          required: true,
                          col_span: 11,
                      },
                      position: {
                          input_type: "text",
                          modelname: "DrivingLicenseType",
                          label_key: "name",
                          placeholder: "ตำแหน่ง",
                          required: false,
                          col_span: 11,
                      },
                      username: {
                          input_type: "text",
                          placeholder: "ชื่อผู้ใช้",
                          check_duplicate: true,
                          check_rule: [
                              {
                                  required: true,
                                  validator: this.validate_duplicate,
                                  trigger: "change",
                              },
                          ],
                          required: true,
                          non_editable: true,
                          col_span: 11,
                      },
                      email: {
                          input_type: "email",
                          placeholder: "อีเมล",
                          required: true,
                          col_span: 11,
                      },
                      fullname: {
                          input_type: "text",
                          placeholder: "ชื่อ-นามสกุล",
                          required: true,
                          col_span: 11,
                      },
                      id_no: {
                          input_type: "text",
                          placeholder: "เลขบัตรประชาชน",
                          required: false,
                          col_span: 11,
                      },
                      employee_no: {
                          input_type: "text",
                          placeholder: "เลขบัตรประจำตัวพนักงาน",
                          required: false,
                          col_span: 11,
                      },
                      nickname: {
                          input_type: "text",
                          placeholder: "ชื่อเล่น",
                          required: true,
                          col_span: 11,
                      },
                      phone_no: {
                          input_type: "text",
                          placeholder: "เบอร์โทร",
                          required: true,
                          col_span: 11,
                      },
                      phone_backup: {
                          input_type: "text",
                          placeholder: "เบอร์โทรสำรอง",
                          required: false,
                          col_span: 11,
                      },
                      salaly: {
                          input_type: "number",
                          placeholder: "เงินเดือน",
                          required: false,
                          col_span: 11,
                      },
                  },
              },
          },
          info_data: {
            department: null
          },
          departments: {},
          selectables: {},
          model_form: {},
          check_keys_to_modelname: {
              driver_no: "Driver",
              username: "ClientContact",
              plate_no: "Car",
          },
          delayed: null,
      };
  },
  methods: {
    async client_no_on_change() {
            this.model_form.client_no = this.model_form.client_no || await SystemService.get_client_no();
        },
      async validate_duplicate(_rule, value) {
          return new Promise((resolve, reject) => {
              if (_rule.required && !value) {
                  reject("โปรดระบุ");
              }
              setTimeout(() => {
                  this.create ? SystemService.check_duplicate(
                      this.model_type,
                      // this.check_keys_to_modelname[_rule.field],
                      `field=${_rule.field}&value=${value}&id=${this.action_type}`
                  ).then((res) => {
                      if (res) {
                          reject("มีข้อมูลนี้อยู่แล้ว");
                      } else {
                          resolve();
                      }
                  }) : resolve();
              }, 0);
          });
      },
      setFileType(file) {
            const fileType = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/gif' 
            || file.type === 'image/raw' || file.type === 'image/webp' || file.type === 'image/tif' || file.type === 'image/psd' || file.type === 'image/svg';
            if (!fileType) {
                this.$message.error('คุณสามารถอัปโหลดได้เฉพาะไฟล์รูปภาพเท่านั้น!');
            }
            return fileType
      },
      handleChange(value, on_change) {
          if (on_change) on_change();
          if (value) {
              this.model_form[value] = null;
          } else {
              return;
          }
      },
      filterOption(input, option) {
          return option.label.toUpperCase().indexOf(input.toUpperCase()) >= 0;
      },
      async remove_uploaded_file({ uid }) {
          this.model_form[uid] = "";
      },
      show_sub_selectables(parent) {
          try {
              return this.selectables[parent.list].find(
                  ({ id }) => id == this.model_form[parent.key]
              )[parent.sub_list_name];
          } catch (er) {
              return [];
          }
      },
      async go_remove(id, type) {
          Swal.fire({
              title: `คุณต้องการลบข้อมูลนี้ใช่หรือไม่? [${type}ID: ${id}]`,
              text: "คุณจะไม่สามารถกู้คืนข้อมูลนี้ได้หากลบแล้ว!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "ใช่, ลบข้อมูลนี้!",
              cancelButtonText: "ยกเลิก",
          }).then(async (result) => {
              if (result.isConfirmed) {
                  await SystemService.delete_all(type, id);
                  Swal.fire("ลบข้อมูลเรียบร้อย!", "ข้อมูลของคุณถูกลบแล้ว", "success");
              }
          });
      },
      upload_files(e, key) {
          if (e.file.status != "remove") {
            this.getBase64(e.file.originFileObj, key);
            if (e.file.status == 'uploading' && !this.isLoading) {
                this.$message.loading('กำลังอัปโหลดรูปภาพ...');
                this.isLoading = true;
            }
            if (e.file.status == 'error') {
                this.$message.success('อัปโหลดรูปภาพสำเร็จ')
                this.isLoading = false;
            }
            return `https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6`;
          }
      },
      async upload_file_multiple(e, key) {
          if (e.file.status != "remove") {
              var file = e.file.originFileObj;
              if (key) {
                  const vue = this;
                  var reader = new FileReader();
                  reader.readAsDataURL(file);
                  reader.onload = function () {
                      if (vue.data_raw_files.map((e) => e.file_name).includes(file.name))
                          return;
                      vue.data_raw_files.push({
                          file: reader.result,
                          file_name: file.name,
                          name: "",
                      });
                  };
              } else {
                  return await new Promise((resolve, reject) => {
                      if (file) {
                          if (typeof file == "string") {
                              resolve(file);
                          } else {
                              const reader = new FileReader();
                              reader.readAsDataURL(file);
                              reader.onload = () => resolve(reader.result);
                              reader.onerror = (error) => reject(error);
                          }
                      }
                  });
              }
              return `https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6`;
          }
      },
      async getBase64(file, key) {
          if (key) {
              const vue = this;
              var reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = function () {
                  vue.model_form[key] = reader.result;
                  vue.model_form[`${key}_file_name`] = file.name;
              };
          } else {
              return await new Promise((resolve, reject) => {
                  if (file) {
                      if (typeof file == "string") {
                          resolve(file);
                      } else {
                          const reader = new FileReader();
                          reader.readAsDataURL(file);
                          reader.onload = () => resolve(reader.result);
                          reader.onerror = (error) => reject(error);
                      }
                  }
              });
          }
      },
      async validate_model_form() {
          try {
              if (this.data_raw_files && this.data_raw_files.length) {
                  this.model_form.data_raw_files = this.data_raw_files;
              }

              if (this.model_form.create_options) {
                  this.model_form.car_options = this.model_form.create_options.map(
                      (id) => {
                          return {
                              option_id: id,
                          };
                      }
                  );
                  delete this.model_form.create_options;
              }

              if (this.model_form.photos) {
                  for (var i = 0; i < this.model_form.photos.length; i++) {
                      this.model_form[`photo_${i + 1}`] = await this.getBase64(
                          this.model_form.photos[i].originFileObj
                      );
                  }
                  delete this.model_form.photos;
                  delete this.model_form.create_photos;
              }
              this.model_form.company_id = this.company_id;
          } catch (err) {
              console.log(err);
          }
      },
      async submit_model_data() {
        const type = this.create ? "create_all" : "update_all";
          Swal.fire({
              title: "กรุณารอสักครู่",
              text: "กำลังบันทึกข้อมูล",
              allowOutsideClick: false,
              didOpen: async () => {
                  Swal.showLoading();
                  this.validate_model_form();
                  if (this.model_type == "Sale") {
										type == "create_all" 
										? 
												await SaleService.createSale({ data: [this.model_form] })
												.then((res) => {
													if (res) {
															Swal.close();
													}
												})
										:
												await SaleService.updateSale({ data: [this.model_form] }, this.model_form.id)
												.then((res) => {
													if (res) {
															Swal.close();
													}
												})
									} else if (this.model_type == "TM") {
										type == "create_all" 
										? 
												await TMService.createTM({ data: [this.model_form] })
												.then((res) => {
													if (res) {
															Swal.close();
													}
												})
										:
												await TMService.updateTM({ data: [this.model_form] }, this.model_form.id)
												.then((res) => {
													if (res) {
															Swal.close();
													}
												})
									} else if (this.model_type == "TSM") {
										type == "create_all" 
										? 
												await TSMService.createTSM({ data: [this.model_form] })
												.then((res) => {
													if (res) {
															Swal.close();
													}
												})
										: 
												await TSMService.updateTSM({ data: [this.model_form] }, this.model_form.id)
												.then((res) => {
													if (res) {
															Swal.close();
													}
												})
									} else if (this.model_type == "Administrative") {
										type == "create_all" 
										? 
												await AdministrativeService.createAdministrative({ data: [this.model_form] })
												.then((res) => {
													if (res) {
															Swal.close();
													}
												})
										:
												await AdministrativeService.updateAdministrative({ data: [this.model_form] }, this.model_form.id)
												.then((res) => {
													if (res) {
															Swal.close();
													}
												})
									} else if (this.model_type == "Accounting") {
										type == "create_all" 
										? 
												await AccountingService.createAccounting({ data: [this.model_form] })
												.then((res) => {
													if (res) {
															Swal.close();
													}
												})
										:
												await AccountingService.updateAccounting({ data: [this.model_form] }, this.model_form.id)
												.then((res) => {
													if (res) {
															Swal.close();
													}
												})
									} else if (this.model_type == "Operation") {
										type == "create_all" 
										? 
												await OperationService.createOperation({ data: [this.model_form] })
												.then((res) => {
													if (res) {
															Swal.close();
													}
												})
										: 
												await OperationService.updateOperation({ data: [this.model_form] }, this.model_form.id)
												.then((res) => {
													if (res) {
															Swal.close();
													}
												})
									}
              },
              willClose: () => {
                  Swal.fire({
                      title: "บันทึกข้อมูลสำเร็จ",
                      icon: "success",
                      timer: 1000,
                      timerProgressBar: false,
                      showConfirmButton: false,
                  });
                  this.clear_model_data();
              },
          });
      },
      async init_create_edit() {
          if (!this.create) {
              var data = await SystemService.get_id(
                  this.model_type,
                  this.model_data.id
              );
              this.model_form = this.parse_update_data(data);
          } else {
              this.model_form = this.model_data;
          }
      },
      clear_model_data() {
          this.$emit("apply_model_data", {});
          this.$router.go(-1)
      },
      async get_default_fields() {
          this.init_data();
          if (!this.default_fields[this.model_type]) {
                console.error(`Model type ${this.model_type} is not defined in default_fields`);
                return;
          }
          for (var key in this.default_fields[this.model_type].inputs) {
              const { input_type, modelname, target_key } =
                  this.default_fields[this.model_type].inputs[key];
              if ((input_type == "select" || input_type == "checkbox") && modelname) {
                  SystemService.get_all(modelname).then((res) => {
                      this.selectables[modelname] = res;
                  });
              }
              if (input_type == "photos") {
                  if (!this.model_form[target_key]) {
                      this.model_form[target_key] = [];
                  }
              }
              if (key == 'client_no') {
                    this.model_form[key] = this.model_form.client_no || await SystemService.get_client_no();
                }
          }
      },
      clear_drawer_data(key) {
          this.default_fields[this.model_type].inputs[key].create.show = false;
      },
      parse_update_data(data) {
          try {
              for (var key in data) {
                  if (!data[key]) continue;
                  if (isNaN(data[key]) == false) {
                      data[key] = data[key];
                  } else if (
                      dayjs(data[key]).isValid() &&
                      key != "car_no" &&
                      key != "fullname" &&
                      key != "remark"
                  ) {
                      data[key] = dayjs(data[key]);
                  }
              }

              const array_fields = ["options"];
              for (var i = 0; i < array_fields.length; i++) {
                  const key = array_fields[i];
                  if (key in data) {
                      data[`create_${key}`] = data[key].map((item) => {
                          return item.id;
                      });
                  }
              }

              const photos_fields = [
                  "photo_1",
                  "photo_2",
                  "photo_3",
                  "photo_4",
                  "photo_5",
                  "photo_6",
                  "photo_7",
                  "photo_8",
              ];
              for (var i = 0; i < photos_fields.length; i++) {
                  const key = photos_fields[i];
                  if (key in data) {
                      if (data[key] != null) {
                          if (data.photos) {
                              data.photos.push({
                                  uid: key,
                                  name: data[key].split("/").pop(),
                                  status: "done",
                                  url: data[key],
                              });
                          } else {
                              data.photos = [
                                  {
                                      uid: key,
                                      name: data[key].split("/").pop(),
                                      status: "done",
                                      url: data[key],
                                  },
                              ];
                          }
                      }
                  }
              }

              const boolean_fields = ["is_3rd_party", "is_logis"];
              for (var i = 0; i < boolean_fields.length; i++) {
                  const key = boolean_fields[i];
                  if (key in data) {
                      if (data[key] != null) {
                          data[key] = data[key] == 1 ? true : false;
                      }
                  }
              }
              this.data_raw_files = data.data_raw_files;
              this.modelname = this.model_type;
              return data;
          } catch (err) {
              console.log(err);
          }
      },
      init_data() {
        // console.log("init_data",this.model_type);
          this.departments = {
                Sale: [
                    {
                        value: "Sale-A",
                        text: "Sale-A"
                    },
                    {
                        value: "Sale-B",
                        text: "Sale-B"
                    },
                    {
                        value: "Sale-C",
                        text: "Sale-C"
                    },
                ],
                TM: [
                    {
                        value: "preDelivery",
                        text: "Pre-Delivery"
                    },
                    {
                        value: "planner",
                        text: "Planner"
                    },
                    {
                        value: "adminTM",
                        text: "Admin"
                    },
                    {
                        value: "rollCall",
                        text: "Roll-call"
                    },
                ],
                TSM: [
                    {
                        value: "TSMS-A",
                        text: "TSMS-A"
                    },
                    {
                        value: "TSMS-B",
                        text: "TSMS-B"
                    },
                    {
                        value: "TSMS-C",
                        text: "TSMS-C"
                    },
                ],
                Administrative: [
                ],
                Accounting: [
                  {
                      value: "Accounting",
                      text: "แผนกการเงินและบัญชี"
                  },
                  {
                      value: "hr",
                      text: "แผนกบุคคลและจัดชื่อ"
                  },
                  {
                      value: "info",
                      text: "แผนกข้อมูล"
                  },
                ],
                Operation: [
                  {
                      value: "transport",
                      text: "แผนกปฏิบัติการขนส่ง"
                  },
                  {
                      value: "repair",
                      text: "แผนกซ่อมบำรุง"
                  },
                ],
          }
      },
  },
  async mounted() {
      this.init_create_edit();
      await this.get_default_fields();
  },	
};
</script>
