import httpClient from './httpClient';

const prefix = 'administrative'

export default {
    async getAdministrativeAll () {
        const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/getAdministrativeAll`)
        return dataAdministrative
    },
    async getAdministrativeByCompany () {
        const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/getAdministrativeByCompany`)
        return data
    },
    async getAdministrativeByCompanyAndId (id) {
        const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/getAdministrativeByCompanyAndId/${id}`)
        return data
    },

    async getFromIdWithRelationById (id) {
        const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/getFromIdWithRelationById?id=${id}`)
        return data
    },

    async createAdministrative (body) {
        const { data } = await httpClient({ requiresAuth: true }).post(`${prefix}/createAdministrative`, body)
        return data
    },

    async updateAdministrativePermissionById (body, id) {
        const { data } = await httpClient({ requiresAuth: true }).put(`${prefix}/updateAdministrativePermissionById/${id}`, body)
        return data
    },

    async updateAdministrative (body, id) {
        let payload = {
            data: body.data[0]
        }
        const { data } = await httpClient({ requiresAuth: true }).put(`${prefix}/updateAdministrative/${id}`,payload)
        return data
    },

    async updateAdministrativeIsActiveById (body, id) {
        const { data } = await httpClient({ requiresAuth: true }).put(`${prefix}/updateAdministrativeIsActiveById/${id}`, body)
        return data
    },

    async deleteAdministrative (id) {
        const { data } = await httpClient({ requiresAuth: true }).delete(`${prefix}/deleteAdministrative/${id}`)
        return data
    }
}