<template>
  <div class="login-container">
    <div class="form-card-img" :style="`background-image: url('${cover_img}')`"></div>
    <div class="login-form">
      <div class="flex-column">
        <img :src="logo_img" alt="Logo" style="width: 160px" />
        <span class="welcome-text">ลืมรหัสผ่าน</span>
        <!-- <span class="detail-text">รีเซ็ตรหัสผ่านด้วยอีเมล</span> -->
        <span class="detail-text">รีเซ็ตรหัสผ่านด้วยชื่อผู้ใช้</span>
        <div class="flex-column form-inputs">
          <div v-if="formState.statusForm == 'USERNAME_STATE'">
            <a-form
              :model="formState"
              name="basic"
              autocomplete="off"
              @finish="onCheckEmail"
              @finishFailed="onFinishFailed"
            >
              <a-form-item name="username" hasFeedback :rules="[{ required: true, message: 'โปรดกรอกชื่อผู้ใช้ให้ถูกต้อง' }]">
                <a-input v-model:value="formState.username" placeholder="ชื่อผู้ใช้">
                </a-input>
              </a-form-item>
              <a-form-item>
                <a-button type="primary" html-type="submit" class="primary-button">รีเซ็ตรหัสผ่าน</a-button>
              </a-form-item>
            </a-form>
            <div v-if="formState.color">
              <p style="color: green" v-if="formState.message">{{ formState.message }}</p>
            </div>
            <div v-else>
              <p style="color: red" v-if="formState.message">{{ formState.message }}</p>
            </div>
          </div>
          <div v-if="formState.statusForm == 'EMAIL_STATE'">
            <a-form
              :model="formState"
              name="basic"
              autocomplete="off"
              @finish="onFinish"
              @finishFailed="onFinishFailed"
            >
              <a-form-item name="email" hasFeedback :rules="[{ required: true, type: 'email', message: 'โปรดกรอกอีเมลให้ถูกต้อง' }]">
                <a-input v-model:value="formState.email" placeholder="อีเมล" disabled="true">
                </a-input>
              </a-form-item>
              <a-form-item>
                <a-button type="primary" html-type="submit" class="primary-button">รีเซ็ตรหัสผ่าน</a-button>
              </a-form-item>
            </a-form>
            <div v-if="formState.color">
              <p style="color: green" v-if="formState.message">{{ formState.message }}</p>
            </div>
            <div v-else>
              <p style="color: red" v-if="formState.message">{{ formState.message }}</p>
            </div>
          </div>
          <div v-else-if="formState.statusForm == 'OTP_STATE'">
            <a-form
              :model="otpFormState"
              name="otp"
              autocomplete="off"
              @finish="onOtpSubmit"
              @finishFailed="onFinishFailed"
            >
              <a-form-item name="otp" hasFeedback :rules="[{ required: true, message: 'โปรดกรอก OTP ที่ถูกต้อง' }]">
                <a-input v-model:value="otpFormState.otp" placeholder="กรอก OTP">
                </a-input>
              </a-form-item>
              <a-form-item>
                <a-button type="primary" html-type="submit" class="primary-button">ยืนยัน OTP</a-button>
              </a-form-item>
            </a-form>
            <div v-if="formState.color">
              <p style="color: green" v-if="formState.message">{{ formState.message }}</p>
            </div>
            <div v-else>
              <p style="color: red" v-if="formState.message">{{ formState.message }}</p>
            </div>
          </div>
          <div class="navigation-links">
            <router-link to="/user/login" class="nav-link">
              กลับไปหน้าเข้าสู่ระบบ
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive } from 'vue';
import UserService from '../api/UserService.js';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'ForgotPassword',
  data() {
    return {
      cover_img: require('@/assets/images/fleetex-home-page.png'),
      logo_img: require('@/assets/images/fleetex-log-in-logo.png'),
    };
  },
  setup() {
    const router = useRouter();
    
    const formState = reactive({
      email: '',
      username: '',
      message: '',
      statusForm: 'USERNAME_STATE',
      color: false,
    });

    const otpFormState = reactive({
      otp: '',
    });
    let email = reactive('');

    const onCheckEmail = async (values) => {
      try {
        formState.message = 'โปรดรอสักครู่...';
        const response = await UserService.findEmailByUsername({ username: values.username });
        if (response.status === 'success') {
          formState.message = await response.message;
          formState.statusForm = 'EMAIL_STATE';
          formState.color = true;
          formState.email = maskEmail(response.data.email)
          email = response.data.email
        } else {
          formState.message = await response.message;
          formState.statusForm = 'USERNAME_STATE';
          formState.color = false;
        }
      } catch (error) {
        console.log(error)
        formState.message = 'มีข้อผิดพลาดเกิดขึ้น กรุณาลองใหม่อีกครั้ง';
        formState.statusForm = 'USERNAME_STATE';
        formState.color = false;
      }
    };

    const onFinish = async (values) => {
      try {
        formState.message = 'โปรดรอสักครู่...';
        // console.log("onFinish", email)
        const response = await UserService.forgotPassword({ email: email, username: formState.username });
        if (response.status === 'success') {
          formState.message = await response.message;
          formState.statusForm = 'OTP_STATE';
          formState.color = true;
        } else {
          formState.message = await response.message;
          formState.statusForm = 'USERNAME_STATE';
          formState.color = false;
        }
      } catch (error) {
        formState.message = 'มีข้อผิดพลาดเกิดขึ้น กรุณาลองใหม่อีกครั้ง';
        formState.statusForm = 'USERNAME_STATE';
        formState.color = false;
      }
    };

    const onOtpSubmit = async (values) => {
      try {
        formState.message = 'โปรดรอสักครู่...';
        const response = await UserService.verifyOtp({ email: email, otp: values.otp });

        if (response.status === 'success') {
          formState.message = response.message;
          formState.color = true;
          router.push({
            name: 'ResetPassword',
            query: { email: email, otp: values.otp,username: formState.username }
          });
        } else {
          formState.message = 'OTP ไม่ถูกต้อง กรุณาลองใหม่';
          formState.color = false;
        }
      } catch (error) {
        console.error('Error during OTP verification:', error);
        formState.message = 'เกิดข้อผิดพลาดเกิดขึ้น กรุณาลองใหม่อีกครั้ง';
        formState.color = false;
      }
    };

    const onFinishFailed = (errorInfo) => {
      console.log('Failed:', errorInfo);
    };

    const maskEmail = (email) => {
    let [user, domain] = email.split("@");
    let maskedUser = user.slice(0, 2) + "***" + user.slice(-1);
    return maskedUser + "@" + domain;
  }

    return {
      formState,
      otpFormState,
      onFinish,
      onFinishFailed,
      onOtpSubmit,
      onCheckEmail,
      email,
      maskEmail
    };
  },
});
</script>


<style lang="scss">
$blue: #1890FF;

.login-container {
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
}
.login-form {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (min-width: 992px) {
    max-width: 50%;
    flex: 0 0 50%;
  }
}
.form-card-img {
  width: 100%;
  min-height: 300px;
  background-size: cover;
  background-position: 50% 50%;

  @media (min-width: 992px) {
    max-width: 50%;
    flex: 0 0 50%;
  }
}
.welcome-text {
  text-align: center;
  width: 35rem;
  margin-top: 1rem;
  font-size: 19px;
  font-weight: 800;
  color: RGB(38, 49, 107);
}
.detail-text {
  margin-bottom: 1.5rem;
  font-size: 14px;
}
.form-inputs {
  width: 100%;
  align-items: initial;
}
.primary-button {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: $blue;
}
.ant-form-item {
  margin-bottom: 1rem !important;
}
.navigation-links {
  display: flex;
  justify-content: space-between;
}
.nav-link {
  font-size: 12px;
  text-align: center;
  margin: 1rem 0;
}
</style>
