
let price_rate_drawer_fields = {
    drawer_title:'ข้อมูลเรทราคา',
    drawer_button_text:'เพิ่มเรทราคา',
    drawer_button_text_edit:'แก้ไขเรทราคา',
    target_modelname:'PriceRate',
    columns: [
        {
            title: 'ลำดับ',
            dataIndex: 'index',
            key: 'index',
            width: 120,
        },
        {
            title: 'ต้นทาง',
            dataIndex: 'start_place',
            key: 'start_place',
            width: 120,
        },
        {
            title: 'ปลายทาง',
            dataIndex: 'end_place',
            key: 'end_place',
            width: 120,
        },
        {
            title: 'คำอธิบาย',
            dataIndex: 'descriptions',
            key: 'descriptions',
            width: 120,
        },
        {
            title: 'H-4WH',
            dataIndex: 'H_4WH',
            key: 'H_4WH',
            width: 120,
        },
        {
            title: 'H-4WJ',
            dataIndex: 'H_4WJ',
            key: 'H_4WJ',
            width: 120,
        },
        {
            title: 'H-6WH',
            dataIndex: 'H_6WH',
            key: 'H_6WH',
            width: 120,
        },
        {
            title: 'H-4WT',
            dataIndex: 'H_4WT',
            key: 'H_4WT',
            width: 120,
        },
        {
            title: 'H-4WD',
            dataIndex: 'H_4WD',
            key: 'H_4WD',
            width: 120,
        },
        {
            title: 'H-10WH',
            dataIndex: 'H_10WH',
            key: 'H_10WH',
            width: 120,
        },
        {
            title: 'H-10WT',
            dataIndex: 'H_10WT',
            key: 'H_10WT',
            width: 120,
        },
        {
            title: 'H-10WD',
            dataIndex: 'H_10WD',
            key: 'H_10WD',
            width: 120,
        },
        {
            title: 'H-18WH',
            dataIndex: 'H_18WH',
            key: 'H_18WH',
            width: 120,
        },
        {
            title: 'H-22WH',
            dataIndex: 'H_22WH',
            key: 'H_22WH',
            width: 120,
        },
        {
            title: 'C-4WH',
            dataIndex: 'C_4WH',
            key: 'C_4WH',
            width: 120,
        },
        {
            title: 'C-4WJ',
            dataIndex: 'C_4WJ',
            key: 'C_4WJ',
            width: 120,
        },
        {
            title: 'C-6WH',
            dataIndex: 'C_6WH',
            key: 'C_6WH',
            width: 120,
        },
        {
            title: 'C-4WT',
            dataIndex: 'C_4WT',
            key: 'C_4WT',
            width: 120,
        },
        {
            title: 'C-4WD',
            dataIndex: 'C_4WD',
            key: 'C_4WD',
            width: 120,
        },
        {
            title: 'C-10WH',
            dataIndex: 'C_10WH',
            key: 'C_10WH',
            width: 120,
        },
        {
            title: 'C-10WT',
            dataIndex: 'C_10WT',
            key: 'C_10WT',
            width: 120,
        },
        {
            title: 'C-10WD',
            dataIndex: 'C_10WD',
            key: 'C_10WD',
            width: 120,
        },
        {
            title: 'C-18WH',
            dataIndex: 'C_18WH',
            key: 'C_18WH',
            width: 120,
        },
        {
            title: 'C-22WH',
            dataIndex: 'C_22WH',
            key: 'C_22WH',
            width: 120,
        },        
        {
            title: 'ชั่วโมงวิ่งงาน',
            dataIndex: 'total_hours',
            key: 'total_hours',
            width: 120,
        },
        {
            title: 'ระยะทางรวม',
            dataIndex: 'total_distance',
            key: 'total_distance',
            width: 120,
        },
        {
            title: 'ตัวเลือก',
            dataIndex: 'id',
            key: 'id',
            width: 100,
            align: 'center',
            fixed: 'right'
        },
    ],
    drawer_fields: {
        inputs: {
            start_place: {
                input_type: 'text',
                placeholder: 'ต้นทาง',
                required: true,
                col_span: 24,
            },
            end_place: {
                input_type: 'text',
                placeholder: 'ปลายทาง',
                required: true,
                col_span: 24,
            },
            descriptions: {
                input_type: 'text',
                placeholder: 'คำอธิบาย',
                required: false,
                col_span: 24,
            },
            H_4WH: {
                input_type: 'number',
                placeholder: 'H-4WH',
                required: false,
                col_span: 12,
            },
            H_4WJ: {
                input_type: 'number',
                placeholder: 'H-4WJ',
                required: false,
                col_span: 12,
            },
            H_6WH: {
                input_type: 'number',
                placeholder: 'H-6WH',
                required: false,
                col_span: 12,
            },
            H_4WT: {
                input_type: 'number',
                placeholder: 'H-4WT',
                required: false,
                col_span: 12,
            },
            H_4WD: {
                input_type: 'number',
                placeholder: 'H-4WD',
                required: false,
                col_span: 12,
            },
            H_10WH: {
                input_type: 'number',
                placeholder: 'H-10WH',
                required: false,
                col_span: 12,
            },
            H_10WT: {
                input_type: 'number',
                placeholder: 'H-10WT',
                required: false,
                col_span: 12,
            },
            H_10WD: {
                input_type: 'number',
                placeholder: 'H-10WD',
                required: false,
                col_span: 12,
            },
            H_18WH: {
                input_type: 'number',
                placeholder: 'H-18WH',
                required: false,
                col_span: 12,
            },
            H_22WH: {
                input_type: 'number',
                placeholder: 'H-22WH',
                required: false,
                col_span: 12,
            },
            C_4WH: {
                input_type: 'number',
                placeholder: 'C-4WH',
                required: false,
                col_span: 12,
            },
            C_4WJ: {
                input_type: 'number',
                placeholder: 'C-4WJ',
                required: false,
                col_span: 12,
            },
            C_6WH: {
                input_type: 'number',
                placeholder: 'C-6WH',
                required: false,
                col_span: 12,
            },
            C_4WT: {
                input_type: 'number',
                placeholder: 'C-4WT',
                required: false,
                col_span: 12,
            },
            C_4WD: {
                input_type: 'number',
                placeholder: 'C-4WD',
                required: false,
                col_span: 12,
            },
            C_10WH: {
                input_type: 'number',
                placeholder: 'C-10WH',
                required: false,
                col_span: 12,
            },
            C_10WT: {
                input_type: 'number',
                placeholder: 'C-10WT',
                required: false,
                col_span: 12,
            },
            C_10WD: {
                input_type: 'number',
                placeholder: 'C-10WD',
                required: false,
                col_span: 12,
            },
            C_18WH: {
                input_type: 'number',
                placeholder: 'C-18WH',
                required: false,
                col_span: 12,
            },
            C_22WH: {
                input_type: 'number',
                placeholder: 'C-22WH',
                required: false,
                col_span: 12,
            },
            total_hours: {
                input_type: 'text',
                placeholder: 'ชั่วโมงวิ่งงาน',
                required: false,
                col_span: 24,
            },
            total_distance: {
                input_type: 'number',
                placeholder: 'ระยะทางรวม',
                required: false,
                col_span: 24,
            },
        },
    }
};

export default price_rate_drawer_fields