import httpClient from './httpClient';

const prefix = 'operation'

export default {
    async getOperationAll () {
        const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/getOperationAll`)
        return dataOperation
    },
    async getOperationByCompany () {
        const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/getOperationByCompany`)
        return data
    },
    async getOperationByCompanyAndId (id) {
        const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/getOperationByCompanyAndId/${id}`)
        return data
    },

    async getFromIdWithRelationById (id) {
        const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/getFromIdWithRelationById?id=${id}`)
        return data
    },

    async createOperation (body) {
        const { data } = await httpClient({ requiresAuth: true }).post(`${prefix}/createOperation`, body)
        return data
    },

    async updateOperationPermissionById (body, id) {
        const { data } = await httpClient({ requiresAuth: true }).put(`${prefix}/updateOperationPermissionById/${id}`, body)
        return data
    },

    async updateOperation (body, id) {
        let payload = {
            data: body.data[0]
        }
        const { data } = await httpClient({ requiresAuth: true }).put(`${prefix}/updateOperation/${id}`,payload)
        return data
    },

    async updateOperationIsActiveById (body, id) {
        const { data } = await httpClient({ requiresAuth: true }).put(`${prefix}/updateOperationIsActiveById/${id}`, body)
        return data
    },

    async deleteOperation (id) {
        const { data } = await httpClient({ requiresAuth: true }).delete(`${prefix}/deleteOperation/${id}`)
        return data
    }
}