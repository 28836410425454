<template>
    <div class="login-container">
        <div class="form-card-img" :style="`background-image: url('${cover_img}')`" ></div>
        <div class="login-form">
            <a-spin :spinning="loading">
            <div class="flex-column">
                <img style="width: 160px" :src="logo_img" />
                <span style="margin: 1rem;font-size: 19px">ยินดีต้อนรับสู่ Fleetex Management System</span>
                <a-alert
                    v-if="showWarning"
                    message="การเข้าถึงถูกระงับชั่วคราว"
                    description="ขณะนี้บัญชีของคุณถูกระงับการใช้งานชั่วคราว โปรดติดต่อผู้ดูแลระบบหรือติดต่อฝ่ายสนับสนุนเพื่อขอข้อมูลเพิ่มเติม หากต้องการทราบเหตุผลหรือระยะเวลาในการกลับมาใช้งานได้ ขอบคุณค่ะ/ครับ"
                    type="warning"
                    show-icon
                    style="max-width: 450px; word-wrap: break-word; white-space: normal; margin-bottom: 10px"
                />
                <a-alert
                v-if="showLoginFailed"
                message="การเข้าสู่ระบบไม่สำเร็จ ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง"
                type="error"
                show-icon
                style="max-width: 450px; word-wrap: break-word; white-space: normal; margin-bottom: 10px"
                >
                    <template #description>
                        <div>
                        โปรดตรวจสอบข้อมูลและลองใหม่อีกครั้ง หรือติดต่อฝ่ายสนับสนุนหากต้องการความช่วยเหลือ<br />
                        <span style="font-style: italic;">หมายเหตุ:</span> ตรวจสอบให้แน่ใจว่าปุ่ม Caps Lock ไม่ได้
                        </div>
                    </template>
                </a-alert>
                <div class="flex-column" style="width: 450px; align-items: initial;">
                    <input_primary
                        style="margin: 0.5rem 0"
                        type="text"
                        icon="person"
                        placeholder="ชื่อผู้ใช้"
                        :name="username"
                        @input_model="username = $event"
                        @input="resetLoginFailed"
                        :class="{ 'input-error': showLoginFailed }" 
                    />
                    <div style="position: relative; margin: 0.5rem 0;">
                    <input_primary
                        style="width: 100%;"
                        :type="showPassword ? 'text' : 'password'"
                        icon="lock"
                        placeholder="รหัสผ่าน"
                        :name="password"
                        @input_model="password = $event"
                        @input="resetLoginFailed"
                        :enter_press="sign_in"
                        :class="{ 'input-error': showLoginFailed }" 
                    />
                    <EyeOutlined v-if="this.showPassword"
                        @click="togglePasswordVisibility" 
                        style="
                        position: absolute;
                        right: 10px;
                        top: 50%;
                        transform: translateY(-50%);
                        font-size: 16px;
                        color: grey;
                        cursor: pointer;
                        z-index: 10; 
                        "
                    />
                    <EyeInvisibleOutlined v-if="!this.showPassword"
                        @click="togglePasswordVisibility" 
                        style="
                        position: absolute;
                        right: 10px;
                        top: 50%;
                        transform: translateY(-50%);
                        font-size: 16px;
                        color: grey;
                        cursor: pointer;
                        z-index: 10; 
                        "
                    />
                    </div>
                    <a-button type="primary" @click="sign_in()" class="primary-button" style="margin: 0.5rem 0">เข้าสู่ระบบ</a-button>
                    <router-link to="/user/forgot_password" class="nav-link">ลืมรหัสผ่าน?</router-link>
                </div>
            </div>
            </a-spin>
        </div>
    </div>
</template>

<script>
import input_primary from '../components/input_primary.vue'
import UserService from '../api/UserService.js'
import { cl } from '@fullcalendar/core/internal-common';
import bcrypt from 'bcryptjs';
import { PlusOutlined, EyeOutlined, EyeInvisibleOutlined,DeleteOutlined, DownloadOutlined } from '@ant-design/icons-vue';


export default {
  components: { input_primary,EyeOutlined,EyeInvisibleOutlined },
    name: 'Login',
    data () {
        return {
            loading: false,
            showWarning: false,
            showLoginFailed: false,
            showPassword: false,
            cover_img: require('@/assets/images/fleetex-home-page.png'),
            logo_img: require('@/assets/images/fleetex-log-in-logo.png'),

            username: null,
            password: null
        }
    },
    methods: {
        resetLoginFailed() {
            this.showLoginFailed = false;
        },
        togglePasswordVisibility() {
            this.showPassword = !this.showPassword;
        },
        async sign_in () {
            this.showLoginFailed = false;
            this.loading = true;
            try{
            const data = await UserService.login({ username: this.username, password: this.password })

            if (data.token) {
                localStorage.setItem('token', data.token)
                localStorage.setItem('role', data.role)
                localStorage.setItem('companyData', JSON.stringify(await UserService.profile()))

                const companyData = JSON.parse(localStorage.getItem('companyData'));
                const useUser = companyData?.Admin?.use_user;
                const userName = companyData?.Admin?.username;
                const password = companyData?.Admin?.password;
                // const isPasswordMatch = await bcrypt.compare(this.password, password);

                if (useUser == 0) {
                    localStorage.clear()
                    this.showWarning = true;
                    this.loading = false;
                    return;
                }
                this.startSessionTimeout();
                location.reload()
                // this.loading = false;
            }
            }catch(error){
                localStorage.clear()
                this.username = null;
                this.password = null;
                this.showLoginFailed = true;
                this.loading = false;
            }
        },
        startSessionTimeout() {
            const sessionTimeout = 28800000; // 8 hours in milliseconds
            const checkInterval = 60000; // Check every minute

            const intervalId = setInterval(() => {
                const token = localStorage.getItem('token');
                if (!token) {
                    clearInterval(intervalId);
                    localStorage.clear();
                    location.reload();
                    setTimeout(() => {
                        this.$router.push('/user/login');
                    }, 100);
                }
            }, checkInterval);

            setTimeout(() => {
                clearInterval(intervalId);
                localStorage.clear();
                location.reload();
                setTimeout(() => {
                    this.$router.push('/user/login');
                }, 100);
            }, sessionTimeout);
        }
    },
    mounted() {
        if (localStorage.getItem('token') && localStorage.getItem('companyData')) {
            setTimeout(() => {
                this.$router.push('/app/dashboard')
                this.startSessionTimeout();
            }, 100)
        }
    }
}
</script>

<style lang="scss">
    .login-container {
        height: 100vh;
        display: flex;
        flex-wrap: wrap;
    }
    .login-form {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        @media (min-width: 992px) {
            max-width: 50%;
            flex: 0 0 50%;
        }
    }
    .form-card-img {
        width: 100%;
        min-height: 300px;
        background-size: cover;
        background-position: 50% 50%;


        @media (min-width: 992px) {
            max-width: 50%;
            flex: 0 0 50%;
        }
    }
    .input-error {
        border: 1px solid red;  /* Red border when there's an error */
    }

</style>