import httpClient from './httpClient';

const prefix = 'leaveAdmin'

export default {
    async isReadLeaveAdminId(id){
        const { data } = await httpClient({ requiresAuth: true }).put(`${prefix}/is_read/`+id)
        return data
    }
}
