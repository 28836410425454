<template>
    <div>
      <div v-for="index in ((create_paper_original_and_copy) ? 2 : 1)" :key="index">
        <div v-if="show_pdf" :class="'page-' + index" style="height: 1754px; width: 1240px; display: flex; padding: 4em; flex-direction: column; justify-content: space-between">
          <div style="height: 95%; width: 100%; display: flex; flex-direction: column; border-bottom: 2px solid black;">
            <p style="margin-left: 90%;">{{ paper_type(index) }}</p>
            <div>
              <div style="display:flex;justify-content: space-between;align-items:end;border-bottom: 2px solid black;">
                <div>
                  <span style="font-size: 18px;">
                      <div style="display: flex;flex-direction: row;">
                        <img crossorigin="anonymous" style="height: 90px;object-fit: cover;" :src="car_companies.logoSc" />
                        <img crossorigin="anonymous" style="height: 90px;width: 180px;object-fit: cover;" :src="car_companies.logoIso" /><br/>
                      </div>
                      {{ selected_billing.company?.fullname }} <br>
                      เลขประจำตัวผู้เสียภาษี {{ selected_billing.company?.tax_id ?? "-" }} <br>
                      โทร. {{ selected_billing.company?.phone_no }} โทรสาร. {{ selected_billing.company?.fax_no }}<br>
                      {{ selected_billing?.company?.address }} <br>
                  </span>
                  </div>
                    <img crossorigin="anonymous" style="height: 170px ;object-fit: cover;" :src="car_companies.logo" />
                </div>
              <br>
              <h1 style="text-align: center">INVOICE / ใบแจ้งหนี้
              </h1>
              <div style="display: flex;width: 100%;justify-content: end;">
                <div style="margin-right: 2rem;text-align: end;">
                  <p>เลขที่:</p>
                  <p>วันที่:</p>
                  <p>QTC:</p>
                </div>
                <div style="width: 25%">
                  <p>{{ selected_billing.billing_no }}</p>
                  <p>{{ render_time(new Date(), 'DD MMMM BBBB') }}</p>
                  <p>
                    <span v-if="selected_billing?.booking && selected_billing?.booking.length > 0">
                      <template v-for="(booking, index) in selected_billing?.booking" :key="index">
                        {{ booking.booking_no }}<span v-if="index < selected_billing?.booking.length - 1">, </span>
                      </template>
                    </span>
                    <span v-else>
                      {{ selected_billing?.booking?.booking_no }}
                    </span>
                  </p>
                </div>
              </div>
              <div>
                <p>ชื่อลูกค้า: {{ selected_billing?.booking[0]?.client.fullname }}</p>
                <p>ที่อยู่: {{ selected_billing?.booking[0]?.client.address }}</p>
                <p>เลขประจำตัวผู้เสียภาษี: {{ selected_billing?.booking[0]?.client.tax_id }}</p>
              </div>
              <br>
              <table style="width: 100%;border-collapse: collapse;">
                <tr style="text-align: center;">
                  <td style="width: 10%">ลำดับ</td>
                  <td style="width: 40%">{{selected_billing.is_guarantee ? 'ค่าหลักประกันสัญญางาน' : 'รายการ'}}</td>
                  <td style="width: 10%">หน่วย</td>
                  <td style="width: 20%">ราคา/หน่วย(บาท)</td>
                  <td style="width: 20%">ราคารวม(บาท)</td>
                </tr>



                <!-- <tr style="text-align:center" v-for="{ quantity, cost, remark, expenses }, i in selected_billing?.booking?.booking_car_types" :key="i">
                  <td>{{ (i + 1) }}</td>
                  <td style="text-align:start">
                    <span v-if="remark">
                      <span v-for="line in remark.split('\n')">
                        {{ line }}<br>
                      </span>
                      <span v-if="expenses.length">(ค่าใช้จ่ายเพิ่มเติม {{expenses.reduce((a,b) => a + (b.amount * b.price), 0)}} บาท)</span>
                    </span>
                  </td>
                  <td style="text-align: center">{{ quantity }}</td>
                  <td style="text-align: end">
                    <template v-if="selected_billing.billing_type_id == 1">
                      {{ ((selected_billing.total_price - selected_billing.inc_vat - (selected_billing?.booking?.extras?.reduce((total, extra) => total + (extra.price * extra.amount), 0) || 0) + Number(selected_billing.booking[0].discount)) / quantity).toLocaleString('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}    
                    </template> 
                    <template v-else>
                      {{ (selected_billing.total_price / quantity).toLocaleString('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }} 
                      </template>  
                  </td>                  
                    <td style="text-align: end">
                      <template v-if="selected_billing.billing_type_id == 1">
                        {{ (selected_billing.total_price - selected_billing.inc_vat - (selected_billing?.booking?.extras?.reduce((total, extra) => total + (extra.price * extra.amount), 0) || 0) + Number(selected_billing.booking[0].discount)).toLocaleString('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}      
                      </template>   
                      <template v-else>
                        {{ selected_billing.total_price.toLocaleString('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}
                      </template>           
                    </td>                
                </tr> -->

                <template v-if="selected_billing?.booking">
                  <template v-for="(booking, bookingIndex) in selected_billing.booking" :key="bookingIndex">
                      <template v-if="booking.booking_car_types.length > 0">
                          <tr v-for="{ quantity, cost, remark, expenses }, i in booking.booking_car_types" :key="i" style="text-align:center">
                              <td>{{ (i + 1) }}</td>
                              <td style="text-align:start">
                                  <span v-if="remark">
                                      <span v-for="line in remark.split('\n')" :key="line">
                                          {{ line }}<br>
                                      </span>
                                      <span v-if="expenses.length">(ค่าใช้จ่ายเพิ่มเติม {{ expenses.reduce((a, b) => a + (b.amount * b.price), 0) }} บาท)</span>
                                  </span>
                              </td>
                              <td style="text-align: center">{{ quantity }}</td>
                              <td style="text-align: end">
                                  <template v-if="selected_billing.billing_type_id == 1">
                                      {{ ((selected_billing.total_price - selected_billing.inc_vat - (booking?.extras?.reduce((total, extra) => total + (extra.price * extra.amount), 0) || 0) + Number(booking.discount)) / quantity).toLocaleString('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}
                                  </template>
                                  <template v-else>
                                      {{ (selected_billing.total_price / quantity).toLocaleString('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}
                                  </template>
                              </td>
                              <td style="text-align: end">
                                  <template v-if="selected_billing.billing_type_id == 1">
                                      {{ (selected_billing.total_price - selected_billing.inc_vat - (booking?.extras?.reduce((total, extra) => total + (extra.price * extra.amount), 0) || 0) + Number(booking.discount)).toLocaleString('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}
                                  </template>
                                  <template v-else>
                                      {{ selected_billing.total_price.toLocaleString('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}
                                  </template>
                              </td>
                          </tr>
                      </template>
                  </template>
              </template>



                <tr v-if="selected_billing.billing_type_id == 1" style="text-align:center;" v-for="{ price, extra: { name }, amount }, i in selected_billing?.booking[0]?.extras" :key="i">
                  <td >{{ (selected_billing?.booking[0]?.booking_car_types.length + i + 1) }}</td>
                  <td :colspan="1" style="text-align:start;word-break: break-all;">
                    {{ name }}
                  </td>
                  <td style="text-align: end">{{ price.toLocaleString('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}</td>
                  <td>{{ amount }}</td>
                  <td style="text-align: end">{{ (price * amount).toLocaleString('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}</td>
                </tr>



                <tr>
                  <td :colspan="4">
                    <div style="display: flex;align-items: end;width: 100%;justify-content: space-between;">
                      <p style="padding-left: 20em">({{ price_to_word(selected_billing.total_price) }})</p>
                      <div style="text-align: end;">
                        <p v-if="selected_billing.billing_type_id == 1">ส่วนลด</p>
                        <p v-if="selected_billing.inc_vat && selected_billing.billing_type_id == 1">ภาษีมูลค่าเพิ่ม</p>
                        <p>จำนวนเงินรวมทั้งสิ้น</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div style="text-align: end;">
                      <p v-if="selected_billing.billing_type_id == 1">
                        {{selected_billing.booking[0].discount? selected_billing.booking[0].discount.toLocaleString('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 }):'0.00'}}
                      </p>
                      <p v-if="selected_billing.inc_vat && selected_billing.billing_type_id == 1">
                        {{ selected_billing.inc_vat.toLocaleString('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}
                      </p>
                      <p> 
                        {{(selected_billing.total_price).toLocaleString('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}
                      </p>                
                    </div>
                  </td>
                </tr>
                <tr>
                  <td :colspan="4" style="text-align: end;">
                    <p v-if="selected_billing.withholding">สามารถหักภาษี ณ ที่จ่าย (1%)(ค่าขนส่ง)</p>
                  </td>
                  <td style="text-align: end;">
                    <p v-if="selected_billing.withholding">{{ selected_billing.withholding.toLocaleString('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}</p>
                  </td>
                </tr>
              </table>
            </div>
            <br>
            <div>
              <p>กำหนดชำระเงิน: ชำระเงินภายในวันที่ {{ render_time(selected_billing.expire_date, 'DD MMMM YYYY') }} ตามเอกสารอ้างอิง</p>
              <p>วิธีการชำระเงิน ดังต่อไปนี้</p>
              <div>
                <p>[ ] ชำระเงินด้วยเงินสด</p>
                <p>[ ] ชำระเงินด้วยเช็ค หรือ ดร๊าฟ โดยสั่งจ่ายในนาม "{{ selected_billing?.booking[0]?.car_company?.fullname }}" และ "A/C PAYEE ONLY" เท่านั้น</p>
                <p>[ ] ชำระเงินด้วยโอนเงินโดยโอนเข้าบัญชี {{ selected_billing?.booking[0]?.car_company?.bank_code }} 
                  สาขา {{ selected_billing?.booking[0]?.car_company?.bank_branch }} 
                  ในนาม {{ selected_billing?.booking[0]?.car_company?.fullname }}
                  บัญชีออมทรัพย์เลขที่ {{ selected_billing?.booking[0]?.car_company?.bank_account_no }}
                </p>
              </div>
              <br>
              <div style="display:flex;">
                <p style="margin-right: 2rem">หมายเหตุ: </p>
                <div>
                  <p>- โปรดตรวจสอบรายการข้างต้น หากมีข้อผิดพลาดและต้องการแก้ไข กรุณาติดต่อกลับมาภายใน 3 วัน นับจากวันที่รับวางบิล มิฉะนั้นทางบริษัทจะถือว่าท่านได้รับเอกสารถูกต้อง</p>
                  <p>- ในกรณีโอนเงินเข้าบัญชีธนาคาร กรุณาส่ง หลักฐานการชำระเงิน มาทาง โทรสาร. {{ selected_billing?.booking[0]?.car_company?.fax_no }} หรือ อีเมล์ {{ selected_billing?.booking[0]?.car_company?.email }}</p>
                </div>
              </div>
              <div style="display: flex;justify-content: space-between;text-align: center;line-height: 2; align-items: center;">
                <div style="margin-top: 6.5rem;">
                  <div v-if="selected_billing?.booking[0]?.sale?.signature" style="height: 60px;"></div>
                  <p>ลงชื่อ...............................................................................ผู้รับมอบงาน</p>
                  <p>(.........................................................................................)</p>
                  <p>{{ selected_billing?.booking[0]?.client.fullname }}</p>
                  <p>วันที่ ........................... / ........................... / ...........................</p>
                </div>
                <div style="margin: 0.5rem;width: 105px;height: 105px;border: 1px dotted black;display: flex;align-items: center;justify-content: center;" v-if="!no_stamp">
                  <p>ประทับตรา</p>
                </div>
                <div style="position: relative;">
                  <div v-if="selected_billing?.booking[0]?.sale?.signature">
                    <canvas :id="'signature-canvas-' + index" width="320" height="55"
                        style="position: absolute; left: 5rem; top: 40px;"></canvas>
                    <p style="padding-top: 9rem;">
                      ลงชื่อ..........................................................................ผู้ส่งมอบงาน
                    </p>
                  </div>
                  <div v-else>
                    <p style="padding-top: 3rem;">
                      ลงชื่อ..........................................................................ผู้ส่งมอบงาน
                    </p>
                  </div>
                  <p>{{ display_fullname(selected_billing.booking[0].sale?.firstname, selected_billing.booking[0].sale?.lastname) }}</p>
                  <template v-if="auto_date">
                    <p >วันที่ {{ render_time(new Date(), 'DD MMMM BBBB') }}</p>
                  </template>
                  <template v-else>
                    <p>วันที่ ........................... / ........................... / ...........................</p>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex;justify-content: space-between;">
            <span>{{ car_companies.fullname_en }}</span>
            <span v-if="(businessTypeId == null) || businessTypeId == 1">F-AC-0054 ฉบับแก้ไขครั้งที่ 2 วันที่อนุมัติใช้ 1 มิถุนายน 2560</span>
            <span v-else></span>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { jsPDF } from 'jspdf'
  import html2canvas from 'html2canvas'
  import dayjs from 'dayjs'
  import buddhistEra from 'dayjs/plugin/buddhistEra'
  dayjs.extend(buddhistEra);
  import 'dayjs/locale/th';
  import { message } from 'ant-design-vue';
  import { ArabicNumberToText } from '@/components/helpers.js'
  import Swal from 'sweetalert2';
  import 'jspdf-autotable';
  import CompanyService from "../../api/CompanyService";
  import SystemService from '../../api/SystemService';
  
  export default {
    props: {
      selected_billing: Object,
      billed_amount: {
        type: Number,
        default: 0
      },
      user_name: String,
      no_stamp: Boolean,
      auto_date: Boolean,
      create_paper_original: Boolean,
      create_paper_copy: Boolean,
      create_paper_original_and_copy: Boolean,
      car_companies: Object,
      billing_type_id: Number,
      arrayBillingInQuotation: Array,
    },
    name: 'Export-Modal',
    data() {
      return {
        show_pdf: false,
        logo_url: require('@/assets/logo.jpg'),
        car_company_list: [],
        client_list: [],
        client_contact_list: [],
        remarks_list: [],
        bankList: [],
        typeDocument : "",
        bookingType: {
          1: { txt: 'เที่ยวเดียว (ขาเดียว)', color: 'pink' },
          2: { txt: 'ไป-กลับ', color: 'red' },
          3: { txt: 'รายวัน (เต็มวัน)', color: 'orange' },
          4: { txt: 'รายวัน (ครึ่งวัน)', color: 'green' },
          5: { txt: 'เฉพาะวัน (ส่ง-รับ วันกลางไม่ใช้รถ)', color: 'cyan' },
          6: { txt: 'เฉพาะวัน (ระบุวัน)', color: 'blue' },
          7: { txt: 'ใช้รถทุกวัน', color: 'purple' },
          8: { txt: 'รายเดือน', color: 'green' },
        },
        businessTypeId: null,
      }
    },
    computed: {
      time_now() {
        return dayjs().format('DD-MM-YYYY HH:mm')
      }
    },
    methods: {
      paper_type(index) {
        if (this.create_paper_original_and_copy && index === 1) {
          return 'ต้นฉบับ';
        } else if (this.create_paper_original_and_copy && index === 2) {
          return 'สำเนา';
        }
        if (this.create_paper_copy && index === 1) {
          return 'สำเนา';
        }
        if (this.create_paper_original && index === 1) {
          return 'ต้นฉบับ';
        }
      },
  
      render_booking_detail(booking) {
        const { booking_type, person_qty, flight_pick, flight_drop, time_start, time_end, full_day_amount, start_location_name, end_location_name } = booking;
  
        return `[${booking_type}] ${person_qty} ท่าน ${flight_pick ? `ไฟล์ท ${flight_pick}` : ''} ${flight_drop ? `ไฟล์ท ${flight_drop}` : ''} ${time_start ? `เวลา ${this.render_time(time_start, 'DD MMMM YYYY HH:mm')} น.` : ''} ${time_end ? `ถึงเวลา ${this.render_time(time_end, 'DD MMMM YYYY HH:mm')} น.` : ''} ${full_day_amount ? `จำนวนวัน ${full_day_amount} วัน` : ''} ${start_location_name ? `จุดเริ่มต้น ${start_location_name}` : ''} ${end_location_name ? `จุดสิ้นสุด ${end_location_name}` : ''}`;
      },
      price_to_word(value) {
        return ArabicNumberToText(value);
      },
      get_stops(array) {
        const stops = [];
        if (array.booking_cars) {
          array.booking_cars.map(({ stops }) => (stops)).forEach((stop) => {
            stop.forEach((s) => {
              stops.push(s.name);
            });
          });
          return stops.join(' - ');
        } else {
          return '';
        }
      },
      render_time(datetime, format) {
        return datetime ? dayjs(datetime).locale('th').format(format) : ' - '
      },

      display_fullname(firstname, lastname) {
        const firstNamePart = firstname ? firstname : '';
        const lastNamePart = lastname ? lastname : '';
        var fullName = `${firstNamePart} ${lastNamePart}`.trim();
        if (fullName !== null && fullName.trim() !== "") {
          return `(${fullName})`;
        } else {
          return '(.........................................................................................)';
        }
      },
      
      export_pdf_backup() {
        this.show_pdf = true;
        message.loading({
          content: 'กำลังสร้าง PDF...',
          key: 'export_pdf',
          duration: 0,
        });
        const totalPages = this.create_paper_original_and_copy ? 2 : 1;
        setTimeout(() => {
          const promises = [];
          for (let i = 1; i <= totalPages; i++) {
            promises.push(this.initSignature(this.selected_billing?.booking[0]?.sale?.signature, i));
          }
  
          Promise.all(promises)
            .then(() => {
              const capturePromises = [];
              for (let i = 1; i <= totalPages; i++) {
                const pageElement = document.querySelector(`.page-${i}`);
                if (pageElement) {
                  capturePromises.push(html2canvas(pageElement, {
                    dpi: 72,
                    scale: 2,
                  }));
                } else {
                  console.error(`Element .page-${i} not found`);
                }
              }
  
              return Promise.all(capturePromises);
            })
            .then((canvases) => {
              if (canvases.length === 0) {
                throw new Error('No canvases were created.');
              }
  
              const doc = new jsPDF('p', 'pt', 'a4', true);
              canvases.forEach((canvas, index) => {
                if (index > 0) doc.addPage();
                const imgData = canvas.toDataURL('image/png');
                const width = doc.internal.pageSize.getWidth();
                const height = doc.internal.pageSize.getHeight();
                doc.addImage(imgData, 'PNG', 4, 4, width - 8, height - 8);
              });
              doc.save(`INVOICE-${this.selected_billing?.booking[0]?.booking_no}.pdf`);
              this.show_pdf = false;
              message.success({
                content: 'สร้าง PDF เรียบร้อย',
                key: 'export_pdf',
                duration: 1.5,
              });
              this.$parent.get_delivery_note = false;
              this.$emit('close');
            })
            .catch((error) => {
              console.error('Error generating PDF:', error);
              message.error({
                content: 'เกิดข้อผิดพลาดในการสร้าง PDF',
                key: 'export_pdf',
                duration: 1.5,
              });
              this.show_pdf = false;
            });
        }, 1000); // Increased timeout to 1000ms to ensure all elements are rendered
      },
      async export_docx() {
        try {
          this.$message.loading({
            content: 'กำลังสร้างไฟล์...',
            key: 'export_docx',
            duration: 0
          });
  
          const { base64 } = await ReportService.export_invoice({
            billing_payments: [{ amount: this.billed_amount }],
            selected_billing: this.selected_billing,
            user_name: this.user_name,
            deliverynote: true
          });
  
          const buffer = Buffer.from(base64, 'base64');
          saveAs(new Blob([buffer]), `INVOICE-${this.selected_billing?.booking[0]?.booking_no}.docx`);
          this.$message.success({
            content: 'สร้างไฟล์เรียบร้อย',
            key: 'export_docx',
            duration: 1.5
          });
          this.$emit('close');
        } catch (err) {
          console.log(err);
          this.$emit('close');
        }
      },
      initSignature(signature, index) {
        return new Promise((resolve, reject) => {
          if (!signature) return resolve();
          setTimeout(() => {
            const canvas = document.getElementById(`signature-canvas-${index}`);
            const ctx = canvas.getContext("2d");
            const imageUrl = signature + '?no';
            const img = new Image();
            img.crossOrigin = "anonymous";
            img.src = imageUrl;
            img.onload = function () {
              ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
              resolve();
            }
            img.onerror = function (err) {
              console.log(err);
              reject();
            }
          }, 100);
        });
      },
      async convertImgToBase64(url) {
            const response = await fetch(url);
            const blob = await response.blob();
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
      },
      async export_pdf(){
        try {
          this.show_pdf = true;
            message.loading({
              content: 'กำลังสร้าง PDF...',
              key: 'export_pdf',
              duration: 0,
            });
  
          const doc = new jsPDF({
              orientation: 'p',
              unit: 'pt',
              format: 'a4',
              putOnlyUsedFonts: true,
              lineHeight: 1.2,
          });
  
          const width = doc.internal.pageSize.getWidth();
          const height = doc.internal.pageSize.getHeight();

          // Convert images to Base64
          const imglogo = this.car_companies.logo ? await this.convertImgToBase64(this.car_companies.logo) : null;
          const imglogoIso = this.car_companies.logoIso ? await this.convertImgToBase64(this.car_companies.logoIso) : null;
          const imglogoSc = this.car_companies.logoSc ? await this.convertImgToBase64(this.car_companies.logoSc) : null;
          const imgSignature = this.selected_billing?.booking[0]?.sale?.signature 
            ? await this.getBase64ImageFromURL(this.selected_billing?.booking[0]?.sale?.signature) 
            : null;

          const addHeader = (doc, width) => {
              if(imglogoSc) doc.addImage(imglogoSc, 'JPEG', 20, 15, 45, 45);
              if(imglogoIso) doc.addImage(imglogoIso, 'JPEG', 70, 15, 110, 45);
              if(imglogo) doc.addImage(imglogo, 'JPEG', width - 180, 30, 160, 80);

              doc.setFontSize(18); 
              doc.setTextColor(38, 38, 38);
              doc.setFont('THSarabunNew Bold', 'bold');

              doc.text(this.car_companies.fullname,25, 75);

              doc.setFontSize(13); 
              doc.setTextColor(80, 80, 80);
              const textHeader =
              `${this.car_companies.address}\n` +
              `เลขประจำตัวผู้เสียภาษี : ${this.car_companies.tax_id}\n` +
              `โทรศัพท์ : ${this.car_companies.phone_no} อีเมล : ${this.car_companies.email} Line ID: ${this.car_companies.line_id}\n`;
              const splitTitle = doc.splitTextToSize(textHeader, width - 230);
              doc.text(splitTitle , 25, 90);

              doc.setLineWidth(0.5);
              doc.line(25, 125, width - 25, 125);
          };

          const totalPages = this.create_paper_original_and_copy ? 2 : 1;

          for (let index = 1; index <= totalPages; index++) {
            
            if (index > 1) doc.addPage();

            // console.log(!isArray(this.selected_billing?.booking))
            // if (!isArray(this.selected_billing?.booking)) {
            //   this.selected_billing.booking = [this.selected_billing?.booking]
            // };
            // if (!isArray(this.selected_billing?.total_price)) {
            //   this.selected_billing.total_price = [this.selected_billing?.total_price]
            // };

            // Add first page content
            doc.setFontSize(18);  
            doc.setTextColor(38, 38, 38);
            doc.setFont('THSarabunNew Bold', 'bold');
            doc.setTextColor(0, 0, 0);
            doc.text("ใบแจ้งหนี้ / Invoice ", width / 2 - 10, 150, { align: "center" });
            // const textWidth = doc.getTextWidth(this.typeDocument);
            // Text red
            doc.setTextColor(255, 0, 0);
            const textTypeWidth = this.typeDocument != "" ? 45 : 0;
            doc.text(this.typeDocument, (width / 2) + textTypeWidth, 150, { align: "left" });

            // dynamic detail
            let detailH = 48 + (this.selected_billing?.booking.length * 12);
            doc.rect(25,160,390,detailH); // Draw rectangle
            doc.rect(420,160,150,detailH); // Draw rectangle

            const clientCheck = this.selected_billing?.booking[0]?.client != null ? true : false;

            const textLeft = 
                `ชื่อลูกค้า : ${ clientCheck ? this.selected_billing?.booking[0]?.client.fullname : "-"  } \n` +
                `ที่อยู่ : ${ clientCheck ? this.selected_billing?.booking[0]?.client.address : "-"  } \n`  +
                `เลขประจำตัวผู้เสียภาษี : ${ clientCheck ? this.selected_billing?.booking[0]?.client.tax_id : "-"  } \n` 
            const textRight = 
                `เลขที่ : ${ this.selected_billing.billing_no } \n` +
                `วันที่ : ${ this.render_time(new Date(), 'DD MMMM BBBB') } \n` +
                `QTC : ${ this.selected_billing?.booking?.map(booking => booking.booking_no).join('\n         ') || 'ไม่มีรายการ' } \n`;
            const splitTextLeft = doc.splitTextToSize(textLeft, 470); // Define split text to size
            const splitTextRight = doc.splitTextToSize(textRight, 180); // Define split text to size
            doc.setFontSize(11);
            doc.setFont('THSarabunNew Bold', 'bold');
            doc.setTextColor(80, 80, 80);
            doc.text(splitTextLeft , 30 , 175);
            doc.text(splitTextRight , 425 , 175);
            

            // Content and table
            const columns = ["ลำดับ", "รายการ", "หน่วย", "ราคา/หน่วย (บาท)", "ราคารวม (บาท)"];
            const rows = []
            var totalValue = 0
            var totalCost = 0
            var vatValue = 0
            var withholdValue = 0
            // ส่วนที่ 1 ค่าขนส่ง
            var order = 0;
            this.selected_billing?.booking?.forEach((booking, booking_index) => {
              booking?.booking_car_types?.forEach((item, index) => {
                  const { quantity, cost, remark, expenses } = item;
                  const remarkText = remark ? remark.split('\n') : [];
                  order += 1
                  const row = [
                      order,
                      `${remark} ${expenses.length 
                                    ? `\n(ค่าใช้จ่ายเพิ่มเติม ${Number(expenses.reduce((a,b) => a + (b.amount * b.price), 0)).toLocaleString()} บาท)`
                                    : ''
                                  } `,
                      Number(quantity).toLocaleString(),

                      Number(expenses.reduce((a,b) => a + (b.amount * b.price), 0)) > Number((cost * this.selected_billing.deposit_percent[booking_index])) ?
                        Math.round(Number(cost * this.selected_billing.deposit_percent[booking_index]) / quantity)
                            .toLocaleString('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
                        : Math.round(Number(((cost * this.selected_billing.deposit_percent[booking_index]) - Number(expenses.reduce((a,b) => a + (b.amount * b.price), 0))) / quantity))
                            .toLocaleString('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 }),

                      Math.round(Number(cost * this.selected_billing.deposit_percent[booking_index]))
                          .toLocaleString('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
                  ];

                  // Add to total calculations
                  totalValue += Number(cost * this.selected_billing.deposit_percent[booking_index]);
                  totalCost += Number(cost);

                  // VAT calculations
                  if (booking.inc_vat && this.selected_billing.inc_vat != 0 && this.selected_billing.billing_status_id == 7) {
                    vatValue += Number(cost * this.selected_billing.deposit_percent[booking_index]) * 0.07;
                  }

                  // Withholding calculations
                  if (booking.withholding && this.selected_billing.withholding != 0 && this.selected_billing.billing_status_id == 7) {
                    withholdValue += Number(cost * this.selected_billing.deposit_percent[booking_index]) * 0.01;
                  }
                
                  // Push the row data
                  rows.push(row);
              });

              const lengthBookingCar = booking?.booking_car_types.length;
              // ส่วนที่ 2 ค่าใช้จ่ายเพิ่มเติม
              if(this.selected_billing.billing_type_id == 1 || this.selected_billing.billing_type_id == 5||(this.selected_billing.billing_type_id == 6&&this.selected_billing.select_type_remaining) ){
                booking?.extras.forEach((item, index) => {
                    const { price, extra: { name }, amount } = item;
                    order += 1
                    const row = [
                      order, 
                      name,
                      Number(amount).toLocaleString(),
                      Number(price).toLocaleString('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 }), 
                      Number(price * amount).toLocaleString('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
                    ];
                    totalValue += Number(price * amount)
                    rows.push(row);
                });
              }
            });

            doc.autoTable({
              head: [columns],
              body: rows,
              startY: 230 + detailH - 48,
              theme: 'plain',
              styles: {
                font: 'THSarabunNew',
                fontSize: 13,
              },
              headStyles: {
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
                halign: 'center',   
                font: 'THSarabunNew Bold',
                fontSize: 13,
              },
              columnStyles: {
                  fontSize: 13, 
                  0 : { halign: 'center' },
                  1 : { halign: 'left' , cellWidth: 250 },
                  2 : { halign: 'center' },
                  3 : { halign: 'right' },
                  4 : { halign: 'right' },
              },
              margin: { top: 135, right: 25, bottom: 180, left: 25 },
              didDrawCell: function (data) {
                doc.setDrawColor(118, 112, 112);
                doc.setLineWidth(0.5);
                doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height);
              },
            })

            let docFinalY = doc.lastAutoTable.finalY
            if(height - docFinalY < 250){ // Check if there is enough space for the next content
                doc.addPage();
                docFinalY = 150; 
            }
            const textTypeDocument = this.typeDocument == "(มัดจำ)" ? " (มัดจำ)" : ""
            let textTitle = '';

            // เช็คส่วนลดทั้งหมด
            let textTotal = '';
            let total_discount = 0;
            // loop cal total discount
            this.selected_billing?.booking?.forEach((booking, booking_index) => {
              if(booking.discount && (((this.selected_billing.billing_type_id == 5||(this.selected_billing.billing_type_id == 6&&this.selected_billing.select_type_remaining))&&this.arrayBillingInQuotation[0].billing_status_id != 7)
              ||(this.selected_billing.billing_status_id == 7))){
                totalValue -= Number(Number(booking.discount).toFixed(0))
                total_discount += Number(Number(booking.discount).toFixed(0))
              }
            });
            if (total_discount > 0) {
              textTitle += `ส่วนลด \n`;
              textTotal += `${Number(Number(total_discount).toFixed(0)).toLocaleString() + ".00"}\n`;
            }
            
            // if(this.selected_billing.booking[0].discount && (((this.selected_billing.billing_type_id == 5||(this.selected_billing.billing_type_id == 6&&this.selected_billing.select_type_remaining))&&this.arrayBillingInQuotation[0].billing_status_id != 7)
            // ||(this.selected_billing.billing_status_id == 7))){
            //   textTitle += `ส่วนลด \n`
            // }
            if(this.selected_billing.inc_vat && ((this.selected_billing.billing_type_id == 5||(this.selected_billing.billing_type_id == 6&&this.selected_billing.select_type_remaining))
            ||(this.selected_billing.billing_status_id == 7))){
              textTitle += `ภาษีมูลค่าเพิ่ม \n`
            }
            textTitle += `รวมเป็นเงิน${textTypeDocument} `;
            
            if(this.selected_billing.inc_vat && ((this.selected_billing.billing_type_id == 5||(this.selected_billing.billing_type_id == 6&&this.selected_billing.select_type_remaining))
            ||(this.selected_billing.billing_status_id == 7))){
              if(this.selected_billing.billing_type_id>3&&this.selected_billing.billing_status_id==7){
                textTotal += `${Number(Number(vatValue).toFixed(0)).toLocaleString() + ".00"}\n`
                totalValue += Number(Number(vatValue).toFixed(0))
              }else{
                textTotal += `${Number(Number(this.selected_billing.inc_vat).toFixed(0)).toLocaleString() + ".00"}\n`
                totalValue += Number(Number(this.selected_billing.inc_vat).toFixed(0))
              }
            }
            var withhold = 0
            if(this.selected_billing.withholding > 0 && ((this.selected_billing.billing_type_id == 5||(this.selected_billing.billing_type_id == 6&&this.selected_billing.select_type_remaining))
            ||(this.selected_billing.billing_status_id == 7))){
              if(this.selected_billing.billing_type_id>3&&this.selected_billing.billing_status_id==7){
                withhold = withholdValue
                totalValue -= Number(Number(withholdValue).toFixed(0))
              }else{
                withhold = this.selected_billing.withholding
                totalValue -= this.selected_billing.withholding
              }
            }
            textTotal += `${ Number(Number(totalValue).toFixed(0)).toLocaleString() + ".00" }`
            const textPriceWord = `(${ this.price_to_word(totalValue.toFixed(0)) })`
            const splitTextTitle = doc.splitTextToSize(textTitle, 200);
            const splitTextTotal = doc.splitTextToSize(textTotal, 200);
            const splitTextPriceWord = doc.splitTextToSize(textPriceWord, 200);
            doc.setFontSize(13);
            doc.setFont('THSarabunNew Bold', 'bold');
            doc.text(splitTextTitle , width / 2 + 175 , docFinalY + 13 , { align: "right" });
            doc.text(splitTextTotal , width / 2 + 268 , docFinalY + 13 , { align: "right" });

            // Price word
            let positionWord = 0;
            if(this.selected_billing.inc_vat > 0 && (this.selected_billing.billing_type_id == 1||this.selected_billing.billing_type_id>3)){
              positionWord = 15;
            }
            doc.text(splitTextPriceWord , 200 , docFinalY + 30 + positionWord, { align: "center" });

            const heightRect = this.selected_billing.inc_vat && (this.selected_billing.billing_type_id == 1||this.selected_billing.billing_type_id > 3) ? 48 : 35;
            doc.rect(25 , docFinalY , width / 2 + 151 , heightRect);
            doc.rect(width / 2 + 176 , docFinalY , 96.5 , heightRect);

            if(this.selected_billing.withholding > 0 && ((this.selected_billing.billing_type_id == 5||(this.selected_billing.billing_type_id == 6&&this.selected_billing.select_type_remaining))
            ||(this.selected_billing.billing_status_id == 7))){
              doc.rect(25 , docFinalY + heightRect , width - 50  , 18);
              doc.text(
                `สามารถหักภาษี ณ ที่จ่าย ${this.car_companies.withholding.name} : ${Math.round((withhold)).toLocaleString('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`, 
                width - 30, 
                docFinalY + heightRect + 13 ,
                { align: "right" }
              );
            }

            if(height - docFinalY < 400){ // Check if there is enough space for the next content
                doc.addPage();
                docFinalY = 150; 
            }else{
                docFinalY += 50;
            }

            const paragraph = `กำหนดชำระเงิน : ชำระเงินภายในวันที่ ${ this.render_time(this.selected_billing.expire_date, 'DD MMMM BBBB') } ตามเอกสารอ้างอิง \n` +
              `วิธีการชำระเงิน ดังต่อไปนี้ \n` +
              `[ ] ชำระเงินด้วยเงินสด \n` +
              `[ ] ชำระเงินด้วยเช็ค หรือ ดร๊าฟ โดยสั่งจ่ายในนาม "${ this.car_companies.fullname ?? ''  }" และ "A/C PAYEE ONLY" เท่านั้น \n` +
              `[ ] ชำระเงินด้วยโอนเงินโดยโอนเข้าบัญชี ${ this.bankList.find((item) => item.id == this.car_companies.bank_id).name ?? ''  } สาขา ${ this.car_companies.bank_branch ?? ''  } ในนาม ${ this.car_companies.fullname ?? ''  } บัญชีออมทรัพย์เลขที่ ${ this.car_companies.bank_account_no ?? '' } \n` +
              `หมายเหตุ : \n` +
              `- โปรดตรวจสอบรายการข้างต้น หากมีข้อผิดพลาดและต้องการแก้ไข กรุณาติดต่อกลับมาภายใน 3 วัน นับจากวันที่รับวางบิล มิฉะนั้นทางบริษัทจะถือว่าท่านได้รับเอกสารถูกต้อง \n` +
              `- ในกรณีโอนเงินเข้าบัญชีธนาคาร กรุณาส่ง หลักฐานการชำระเงิน มาทาง โทรสาร. ${ this.car_companies.fax_no ?? ''  } หรือ อีเมล์ ${ this.car_companies.email ?? ''  }`
            const splitParagraph = doc.splitTextToSize(paragraph, width - 50);
            doc.setFontSize(13);
            doc.setFont('THSarabunNew', 'normal');
            doc.text(splitParagraph , 25 , docFinalY + 30);


            let pageCount = 0;
            let pageIndex = 0;
            if(index == 1){
              pageCount = doc.internal.getNumberOfPages();
              pageIndex = 1;
            }else{
              pageCount = doc.internal.getNumberOfPages();
              pageIndex = doc.internal.getNumberOfPages() / 2 + 1;
            }
            
            // นับจำนวนหน้า แล้วใส่ Header และ Footer ใหม่ ทุกหน้า
            for (let i = pageIndex; i <= pageCount; i++) {
                  doc.setPage(i);
                  doc.setLineDashPattern([0, 0], 0);
                  
                  addHeader(doc, width);
                  
                  //ต้นฉบับ และ สำเนา
                  if (this.create_paper_original_and_copy && index === 1) {
                    doc.text('ต้นฉบับ', width - 50, 20);
                  } else if (this.create_paper_original_and_copy && index === 2) {
                    doc.text('สำเนา', width - 50, 20);
                  }

                  //ต้นฉบับ
                  if (this.create_paper_original && index === 1) {
                    doc.text('ต้นฉบับ', width - 50, 20);
                  }

                  //สำเนา
                  if (this.create_paper_copy && index === 1) {
                    doc.text('สำเนา', width - 50, 20);
                  }

                  //Signature Footer
                  // if(i != pageCount){
                      docFinalY = height - 200;

                      doc.setDrawColor(0, 0, 0);
                      doc.rect(25,doc.internal.pageSize.height - 180, width - 50 , 140);

                      const signature = 
                          `ลงชื่อ..............................................................\n\n` +
                          `(.....................................................................)`
                      const textNameSignatureLeft = 
                          `ผู้รับมอบงาน\n` +
                          `${ clientCheck ? this.selected_billing?.booking[0]?.client.fullname : "-" }\n` +
                          `วันที่ .................. / ........................... / .................`
                      const textNameSignatureRight = 
                          `ผู้ส่งมอบงาน\n` +
                          `${ this.display_fullname(this.selected_billing.booking[0].sale?.firstname, this.selected_billing.booking[0].sale?.lastname) }\n` + 
                          `${ this.auto_date ? this.render_time(new Date(), 'DD MMMM BBBB') : "วันที่ ................. / ........................... / ................." }`

                      const textSignature = doc.splitTextToSize(signature, width / 2);
                      const splitTextNameSignatureLeft = doc.splitTextToSize(textNameSignatureLeft, 250);
                      const splitTextNameSignatureRight = doc.splitTextToSize(textNameSignatureRight, 250);

                      doc.setFontSize(11);
                      doc.setFont('THSarabunNew', 'normal');

                      if(imgSignature){
                          doc.addImage(imgSignature, 'JPEG',  width / 2 + 100 , docFinalY + 30, 110, 50);
                      }
                      doc.text(textSignature , width / 4  , docFinalY + 85 , { align: "center" });
                      doc.text(splitTextNameSignatureLeft , width / 4 , docFinalY + 125 , { align: "center" });

                      if(this.no_stamp == false){
                        doc.setLineDashPattern([2, 2], 1);
                        doc.setDrawColor(0, 0, 0);
                        doc.rect( width / 2 - 25 , docFinalY + 70 , 50 , 50 );
                        doc.text("ประทับตรา", width / 2 , docFinalY + 100 , { align: "center" });
                      }
                      
                      doc.text(textSignature , width / 2 +  width / 4 , docFinalY + 85 , { align: "center" });
                      doc.text(splitTextNameSignatureRight , width / 2 +  width / 4 , docFinalY + 125 , { align: "center" });
                  // }

                  //เส้นคั่นสีดำ Footer
                  doc.setLineDashPattern([0, 0], 0);
                  doc.setLineWidth(0.5);
                  doc.setDrawColor(0, 0, 0);
                  doc.line(25, doc.internal.pageSize.height - 35, width - 25, doc.internal.pageSize.height - 35);
                  doc.setFontSize(13); 
                  doc.setTextColor(0, 0, 0);
                  doc.text( this.car_companies.fullname_en ?? '' , 25, doc.internal.pageSize.height - 20);
                  if(( this.businessTypeId == null) || this.businessTypeId == 1){
                      doc.text( "F-CS-0037 ฉบับแก้ไขครั้งที่ 2 วันที่อนุมัติใช้ 1 มิถุนายน 2560" , width - 25 , doc.internal.pageSize.height - 20 , { align: "right" });
                  }
              }
          }

  
          doc.save(`Invoice-${this.selected_billing?.booking[0]?.booking_no}.pdf`);
          message.success({
                content: 'สร้าง PDF เรียบร้อย',
                key: 'export_pdf',
                duration: 2
            });
            this.show_pdf = false;
        } catch (error) {
          console.error(error);
        }
      },
      async getBase64ImageFromURL(url) {
            const res = await fetch(url , {
                method: 'GET',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer'
            });
            const blob = await res.blob();
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                resolve(reader.result);
                };
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        },
      async getBankList() {
        try {
          const res = await SystemService.get_all('Bank')
          this.bankList = res
        } catch (error) {
          console.error(error);
        } 
      },
      getTypeDocument(){
        if((this.arrayBillingInQuotation[0].billing_type_id != 1 && this.arrayBillingInQuotation[0].billing_type_id != 5)
            && this.arrayBillingInQuotation[0].id == this.selected_billing.id
        ){ // มัดจำ
          this.typeDocument = "(มัดจำ)";
        } else if ((this.arrayBillingInQuotation[this.arrayBillingInQuotation.length - 1].billing_type_id == 1
          || this.selected_billing.billing_type_id == 5 ||(this.selected_billing.billing_type_id == 6&&this.selected_billing.select_type_remaining) 
          ) 
           && this.arrayBillingInQuotation[this.arrayBillingInQuotation.length - 1].id == this.selected_billing.id
           && this.arrayBillingInQuotation.length > 1
        ){ // ส่วนที่เหลือ
          this.typeDocument = "(ส่วนที่เหลือ)";
        } else if (this.arrayBillingInQuotation.length == 1 
            && this.arrayBillingInQuotation[0].billing_type_id == 1)
        { // ทั้งหมด
          this.typeDocument = "";
        }
      }
    },

    
    async mounted() { 
      let resultBid = await CompanyService.getBusinessId(this.car_companies.company_id);
      if(resultBid){
        this.businessTypeId = resultBid.data
      }
      this.getTypeDocument();
      this.getBankList();
      Swal.fire({
        title: 'เลือกการส่งออก',
        icon: 'question',
        showCancelButton: true,
        showDenyButton: true,
        confirmButtonText: 'PDF',
        denyButtonText: 'DOCX',
        confirmButtonColor: '#3085d6',
        denyButtonColor: '#d33',
      }).then((result) => {
        if (result.isConfirmed) {
          this.export_pdf();
        } else if (result.isDenied) {
          this.export_docx();
        } else {
          this.$parent.get_invoice = false;
          this.$emit('close');
        }
      })
    }
  }
  </script>
  
  <style scoped>
  #content {
    line-height: 2;
  }
  td {
    border: 1px solid black;
  }
  .job-box {
    margin: 2rem 0;
    padding: 2rem;
    border: 3px solid black;
    border-radius: 15px;
  }
  
  p {
    margin: 0;
    color: black !important;
    font-size: 18px
  }
  
  h1 {
    font-size: 32px;
    margin: 5px 0;
  }
  
  h2 {
    font-size: 24px;
    margin: 5px 0;
  }
  
  .doc-table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    border: 1px solid black;
    font-size: 20px;
  }
  
  tr,
  td {
    padding: 0px 5px;
  }
  
  .showing-for-dev {
    display: block;
    background: white;
    z-index: 9999;
    position: absolute;
    top: 0;
    left: 0;
    padding: 15px;
    border: 2px solid black;
    height: 100vh;
    overflow-y: scroll;
  }
  
  .ant-row {
    margin-bottom: 8px;
  }
  
  p {
    color: grey
  }
  
  .ant-col-12 {
    min-width: 300px;
    padding: 4px
  }
  
  .ant-select,
  .ant-picker {
    width: 100%
  }
  
  .ant-col {
    width: 100%
  }
  
  .ant-form-item {
    margin-bottom: 0;
  }
  
  .quotation-container {
    padding: 15px;
    height: 100vh;
  }
  </style>
  