<template>
    <div>
        <a-row>
            <a-col>
                <span style="font-weight: bold;">{{ divider }}</span>
            </a-col>
            <a-col style="margin-left: auto;">
                <a-dropdown>
                    <template #overlay>
                        <a-menu @click="import_export_dropdown">
                            <a-menu-item key="1">
                                <a-upload
                                    style="display: flex"
                                    v-model:file-list="import_fileList"
                                    :show-upload-list="false"
                                    name="file"
                                    method="GET"
                                    @change="import_change_price_rate"
                                >
                                    <a-button ref="import_file">
                                        <span class="material-symbols-outlined">publish</span>Import
                                    </a-button>
                                </a-upload>
                            </a-menu-item>
                            <a-menu-item key="3">
                                <a-button ref="import_file">
                                    <span class="material-symbols-outlined">download</span>Export
                                </a-button>
                            </a-menu-item>
                        </a-menu>
                    </template>
                    <a-button style="display: flex" size="large">
                        <template #icon><span class="material-symbols-outlined">Settings</span></template>
                        ตัวเลือก
                    </a-button>
                </a-dropdown>
            </a-col>
        </a-row>
        <a-table :columns="table_columns" :data-source="target_object[target_key]" :pagination="false"
            :scroll="{ x: 'calc(100px + 50%)', y: '100%' }">
            <template #bodyCell="{ column, record, index }">
                <template v-if="column.key === 'index'">
                    {{ index + 1 }}
                </template>
                <template v-if="column.key === 'part_id'">
                    {{ show_name_from_id(this.selectables['Part'], record.part_id) }}
                </template>
                <template v-if="column.key === 'distance'">
                    {{ record.distance_1 }} - {{ record.distance_2 }}
                </template>
                <template v-if="column.key === 'car_type_id'">
                    {{ drawer_fields.car_type_id.options.find((data)=>data.id == record.id)?.label }}
                </template>
                <template v-if="column.key === 'file_name'">
                    <a @click="downloadFile(record.file, record.file_name)" @click.stop>
                        <span class="download-icon">
                            {{ record?.file_name }}
                        </span>
                    </a>
                </template>
                <template v-if="column.key === 'id'">
                    <a @click="go_edit(record, index)">
                        <span style="font-size: 18px" class="material-symbols-outlined">edit</span>
                    </a>

                    <a @click="go_remove(record.id, target_modelname, index)">
                        <span style="font-size: 18px" class="material-symbols-outlined">delete</span>
                    </a>
                </template>
            </template>
        </a-table>
        <a-drawer v-model:visible="show_drawer" @afterVisibleChange="onDrawerOpen" :title="drawer_title" :closable="true" :footer="null">
            <a-form :model="drawer_create_data" name="form" autocomplete="off" layout="vertical"
                @finish="drawer_create">
                <a-row :gutter="20">
                    <a-col :span="col_span"
                        v-for="({ placeholder, required, input_type, options, label_key, modelname, accept ,col_span}, key_name) in drawer_fields">
                        <a-form-item :label="placeholder" :name="key_name"
                            :rules="required ? [{ required, message: `โปรดระบุ ${placeholder}` }] : null">
                            <div v-if="input_type == 'select'">
                                <a-select v-model:value="drawer_create_data[key_name]"
                                    @change="(value) => handleOtherChange(value, key_name)" show-search
                                    :placeholder="placeholder">
                                    <a-select-option v-for="option in options" :value="option.value">
                                        {{ option.label }}
                                    </a-select-option>
                                </a-select>
                                <a-modal v-model:visible="showOtherModal" :title="`ระบุ${placeholder}อื่นๆ`"
                                    @ok="handleModalOk" @cancel="handleModalCancel">
                                    <a-input v-model:value="otherInput" :placeholder="`ระบุ${placeholder}อื่นๆ`" />
                                </a-modal>
                            </div>
                            <a-checkbox-group v-else-if="input_type === 'checkbox_result'"
                                v-model="drawer_create_data[key_name]" :placeholder="placeholder">
                                <a-checkbox value="ผ่าน">ผ่าน</a-checkbox>
                                <a-checkbox value="ไม่ผ่าน">ไม่ผ่าน</a-checkbox>
                            </a-checkbox-group>
                            <div v-else-if="input_type == 'file'"
                                style="display: flex; flex-direction: column; align-items: flex-start; gap:1rem">
                                <div>{{ label }}</div>
                                <a v-if="drawer_create_data[key_name]" :href="drawer_create_data[key_name]"
                                    target="_blank" rel="doc">
                                    {{ drawer_create_data[`${key_name}_name`] }}
                                </a>
                                <a-upload v-else v-model:file-list="fileList" method="GET" :show-upload-list="false"
                                    :accept="accept ? accept : '*/*'" @change="upload_files($event, key_name)">
                                    <div style="display: flex; flex-direction: column; align-items: center;">
                                        <p class="ant-upload-drag-icon">
                                            <span class="material-symbols-outlined"
                                                style="font-size: 5rem;">upload_file</span>
                                        </p>
                                        อัพโหลดไฟล์
                                    </div>
                                </a-upload>
                                <a-button danger type="text" @click="(drawer_create_data[key_name] = '')"
                                    style="padding: 0; height: 18px;" v-if="drawer_create_data[key_name]">
                                    <span class="material-symbols-outlined">close</span>
                                </a-button>
                            </div>
                            <div v-else-if="key_name == 'distance_1'">
                                <a-row :gutter="0">
                                    <a-col :span="19">
                                        <a-input
                                            v-model:value="drawer_create_data[key_name]"
                                            :placeholder="placeholder"
                                            :type="input_type"
                                            style="width: 100%;"
                                        />
                                    </a-col>
                                    <a-col :span="5" style="text-align: center;">
                                        <span> - </span>
                                    </a-col>
                                </a-row>
                            </div>
                            <div v-else-if="key_name == 'start_place'">
                                    <vue-google-autocomplete
                                        :ref="key_name + selectedIndex"
                                        :id="key_name + selectedIndex"
                                        classname="form-control"
                                        :placeholder="placeholder"
                                        v-on:input="
                                            drawer_create_data[key_name] = $event.target.value
                                        "
                                        v-on:placechanged="
                                        select_stop_name($event, key_name)
                                        "
                                        types="establishment"
                                        country="th"
                                    >
                                    </vue-google-autocomplete>
                            </div>
                            <div v-else-if="key_name == 'end_place'">
                                    <vue-google-autocomplete
                                        :ref="key_name + selectedIndex"
                                        :id="key_name + selectedIndex"
                                        classname="form-control"
                                        :placeholder="placeholder"
                                        v-on:input="
                                            drawer_create_data[key_name] = $event.target.value
                                        "
                                        v-on:placechanged="
                                        select_stop_name($event, key_name)
                                        "
                                        types="establishment"
                                        country="th"
                                    >
                                    </vue-google-autocomplete>
                            </div>
                            <a-input v-else-if="input_type != 'none'" v-model:value="drawer_create_data[key_name]"
                                :placeholder="placeholder" :type="input_type" />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row justify="end">
                    <a-col>
                        <a-button v-if="drawer_button_text" html-type="submit" type="primary" size="large"
                            style="margin: 4px;text-align: right">
                            <span class="material-symbols-outlined"
                                style="font-size: 16px;margin-right: 4px">Save</span>
                            บันทึกข้อมูล
                        </a-button>
                        <a-button v-else="drawer_button_text_edit" html-type="submit" type="primary" size="large"
                            style="margin: 4px;text-align: right">
                            <span class="material-symbols-outlined" style="font-size: 16px;margin-right: 4px">Add</span>
                            บันทึกข้อมูล
                        </a-button>
                    </a-col>
                </a-row>
            </a-form>
        </a-drawer>
    </div>
</template>

<script>
import Swal from 'sweetalert2'
import SystemService from '../api/SystemService'
import DriverInfo from '../api/DriverInfo';
import XLSX from "xlsx-js-style";
import { xlxsBase64toJson } from '@/components/helpers.js'
import { message } from 'ant-design-vue';
import VueGoogleAutocomplete from "vue-google-autocomplete";


export default {
    props: {
        divider: String,
        table_columns: Array,
        target_object: Object,
        target_key: String,
        target_modelname: String,
        drawer_title: String,
        drawer_fields: Object,
        data: Array,
        drawer_button_text: String,
        drawer_button_text_edit: String,
    },
    name: 'table_create_drawer_project',
    data() {
        return {
            show_drawer: false,
            on_edit: false,
            drawer_create_data: {},
            selectables: {},
            selectedIndex: null,
            fileList: [],
            currentFieldKey: null,
            showOtherModal: false,
            otherInput: '',
            thaiToEnglishMap:{},
            import_fileList: [],
        }
    },
    components: {
        VueGoogleAutocomplete,
    },
    methods: {
        async go_edit(record, index) {
            this.drawer_create_data = record
            this.on_edit = true
            this.show_drawer = true
            this.selectedIndex = index
        },
        onDrawerOpen() {
            this.$nextTick(() => {
                const startPlaceInput = document.getElementById('start_place' + this.selectedIndex);
                const endPlaceInput = document.getElementById('end_place' + this.selectedIndex);
                if (this.drawer_create_data.start_place != null||this.drawer_create_data.end_place != null) {
                    if(startPlaceInput){
                        startPlaceInput.value = this.drawer_create_data.start_place;
                    }
                    if(endPlaceInput){
                        endPlaceInput.value = this.drawer_create_data.end_place;
                    }
                }else{
                    startPlaceInput.value = '';
                    endPlaceInput.value = '';
                    this.on_edit = false;
                }
            });
        },
        async go_remove(id, type, index) {
            if (id) {
                Swal.fire({
                    title: `คุณต้องการลบข้อมูลนี้ใช่หรือไม่?`,
                    text: "คุณจะไม่สามารถกู้คืนข้อมูลนี้ได้หากลบแล้ว!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'ใช่, ลบข้อมูลนี้!',
                    cancelButtonText: 'ยกเลิก'
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        await SystemService.delete_all(type, id)
                        this.target_object[this.target_key].splice(index, 1)
                        Swal.fire(
                            'ลบข้อมูลเรียบร้อย!',
                            'ข้อมูลของคุณถูกลบแล้ว',
                            'success'
                        )
                    }
                })
            } else {
                Swal.fire({
                    title: `คุณต้องการลบข้อมูลนี้ใช่หรือไม่?`,
                    text: "คุณจะไม่สามารถกู้คืนข้อมูลนี้ได้หากลบแล้ว!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'ใช่, ลบข้อมูลนี้!',
                    cancelButtonText: 'ยกเลิก'
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        this.target_object[this.target_key].splice(index, 1)
                        Swal.fire(
                            'ลบข้อมูลเรียบร้อย!',
                            'ข้อมูลของคุณถูกลบแล้ว',
                            'success'
                        )
                    }
                })
            }
        },
        async drawer_create(data) {
            // console.log("drawer_create: ",data)
            if (this.on_edit && this.selectedIndex != null) {
                const { id } = this.drawer_create_data
                console.log("check",data);
                if (id) {
                    await SystemService.update_all(this.target_modelname, { data: data }, id)
                    Swal.fire(
                        'แก้ไขข้อมูลเรียบร้อย!',
                        'ข้อมูลของคุณถูกแก้ไขแล้ว',
                        'success'
                    )
                } else {
                    this.target_object[this.target_key][this.selectedIndex] = data
                }
            } else {
                this.target_object[this.target_key] ? this.target_object[this.target_key].push(data) :
                    this.target_object[this.target_key] = [data]
            }
            // console.log("this.target_object: ", this.target_object)
            this.drawer_create_data = {}
            this.show_drawer = false
            this.on_edit = false
            this.selectedIndex = null
        },
        async get_default_fields() {
            // console.log("this.drawer_fields", this.drawer_fields)
            for (var key in this.drawer_fields) {
                const { input_type, modelname } = this.drawer_fields[key]
                if (input_type == 'select') {
                    this.selectables[modelname] = await SystemService.get_all(modelname)
                }
            }
        },
        printCheck(data){
            console.log("cac",data)
        },
        show_name_from_id(array, id) {
            return array.find(el => el.id == id)?.name
        },
        upload_files(e, key) {
            try {
                if (e.file.status != 'remove') {
                    this.getBase64(e.file.originFileObj, key)
                    if (e.file.status == 'uploading' && !this.isLoading) {
                        this.$message.loading('กำลังอัปโหลดไฟล์...');
                        this.isLoading = true;
                    }
                    if (e.file.status == 'error') {
                        this.$message.success('อัปโหลดไฟล์สำเร็จ')
                        this.isLoading = false;
                    }
                    return true
                }else{
                    const vue = this
                    vue.drawer_create_data[`${key}_name`] = null
                }
            } catch (error) {
                console.log(error)
            }
        },
        async getBase64(file, key) {
            if (key) {
                const vue = this
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                    vue.drawer_create_data[key] = reader.result
                    vue.drawer_create_data[`${key}_name`] = file.name
                };
            } else {
                return await new Promise((resolve, reject) => {
                    if (file) {
                        if (typeof file == 'string') {
                            resolve(file)
                        } else {
                            const reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onload = () => resolve(reader.result);
                            reader.onerror = error => reject(error);
                        }
                    }
                });
            }
        },
        async select_stop_name(e, target, is_car) {
            const { latitude, longitude } = e;
            if (target && !is_car) {
                this.drawer_create_data[target] = document.getElementById(target+this.selectedIndex).value.split(",")[0];
            } else if (is_car) {
                this.drawer_create_data[target]  = document
                .getElementById(target)
                .value.split(",")[0];
            } else {
                this.add_stop.lat = latitude;
                this.add_stop.lng = longitude;
                this.add_stop.name = document.getElementById("map").value.split(",")[0];
            }
        },
        async getBase64ImageFromURL(url) {
            const res = await fetch(url, {
                method: 'GET',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer'
            });
            const blob = await res.blob();
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    resolve(reader.result);
                };
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        },
        async export_file() {
            this.$message.loading({ content: "กำลังโหลด...", key: "loading" });

            // Define headers for the Excel file
            const headersColumn = [
                { header: "ต้นทาง", key: "start_place" }, 
                { header: "ปลายทาง", key: "end_place" }, 
                { header: "คำอธิบาย", key: "descriptions" }, 
                { header: "H-4WH", key: "H_4WH" },
                { header: "H-4WJ", key: "H_4WJ" },
                { header: "H-6WH", key: "H_6WH" },
                { header: "H-4WT", key: "H_4WT" },
                { header: "H-4WD", key: "H_4WD" },
                { header: "H-10WH", key: "H_10WH" },
                { header: "H-10WT", key: "H_10WT" },
                { header: "H-10WD", key: "H_10WD" },
                { header: "H-18WH", key: "H_18WH" },
                { header: "H-22WH", key: "H_22WH" },
                { header: "C-4WH", key: "C_4WH" },
                { header: "C-4WJ", key: "C_4WJ" },
                { header: "C-6WH", key: "C_6WH" },
                { header: "C-4WT", key: "C_4WT" },
                { header: "C-4WD", key: "C_4WD" },
                { header: "C-10WH", key: "C_10WH" },
                { header: "C-10WT", key: "C_10WT" },
                { header: "C-10WD", key: "C_10WD" },
                { header: "C-18WH", key: "C_18WH" },
                { header: "C-22WH", key: "C_22WH" },
                { header: "จำนวนชั่วโมงรวม", key: "total_hours" }, 
                { header: "ระยะทางรวม", key: "total_distance" }, 
            ];

            const workBook = XLSX.utils.book_new();
            const workSheet = XLSX.utils.aoa_to_sheet([headersColumn.map(h => h.header)]);

            headersColumn.forEach((header, index) => {
                const cellAddress = XLSX.utils.encode_cell({ c: index, r: 0 });
                let cell = workSheet[cellAddress];
                if (!cell) {
                    cell = { v: "" };
                    workSheet[cellAddress] = cell;
                }

                const bgColor = ["ต้นทาง", "ปลายทาง", "คำอธิบาย", "จำนวนชั่วโมงรวม", "ระยะทางรวม"].includes(header.header)
                    ? "#f0b474"  
                    : "#ffe4d4";  

                cell.s = {
                    font: { bold: true },
                    fill: { fgColor: { rgb: bgColor.replace("#", "") } },
                    border: {
                        bottom: { style: "thin" },
                        right: { style: "thin" },
                        left: { style: "thin" },
                        top: { style: "thin" },
                    },
                    alignment: { horizontal: "center", vertical: "center" },
                };
            });


            workSheet['!cols'] = headersColumn.map(() => ({ wch: 25 }));

            XLSX.utils.book_append_sheet(workBook, workSheet, "Report");

            XLSX.writeFile(workBook, "PriceRate.xlsx");
            this.$message.success({ content: "สร้างไฟล์สำเร็จ", key: "loading" });
        },
        import_export_dropdown(e) {
            const { key } = e
            if (key == '1') {
                
            } else if (key == '2') {
                this.export_file()
            } else if (key == '3') {
                this.export_file()
            } 
        },
        import_change_price_rate(event, field) {
            const file = event.file.originFileObj;

            const validFileTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];
            if (!validFileTypes.includes(file.type)) {
                message.error({ content: 'โปรดอัปโหลดไฟล์ Excel เท่านั้น', key: 'import', duration: 1.5 });
                return;
            }

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = async () => {
                var result = xlxsBase64toJson(reader.result.split(',')[1]);
                if(this.target_key == 'priceRate'){
                    this.thaiToEnglishMap = {
                        "ต้นทาง": "start_place",
                        "ปลายทาง": "end_place",
                        "คำอธิบาย": "descriptions",
                        "H-4WH": "H_4WH",
                        "H-4WJ": "H_4WJ",
                        "H-6WH": "H_6WH",
                        "H-4WT": "H_4WT",
                        "H-4WD": "H_4WD",
                        "H-10WH": "H_10WH",
                        "H-10WT": "H_10WT",
                        "H-10WD": "H_10WD",
                        "H-18WH": "H_18WH",
                        "H-22WH": "H_22WH",
                        "C-4WH": "C_4WH",
                        "C-4WJ": "C_4WJ",
                        "C-6WH": "C_6WH",
                        "C-4WT": "C_4WT",
                        "C-4WD": "C_4WD",
                        "C-10WH": "C_10WH",
                        "C-10WT": "C_10WT",
                        "C-10WD": "C_10WD",
                        "C-18WH": "C_18WH",
                        "C-22WH": "C_22WH",
                        "จำนวนชั่วโมงรวม": "total_hours",
                        "ระยะทางรวม": "total_distance",
                    };
                    result = this.translateJSON(result);
                }
                message.loading({ content: 'กำลังนำเข้าข้อมูล...', key: 'import', duration: 0 });
                if (result && event.file.status === 'done') {
                    const resultArray = Array.isArray(result) ? result : Object.values(result);
                    if (this.target_object[this.target_key]) {
                        this.target_object[this.target_key].push(...resultArray);
                    } else {
                        this.target_object[this.target_key] = [...resultArray];
                    }
                    message.success({ content: `นำเข้าข้อมูลสำเร็จ!`, key: 'import', duration: 1.5 });
                } else {
                    message.error({ content: 'นำเข้าข้อมูลไม่สำเร็จ!', key: 'import', duration: 1.5 });
                }
                document.querySelector("input[type='file']").value = '';
            };

            reader.onerror = () => {
                message.error({ content: 'เกิดข้อผิดพลาดในการอ่านไฟล์', key: 'import', duration: 1.5 });
            };
        },
        translateJSON(json) {
            const translated = {};

            for (const key in json) {
                const translatedKey = this.thaiToEnglishMap[key] || key; // Translate key if possible, else keep original
                const value = json[key];

                // If the value is an object, recursively translate it
                if (typeof value === 'object' && value !== null) {
                    translated[translatedKey] = this.translateJSON(value);
                } else {
                    translated[translatedKey] = value;
                }
            }

            return translated;
        },
        downloadFile(url, fileName) {
            fetch(url)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.blob();
                })
                .then(blob => {
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName || 'default_filename.pdf'; // Set default filename if fileName is not valid
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(link.href);
                })
                .catch(error => {
                    console.error('Error downloading file:', error);
                });
        },
        handleOtherChange(value, key) {
            if (value === 'OTHER') {
                this.currentFieldKey = key;
                this.showOtherModal = true;
            }
        },
        handleModalOk() {
            this.drawer_create_data[this.currentFieldKey] = this.otherInput;
            this.showOtherModal = false;
            this.otherInput = '';
        },
        handleModalCancel() {
            this.showOtherModal = false;
            this.otherInput = '';
        },
    },

    async mounted() {
        await this.get_default_fields()
    }
}

</script>

<style scoped>
.ant-row {
    margin: 0
}
</style>