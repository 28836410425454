import httpClient from './httpClient';
import { io } from 'socket.io-client';

class SocketService {
  constructor() {
    this.socket = null;
    this.baseURL = httpClient({ requiresAuth: true }).defaults.baseURL;
    this.room = null;
  }


  connect(room = null) {
    if (!this.socket) {
      this.socket = io(this.baseURL);

      this.socket.on('connect', () => {
        console.log('Connected to socket server');
        if (room) {
          this.joinRoom(room);
        }
      });

      this.socket.on('reconnect', () => {
        console.log('Reconnected to socket server');
        if (this.room) {
          this.joinRoom(this.room);
        }
      });

      this.socket.on('connect_error', (error) => {
        console.error('Connection error:', error);
      });
    }
  }

  joinRoom(room) {
    if (this.socket && room) {
      this.socket.emit('join_room', room);
      this.room = room;
      console.log(`Joined room: ${room}`);
    }
  }

  // Adjusted onBroadcast to handle both the default 'broadcast' event and room-specific broadcasts
  onBroadcast(callback, room = null) {
    if (this.socket) {
      if (room) {
        this.socket.on(`broadcast_${room}`, (message) => {
          if (callback) callback(message);
        });
      } else {
        this.socket.on('broadcast', (message) => {
          if (callback) callback(message);
        });
      }
    }
  }

  offBroadcast(room = null) {
    if (this.socket) {
      if (room) {
        this.socket.off(`broadcast_${room}`);
      } else {
        this.socket.off('broadcast');
      }
    }
  }
  // onAdminChatPanel(callback, room) {
  //   if (this.socket && room) {
  //     if (room) {
  //       this.socket.on(`{room}`, (message) => {
  //         if (callback) callback(message);
  //       });
  //     }
  //   }
  // }

  sendMessage(room, message) {
    if (this.socket && room) {
      this.socket.emit('send_message', { room, message });
    }
  }

  markAsRead(room, role, message) {
    if (this.socket && room) {
      this.socket.emit('mark_as_read', { room, role, message });
    }
  }

  onMessageRead(callback) {
    if (this.socket) {
      this.socket.on('message_read', (data) => {
        if (callback) {
          callback(data);
        }
      });
    }
  }


  leaveRoom(room) {
    if (this.socket && room) {
      this.socket.emit('leave_room', room);
      if (this.room === room) {
        this.room = null;
      }
    }
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
      console.log("Disconnect Socket");
      this.socket = null;
    }
  }
}

const socketService = new SocketService();
export default socketService;
