<template>
  <div style="height: 100vh; padding: 15px">
    <div class="app-content">
      <a-card title="จัดการใบงาน">
        <a-row>
          <a-col :span="6">
            <a-input-search
              v-model:value="search_input"
              placeholder="ค้นหาใบงาน"
              enter-button="Search"
            />
          </a-col>
          <a-col :span="5" style="margin-left: 1rem">
            <a-input
              v-model:value="search_input"
              placeholder="ข้อมูลใบงาน"
            />
          </a-col>
          <a-range-picker
            v-model:value="datetime"
            @change="handleTableChange"
            style="margin-left: 1rem"
          />
          <a-button @click="clear_search" type="dashed" style="margin-left: 1rem"
            >CLEAR</a-button
          >
          <a-col style="margin-left: 1rem; display: flex; align-items: center">
            <a-switch v-model:checked="show_need_cars" />
            <span style="margin-left: 5px">จัดรถไม่ครบ</span>
          </a-col>
          <a-select
            v-model:value="search_booking_status"
            label-in-value
            style="width: 135px; margin-left: 1rem"
            placeholder="สถานะการจัดรถ"
            @change="handleTableChange"
          >
            <a-select-option :value="0">ครบ</a-select-option>
            <a-select-option :value="1">ไม่ครบ</a-select-option>
            <a-select-option :value="2">เกิน</a-select-option>
          </a-select>
          <a-col style="margin-left: 1rem; display: flex; align-items: center">
            <a-switch v-model:checked="require_rollcall" />
            <span style="margin-left: 5px">อนุญาติเฉพาะรถผ่าน RollCall</span>
          </a-col>
        </a-row>

        <a-tabs v-model:activeKey="activeKey">
        <a-tab-pane 
          v-for="tab in filteredTabs.filter(t => !(business && [0, 1, 3,  5, 7, 9, 10, 13 , 16 ,17, 18,19 ].includes(t.status)))"
          :key="tab.status"
        >
          <template #tab>
            <a-badge :count="count_by_status(tab.status)">
              <div class="google-icon">
                <span class="material-symbols-outlined">{{ tab.icon }}</span>
                <span>{{ tab.title }}</span>
              </div>
            </a-badge>
          </template>
        </a-tab-pane>
      </a-tabs>

        <a-table
          rowKey="id"
          :columns="booking_columns"
          :data-source="filtered_table(booking_list)"
          bordered
          size="small"
          :scroll="{ x: 'calc(100px + 50%)', y: '500px' }"
          :row-selection="{
            type: 'radio',
            onSelect: select_row,
          }"
          :pagination="{
            total: pagination.total,
            current: pagination.onpage,
            pageSize: pagination.perPage,
          }"
          @change="handleTableChange"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'active_job'">
              <a-button
                type="danger"
                @click="activate_job(record)"
                :disabled="record.active_job == 1 || record.booking_cars.length == 0"
                >{{ record.active_job ? "แจ้งงานแล้ว" : "แจ้งงาน" }}
                </a-button>
            </template>
            <template v-if="column.key === 'id' && record[column.key]">
              <div>
                <a-tooltip>
                  <template #title>อัพเดทข้อมูล</template>
                  <a @click="show_update_progress(record.id)"
                    ><span style="font-size: 14px" class="material-symbols-outlined"
                      >update</span
                    ></a
                  >
                </a-tooltip>
                <a-divider type="vertical"></a-divider>
                <a-tooltip>
                  <template #title>แก้ไข</template>
                  <a @click="go_to_edit_quotation(record.id)"
                    ><span style="font-size: 14px" class="material-symbols-outlined"
                      >drive_file_rename_outline</span
                    ></a
                  >
                </a-tooltip>
                <a-divider type="vertical"></a-divider>
                <a-tooltip>
                  <template #title>ยกเลิก</template>
                  <a @click="remove_booking(record.id)"
                    ><span style="font-size: 18px" class="material-symbols-outlined"
                      >delete</span
                    ></a
                  >
                </a-tooltip>
              </div>
            </template>
          </template>
        </a-table>
      </a-card>
    </div>

    <a-modal
      v-model:visible="update_progress_modal"
      @ok="update_progress"
    >
    <template #title>
      Booking ID : {{ selected_booking?.booking_no }}
    </template>
      <a-card>
        <a-form :model="selected_booking" layout="vertical">
          <a-form-item label="สถานะ">
            <a-select v-model:value="selected_booking.status" placeholder="เลือกสถานะ">
              <a-select-option
                v-for="{ status, title } in sortedBookingStatusLista"
                :key="status"
                :value="status"
              >
                {{ title }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </a-card>
    </a-modal>

    <JobOrder
      v-if="job_order"
      :booking_detail="export_data"
      :user_name="user_name"
      :selected_driver_id="selected_driver_id"
      @close="job_order = false"
    />
    <BookingUpdatedPopup
      :isVisible="BookingUpdatedVisible"
      :booking_updated="bookingUpdatedData"
      @close="closeBookingUpdatePopup"/>
  </div>

</template>

<script>
import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
dayjs.extend(buddhistEra);
import BookingService from "../../api/BookingService";
import CompanyService from "../../api/CompanyService";
import { exportXLSXFile } from "@/components/helpers.js";
import ReportService from "../../api/ReportService";
import Swal from "sweetalert2";
import JobOrder from "@/components/modals/job_order.vue";
import Utility from "../../utility";
import SystemService from "../../api/SystemService";
import { message } from "ant-design-vue";
import th from "dayjs/locale/th";
import BookingUpdatedPopup from "@/components/modals/booking_update_popup.vue";
import * as xlsx from "xlsx-js-style";
import UserService from "../../api/UserService";
import { co, s } from "@fullcalendar/core/internal-common";
import socketService from "../../api/SocketService";

export default {
  components: { JobOrder ,BookingUpdatedPopup},
  props: {
    user_name: String,
  },
  name: "ManageWorksheet",
  data() {
    return {
      socketService,
      showModalActiveJob: false,
      companyId: null,
      ranges: {
        'Today': [dayjs(), dayjs()]  // Use dayjs() to get today's date
      },
      filteredTabs: [],
      customOrder: [0, 1, 2, 11, 8, 9, 10, 3, 5, 6, 14, 15],
      model_form: {
          key: null // Initialize with your appropriate key
      },
      //BookingUpdatedPopup
      booking_business: false,
      business: false,
      bookingStatusData: [], 
      bookingStatusCarData: [], 
      BookingUpdatedVisible: false,
      bookingUpdatedData: null,
      bookingUpdated: {
        booking_no: '', 
        total_cars_expense: null, 
      },
      //TM department
      tmDepartment: null,

      tabsCustomer: [
        { icon: 'menu', title: 'ทั้งหมด', status: null, activate_job: 1, roles: ['adminTM', 'preDelivery','planner','rollCall','others','Accounting','hr','info','transport','repair'] },
        { icon: 'list', title: 'จัดรถแล้ว', status: 2, roles: ['others','planner','Accounting','hr','info','transport','repair'] },
        { icon: 'list', title: 'ยกเลิกการเดินทาง', status: 6, roles: ['others','rollCall','planner','Accounting','hr','info','transport','repair'] },
        { icon: 'list', title: 'รับงาน', status: 8, roles: ['rollCall','others',,'planner','Accounting','hr','info','transport','repair'] },
        { icon: 'list', title: 'แจ้งงาน', status: 11, roles: ['others','rollCall','planner','Accounting','hr','info','transport','repair'] },
        { icon: 'list', title: 'ปฏิเสธงาน', status: 14, roles: ['planner', 'rollCall', 'others','Accounting','hr','info','transport','repair'] },
        { icon: 'list', title: 'ตรวจสอบ', status: 15, roles: ['adminTM','others','Accounting','hr','info','transport','repair'] },
      ],
      showWarningTollWays: false,
      showWarningGasRefills: false,
      showWarningExpenses: false,

      editTollWay: null,
      newTollWay: false,
      newGas: false,
      newExpense: false,

      selected_booking: {
        booking_cars: [
          {
            plate_no: null,
          },
        ],
      },

      editable: Utility.get_permission_editable_with_key("booking"),
      booking_list: [],
      pagination: {
        total: 0,
        onpage: 1,
        perPage: 20,
      },
      ///filter
      search_input: null,
      datetime: null,
      search_booking_status: null,
      search_plate_no: null,

      activeKey: null,
      show_need_cars: false,
      search_booking_status: null,
      require_rollcall: false,
      selected_driver_id: null,
      tag_option: {
        bookingType: {
          1: { txt: "เที่ยวเดียว (ขาเดียว)", color: "pink" },
          2: { txt: "ไป-กลับ", color: "red" },
          3: { txt: "รายวัน (เต็มวัน)", color: "orange" },
          4: { txt: "รายวัน (ครึ่งวัน)", color: "green" },
          5: { txt: "เฉพาะวัน (ส่ง-รับ วันกลางไม่ใช้รถ)", color: "cyan" },
          6: { txt: "เฉพาะวัน (ระบุวัน)", color: "blue" },
          7: { txt: "ใช้รถทุกวัน", color: "purple" },
          8: { txt: "รายเดือน", color: "green" },
        },
        status: [
          { txt: "แนะนำการจัดรถ", color: "processing" },//0
          { txt: "รอจัดรถ", color: "processing" },//1
          { txt: "จัดรถแล้ว", color: "warning" },//2
          { txt: "ส่งสินค้าแล้วเสร็จ", color: "warning" },//3
          { txt: "รับลูกค้าแล้วเสร็จ", color: "success" },//4
          { txt: "จบงาน", color: "success" },//5
          { txt: "ยกเลิกการเดินทาง", color: "error" },//6
          { txt: "กำลังเดินทาง", color: "warning" },//7 
          { txt: "รับงาน", color: "success" },//8 
          { txt: "เริ่มงาน", color: "success" },//9 
          { txt: "รับสินค้าขึ้นยานพาหนะ", color: "success" },//10 
          { txt: "แจ้งงาน", color: "warning" },//11
          { txt: "รับของ", color: "success" },//12
          { txt: "รับของเสร็จสิ้น", color: "success" },//13
          { txt: "ปฏิเสธงาน", color: "warning" },//14
          { txt: "ตรวจสอบ", color: "success" },//15
        ],
        statuscar: {
    0: { txt: "แนะนำการจัดรถ", color: "processing", next: 0 },
    1: { txt: "รอจัดรถ", color: "processing", next: 1 },
    2: { txt: "จัดรถแล้ว", color: "warning", next: 2 },
    11: { txt: "แจ้งงาน", color: "warning", next: 11 },
    9: { txt: "รถออกจากลานจอด", color: "green", next: 9 },
    16: { txt: "รถถึงต้นทาง", color: "purple", next: 16 },
    10: { txt: "กำลังขึ้นของ", color: "orange", next: 10 },
    17: { txt: "อยู่ระหว่างขนส่ง", color: "pink", next: 17 },
    19: { txt: "รถถึงปลายทาง", color: "blue", next: 19 },
    18: { txt: "กำลังลงของ", color: "yellow", next: 18 },
    5: { txt: "จบงาน", color: "red", next: 5 },
    14: { txt: "ปฏิเสธงาน", color: "warning", next: 14 },
    15: { txt: "ตรวจสอบ", color: "success", next: 15 },

    3: { txt: "ถึงจุดรับรถแล้ว", color: "warning", next: 3 },
    6: { txt: "ยกเลิกการเดินทาง", color: "error", next: 6 },
    7: { txt: "กำลังเดินทาง", color: "warning", next: 7 },
    8: { txt: "รับงาน", color: "success", next: 8 },
    12: { txt: "รับของ", color: "success", next: 12 },
    13: { txt: "รับของเสร็จสิ้น", color: "success", next: 13 },
}


      },
      gas: {
        bill_photo_show: [],
        indexGas:null,
        orderPos:null,
      },
      bill_photo_show_source:"",
      toll_way_photo:[],
      billing_color: {
        1: "green",
        2: "red",
        3: "purple",
        4: "yellow",
      },
      selected_booking: null,
      export_data: null,
      show_monthly_report: false,
      loading: false,
      show_timeline: false,
      job_order: false,

      /* update_progress_modal */
      update_progress_modal: false,

      /* update_booking_car_status_modal */
      update_booking_car_status_modal: false,

      /* total_cars_expense_modal */
      total_cars_expense_modal: false,

      pay_method_list: [],
      expense_log: {
        toll_ways: null,
        gas_refills: null,
        extras: null,
      },
      expense_logs: [
        {
          id: 1,
          name: "อนุมัติ",
        },
        {
          id: 2,
          name: "ไม่อนุมัติ",
        },
      ],
      show_gas_refill_drawer: false,
      create_gas_refill: {
        bill_photo:[]
      },
      gas_station_list: [],
      booking_status_list:[],
      countTabStatus: null,

      show_create_toll_way: false,
      create_toll_way: {},

      show_create_expense: false,
      create_expense: {},
      expense_list: [],
      car_list: [],

      total_car_expenses_collapse: null,
      show_export_excel: false,
      export_excel: {
        time_start: null,
        time_end: null,
        plate_no: null,
      },
      monthly_report_date : {
        report_time_start: null,
        report_time_end: null,
        report_plate_no: null,
        report_company: null,
        datetime:null,
      },
      selectedTypeReport : null,
      selectedTypeReportClient: null,
      bookingCarExpenseModal: false,
      bookingCarExpenseData:[],
      userBusinessId:null,
      tabsCheck:[],
      tabsStatusOn:[],
      company_list:[],
    };
  },
  computed: {
    sortedBookingStatusList() { 
      const status = this.booking_status_list;
      this.tmDepartment = JSON.parse(localStorage.getItem('user_profile')).department;
      let statusFilter;

      if (this.userBusinessId == 2) {
        statusFilter = this.tabsProduct.filter(tab => {
          const excludeStatuses = [null, 2,0, 1, 3, 7, 12, 13]; 
          return !excludeStatuses.includes(tab.status) || 
            (this.selected_booking && tab.status === this.selected_booking.status);
        });
        const customOrder = [11,8,  9, 16, 10, 17, 19, 18, 5, 6, 14, 15];
        statusFilter = statusFilter.sort((a, b) => customOrder.indexOf(a.status) - customOrder.indexOf(b.status));
      } else {
        statusFilter = this.tabsCustomer;
        statusFilter = this.tabsCustomer.filter(tab => 
            ![4, 7, 12, 13].includes(tab.status)
          );

          const customOrder = [null, 0,1,2,11,8,9,10,3,5,6,14, 15];
        statusFilter = statusFilter.sort((a, b) => customOrder.indexOf(a.status) - customOrder.indexOf(b.status));
      }

      let statusList = statusFilter.map(tab => {
        const statusItem = status.find(s => s.status == tab.status) || { status: null, name: 'ทั้งหมด' };
        if (statusItem) {
          tab.name = statusItem.name;
        }
        return tab;
      });

      if (this.tmDepartment != null) {
        statusList = statusList.filter(tab => tab.roles.includes(this.tmDepartment));
      } else {
        statusList = statusList.filter(tab => tab.roles.includes('others'));
      }
      return statusList;
    },
    sortedBookingStatusLista() { 
      const status = this.booking_status_list;
      this.tmDepartment = JSON.parse(localStorage.getItem('user_profile')).department;
      let statusFilter;

      if (this.userBusinessId == 2) {
        statusFilter = this.tabsProduct.filter(tab => {
          const excludeStatuses = [0, 1, 3, 7,8, 12, 13,  11, 9, 16, 10, 17, 19, 18, 5, 6, 14, 15]; 
          return !excludeStatuses.includes(tab.status) || 
            (this.selected_booking && tab.status === this.selected_booking.status);
        });
        const customOrder = [null, 2,];
        statusFilter = statusFilter.sort((a, b) => customOrder.indexOf(a.status) - customOrder.indexOf(b.status));
      } else {
        statusFilter = this.tabsCustomer;
        statusFilter = this.tabsCustomer.filter(tab => 
            ![4, 7, 12, 13].includes(tab.status)
          );

          const customOrder = [null, 0,1,2,11,8,9,10,3,5,6,14, 15];
        statusFilter = statusFilter.sort((a, b) => customOrder.indexOf(a.status) - customOrder.indexOf(b.status));
      }

      let statusList = statusFilter.map(tab => {
        const statusItem = status.find(s => s.status == tab.status) || { status: null, name: 'ทั้งหมด' };
        if (statusItem) {
          tab.name = statusItem.name;
        }
        return tab;
      });

      if (this.tmDepartment != null) {
        statusList = statusList.filter(tab => tab.roles.includes(this.tmDepartment));
      } else {
        statusList = statusList.filter(tab => tab.roles.includes('others'));
      }
      return statusList;
    },
    licensePlateRules() {
      if (this.selectedTypeReport === null||this.selectedTypeReport == false) {
        return [{ required: true, message: 'โปรดเลือกทะเบียนรถ' }]; // Apply rule if 'SelectedCar'
      }
      return []; // No rules otherwise
    },
    CompanyClientRules() {
      if (this.selectedTypeReportClient === null||this.selectedTypeReportClient == false) {
        return [{ required: true, message: 'โปรดเลือกลูกค้า/บริษัท' }]; // Apply rule if 'SelectedCar'
      }
      return []; // No rules otherwise
    },
    booking_columns(){
      const booking_columns = [
            {
              title: 'QTC',
              dataIndex: 'booking_no',
              key: 'booking_no',
              width: 160,
              sorter: {
                compare: (a, b) => b.booking_no - a.booking_no,
                multiple: 1,
              },
              align: 'center',
            },
            {
              title: 'สถานะใบงาน',
              dataIndex: '',
              key: '',
              width: 150,
              align: 'center',
              sorter: {
                
              },
            },
            {
              title: 'วันที่ - เวลารับสินค้า',
              dataIndex: '',
              key: '',
              width: 160,
              align: 'center',
              sorter: {

              },
            },
            {
              title: 'วันที่ - เวลาส่งสินค้า',
              dataIndex: '',
              key: '',
              width: 160,
              align: 'center',
              sorter: {
                
              },
            },
            {
              title: 'จำนวนวัน',
              dataIndex: 'countDays',
              key: 'countDays',
              width: 100,
              align: 'center',
              sorter: {
                
              },
            },
            {
              title: 'สถานะรถ',
              dataIndex: '',
              key: '',
              width: 150,
              align: 'center',
            },
            {
              title: 'ต้นทาง',
              dataIndex: '',
              key: '',
              width: 160,
              align: 'center',
            },
            {
              title: 'ปลายทาง',
              dataIndex: '',
              key: '',
              width: 160,
              align: 'center',
            },
            {
              title: 'สถานะงาน',
              dataIndex: 'active_job',
              key: 'active_job',
              width: 150,
              align: 'center',
            },
            {
              title: 'บริษัทรถ',
              dataIndex: '',
              key: '',
              width: 230,
              align: 'left',
            },
            {
              title: 'ผู้ดูแลใบงานฝ่ายปฏิบัติการ',
              dataIndex: '',
              key: '',
              width: 230,
              align: 'center',
            },
            {
              title: 'ชื่อ-นามสกุล ผู้ขับ',
              dataIndex: '',
              key: '',
              width: 200,
              align: 'center',
            },
            {
              title: 'ทะเบียนรถ',
              dataIndex: '',
              key: '',
              width: 150,
              align: 'center',
            },
            {
              title: 'ลูกค้า/บริษัท',
              dataIndex: 'client_name',
              key: 'client_name',
              width: 230,
              align: 'center',
            },
            {
              title: 'โปรเจกต์ลูกค้า',
              dataIndex: '',
              key: '',
              width: 200,
              align: 'center',
              ellipsis: true,
            },
            {
              title: 'แก้ไขล่าสุด',
              dataIndex: 'updated_at',
              key: 'updated_at',
              width: 160,
              align: 'center',
            },
            {
              title: 'ตัวเลือก',
              dataIndex: 'id',
              key: 'id',
              width: 160,
              fixed: 'right',
              align: 'center',
            },
          ]
      return booking_columns
    },
  },
  methods: {
    async go_to_edit_quotation(id) {
      if (this.car_list && this.car_list[0]) {
        if (this.userBusinessId == 2) {
          this.booking_business = true;
        } else {
          this.booking_business = false;
        }
      } else {
        console.error('car_list or car_list[0] is undefined');
        this.booking_business = false;
      }
      this.$router.push(`/app/quotation/${id}?manage_worksheet=true`);
    },
    connectSocket(){
        this.socketService.connect();
        this.socketService.joinRoom('booking');
        this.socketService.onBroadcast((message) => {
            if (message) {
              this.init_bookings_update(this.activeKey);
              this.fetchBookingStatus();
            }
        },'booking');
    },
    filterTabs() {
      const status = this.booking_status_list;
      const customOrder = [0, 1, 2, 11, 8, 9, 10, 3, 4, 5, 6, 14, 15];
      this.tmDepartment = JSON.parse(localStorage.getItem("user_profile")).department;

      let tabsToFilter = [];
      // Filter tabs based on the company ID
      if (this.get_company_id() === true) {
        tabsToFilter = this.tabsProduct;
      } else {
        tabsToFilter = this.tabsCustomer;
      }
      let filteredTabs = tabsToFilter
        .filter(tab => tab.status !== 4 && tab.status !== 7 && tab.status !== 12 && tab.status !== 13)
        .map(tab => {
          const statusItem = status.find(s => s.status === tab.status);
          if (statusItem) {
            tab.title = statusItem.name;
          }
          return tab;
        })
        .sort((a, b) => customOrder.indexOf(a.status) - customOrder.indexOf(b.status));
      // Further filter by department
      this.tabsStatusOn = []
      if (this.tmDepartment != null && this.tmDepartment !== undefined) {
        filteredTabs = filteredTabs.filter(tab => tab.roles.includes(this.tmDepartment));
      } else {
        filteredTabs = filteredTabs.filter(tab => tab.roles.includes("others"));
      }

      this.tabsStatusOn = []
      filteredTabs.forEach(data => {
          this.tabsStatusOn.push(data.status);
      });
      // Update the filteredTabs data property
      this.filteredTabs = filteredTabs;
    },
    async getCompanyId() {
      let data = await UserService.get_user_profile();
      return data.company_id;
    },
    async getFilteredTabs() {
      const status = this.booking_status_list;
      const customOrder = [0, 1, 2, 11, 8, 9, 10, 3, 4, 5, 6, 14, 15];
      this.tmDepartment = JSON.parse(localStorage.getItem("user_profile")).department;

      let data = await UserService.get_user_profile();
      let tabsToFilter;

      if ( this.userBusinessId == 2) {
        tabsToFilter = this.tabsProduct;
      } else {
        tabsToFilter = this.tabsCustomer;
      }

      let filteredTabs = tabsToFilter
        .filter(tab => {
          if (this.userBusinessId == 1 ) {
              return tab.status != 4 && tab.status != 7 && tab.status != 12 && tab.status != 13;
            }
          return tab.status != 4 && tab.status != 7 && tab.status != 12 && tab.status != 13;
        })
        .map(tab => {
          const statusItem = status.find(s => s.status === tab.status);
          if (statusItem) {
            tab.title = statusItem.name;
          }
          return tab;
        })
        .sort((a, b) => customOrder.indexOf(a.status) - customOrder.indexOf(b.status));
      if (this.tmDepartment != null) {
        filteredTabs = filteredTabs.filter(tab => tab.roles.includes(this.tmDepartment));
      } else {
        filteredTabs = filteredTabs.filter(tab => tab.roles.includes("others"));
      }

      return filteredTabs;
    },
    get_company_id() {
      if(this.userBusinessId == 2){
        return true
      }else{
        return this.userBusinessId
      }
      return null
    },
    filtered_table(array) {
      return array
        .filter(({ booking_cars, booking_car_types }) => {
          if (this.search_booking_status == null) return true;
          if (this.search_booking_status.value == 0) {
            var result = booking_cars.length == this.geBookingCarMax(booking_car_types);
            return result;
          } else if (this.search_booking_status.value == 1) {
            var result = booking_cars.length < this.geBookingCarMax(booking_car_types);
            return result;
          } else if (this.search_booking_status.value == 2) {
            var result = booking_cars.length > this.geBookingCarMax(booking_car_types);
            return result;
          }
        })
        ?.filter(({ booking_cars, booking_car_types }) => {
          return this.show_need_cars
            ? booking_cars?.length <
                booking_car_types
                  .map(({ quantity }) => (quantity ? quantity : 0))
                  .reduce((a, b) => a + b, 0)
            : true;
        })
        ?.filter(
          ({ booking_no, start_location_name, end_location_name, client_name }) => {
            return this.search_input
              ? start_location_name?.includes(this.search_input) ||
                  end_location_name?.includes(this.search_input) ||
                  booking_no.includes(this.search_input) ||
                  client_name.includes(this.search_input)
              : true;
          }
        )
        ?.filter(({ status, finished }) => {
      if (this.activeKey != null) {
        /// finished check
        if (this.activeKey == 100) {
          return finished;
        } else if (this.activeKey == 15) {
          return status == this.activeKey;
        } else if (this.activeKey == 8) {
          // รวมสถานะ 1, 2, 3, 4 ในแท็บ 8
          return [  3,  5, 7,8, 9, 10, 13, 16, 17 ,18,19 ].includes(status) && !finished;
        } else {
          return status == this.activeKey && !finished;
        }
      } else {
        return true;
      }
    })

        ?.filter((item) => {
          if (this.datetime) {
            var search_time_start = dayjs(this.datetime[0]).format("YYYY-MM-DD");
            var search_time_end = dayjs(this.datetime[1]).format("YYYY-MM-DD");
            return (
              (dayjs(item.time_start).unix() >= dayjs(search_time_start).unix() &&
                dayjs(item.time_end).unix() <= dayjs(search_time_end).unix()) ||
              (dayjs(item.time_start).unix() >= dayjs(search_time_start).unix() &&
                dayjs(item.time_end).format("YYYY-MM-DD") ==
                  dayjs(search_time_end).format("YYYY-MM-DD"))
            );
          }
          return true;
        })
        ?.filter((item)=>{
          if(item != undefined){
            return this.tabsStatusOn.includes(item.status)
          }else{
            return true
          }
        })
        // .sort((a, b) => dayjs(b.time_start).unix() - dayjs(a.time_start).unix());
        .sort((a, b) => {
          const current_date = dayjs();
          const time_start = Math.abs(dayjs(a.time_start).diff(current_date));
          const time_end = Math.abs(dayjs(b.time_start).diff(current_date));
          return time_start - time_end;
        });
    },
    handleIconClick(action, payload, event) {
        event.stopPropagation();
        if (action === 'preview') {
            this.showPreview(event , payload);
        } else if (action === 'delete') {
            this.fileList = [];
        }
    },
    getPlateNoByCarId(car_id) {
      const record = this.filtered_table(this.booking_list);
      let plateNo = '';
      record.forEach((item) => {
        item.cars.forEach(car => {
          if (car.id === car_id) {
            plateNo = car.plate_no;
          }
        });
      });
      return plateNo;
    },
    handledateTimeChange() {
        this.filtered_table(this.booking_list);
    },
    openBookingUpdatePopup(record) {
      this.BookingUpdatedVisible = true;
      this.bookingUpdatedData = record.booking_updated;
    },
    closeBookingUpdatePopup() {
      this.BookingUpdatedVisible = false;
    },
    handlePlateNoChange(value) {
      this.search_plate_no = value;
    },
    handleTableChange(pagination, filters, sorter) {
      this.pagination.onpage = pagination.current;
      this.pagination.perPage = pagination.pageSize;

      this.init_bookings();
    },
    render_date(date) {
      return dayjs(date).format("DD/MM/YYYY HH:mm");
    },
    async remove_booking(id) {
      Swal.fire({
        title: "ยืนยันการลบ",
        text: "คุณต้องการลบงานนี้ใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await SystemService.update_all("Booking", { data: { progress: 0, progress_1: 0 } }, id);
          this.init_bookings();
          Swal.fire("ลบเรียบร้อย", "ลบงานเรียบร้อย", "success");
        }
      });
    },
    activate_job(record) {  
      const { id, booking_cars } = record;
      const currentTimestamp = dayjs().format("YYYY-MM-DD HH:mm:ss");
      const hasNoDriverId = booking_cars.some(car => !car.driver_id);
      if (hasNoDriverId) {
          this.showModalActiveJob = true;
          return;
      }
      this.$message.loading({ content: "กำลังโหลด...", key: "loading" });
      SystemService.update_all("Booking", { data: { active_job: 1 } }, id).then((res) => {
        this.init_bookings();
        this.$message.success({ content: "โหลดข้อมูลสำเร็จ", key: "loading" });
        booking_cars.map(({ id }) => {
          SystemService.update_all("BookingCar", { data: { active_car: true, status: 11, active_car_timestamp: currentTimestamp } }, id).then((res) => {
            this.init_bookings();
            this.$message.success({ content: "โหลดข้อมูลสำเร็จ", key: "loading" });
          });
        });
      })
    },
    show_update_progress(id) {
      this.update_progress_modal = true;
      this.selected_booking = this.booking_list.find((booking) => booking.id === id);
    },
    async update_progress() {
      await SystemService.update_all("Booking",{ data: { status: this.selected_booking.status } },this.selected_booking.id).then((res) => {
        this.update_progress_modal = false;
        this.init_bookings();
        Swal.fire("อัพเดทเรียบร้อย", "อัพเดทสถานะเรียบร้อย", "success");
      });
    },
    async update_booking_car_status() {
      const bookingCarsStatus = this.selected_booking.booking_cars[0].status;
      const bookingCarId = this.selected_booking.booking_cars[0].id;
      await SystemService.update_all("BookingCar",{ data: { status: bookingCarsStatus } },bookingCarId).then((res) => {
        this.update_booking_car_status_modal = false;
        this.init_bookings(this.activeKey);
        Swal.fire("อัพเดทเรียบร้อย", "อัพเดทสถานะเรียบร้อย", "success");
      });
    },
    count_by_status(status) {
    if (!this.bookingStatusData || !this.bookingStatusCarData) return 0;
    if (status === null) {
        return this.bookingStatusData.reduce((sum, item) => sum + item.count, 0);
    }
    if (status >= 0 && status <= 2) {
        const found = this.bookingStatusData.find(item => item.status === status);
        return found ? found.count : 0;
    }
    if (status >= 3 && status <= 15) {
        const found = this.bookingStatusCarData.find(item => item.status === status);
        return found ? found.count : 0;
    }
        return 0; 
    },
    clear_search() {
      this.search_input = null;
      this.datetime = null;
      this.search_booking_status = null;
      this.init_bookings();
    },
    select_row(selectedRowKeys) {
      this.selected_booking = selectedRowKeys;
    },
    select_filter(key, status) {
      console.log({ key, status });
    },
    render_time(time) {
      return dayjs(time).format("DD/MM/YYYY HH:mm");
    },
    async init_bookings(activeKey) {
      this.$message.loading({ content: "กำลังโหลด...", key: "loading" });
      let datas = null
      let datas2 = null
      if(this.datetime){
        let time_start = dayjs(this.datetime[0]).format("YYYY-MM-DD")
        let time_end = dayjs(this.datetime[1]).format("YYYY-MM-DD")
        if (activeKey == 3 || activeKey == 4 || activeKey == 5 || activeKey == 6 || activeKey == 8 || activeKey == 9 || activeKey == 10 || activeKey == 11 || activeKey == 14 || activeKey == 15) {
          datas = await BookingService.get_bookings_and_booking_cars(
            this.pagination.onpage,
            this.pagination.perPage,
            1,
            {
              time_start: time_start,
              time_end: time_end,
            }
          );
        } else {
          datas = await BookingService.get_bookings(
            this.pagination.onpage,
            this.pagination.perPage,
            1,
            {
              time_start: time_start,
              time_end: time_end,
            }
          );
        }
      } else {
        if (activeKey == 3 || activeKey == 4 || activeKey == 5 || activeKey == 6 || activeKey == 8 || activeKey == 9 || activeKey == 10 || activeKey == 11 || activeKey == 14 || activeKey == 15) {
          datas = await BookingService.get_bookings_and_booking_cars(
            this.pagination.onpage,
            this.pagination.perPage,
            1
          );
        } else {
          datas = await BookingService.get_bookings(
            this.pagination.onpage,
            this.pagination.perPage,
            1
          );
        }
      }

      const combinedData = {
        data: [...(datas ? datas.data : []), ...(datas2 ? datas2.data : [])],
        total: (datas ? datas.total : 0) + (datas2 ? datas2.total : 0),
        page: this.pagination.onpage,
        perPage: this.pagination.perPage,
      };

      // Calculate the actual total based on returned data
      const actualTotal = (this.pagination.onpage - 1) * this.pagination.perPage + combinedData.data.length;

      // Adjust the total count based on the actual data length
      if (combinedData.data.length < this.pagination.perPage) {
        combinedData.total = actualTotal;
      }

      this.pagination = {
        total: combinedData.total,
        onpage: combinedData.page,
        perPage: combinedData.perPage,
      };
      this.booking_list = combinedData.data;
      this.booking_list = this.booking_list?.sort(
        (a, b) => dayjs(a.time_start).unix() - dayjs(b.time_start).unix()
      );
      this.require_rollcall =
        JSON.parse(localStorage.getItem("companyData")).require_rollcall == 1;
      this.$message.success({ content: "โหลดข้อมูลสำเร็จ", key: "loading" });
    },
    async init_bookings_update(activeKey) {
      let datas = null
      let datas2 = null
      if(this.datetime){
        let time_start = dayjs(this.datetime[0]).format("YYYY-MM-DD")
        let time_end = dayjs(this.datetime[1]).format("YYYY-MM-DD")
        if (activeKey == 3 || activeKey == 4 || activeKey == 5 || activeKey == 6 || activeKey == 8 || activeKey == 9 || activeKey == 10 || activeKey == 11 || activeKey == 14 || activeKey == 15) {
          datas = await BookingService.get_bookings_and_booking_cars(
            this.pagination.onpage,
            this.pagination.perPage,
            1,
            {
              time_start: time_start,
              time_end: time_end,
            }
          );
        } else {
          datas = await BookingService.get_bookings(
            this.pagination.onpage,
            this.pagination.perPage,
            1,
            {
              time_start: time_start,
              time_end: time_end,
            }
          );
        }
      } else {
        if (activeKey == 3 || activeKey == 4 || activeKey == 5 || activeKey == 6 || activeKey == 8 || activeKey == 9 || activeKey == 10 || activeKey == 11 || activeKey == 14 || activeKey == 15) {
          datas = await BookingService.get_bookings_and_booking_cars(
            this.pagination.onpage,
            this.pagination.perPage,
            1
          );
        } else {
          datas = await BookingService.get_bookings(
            this.pagination.onpage,
            this.pagination.perPage,
            1
          );
        }
      }

      const combinedData = {
        data: [...(datas ? datas.data : []), ...(datas2 ? datas2.data : [])],
        total: (datas ? datas.total : 0) + (datas2 ? datas2.total : 0),
        page: this.pagination.onpage,
        perPage: this.pagination.perPage,
      };

      // Calculate the actual total based on returned data
      const actualTotal = (this.pagination.onpage - 1) * this.pagination.perPage + combinedData.data.length;

      // Adjust the total count based on the actual data length
      if (combinedData.data.length < this.pagination.perPage) {
        combinedData.total = actualTotal;
      }

      this.pagination = {
        total: combinedData.total,
        onpage: combinedData.page,
        perPage: combinedData.perPage,
      };
      this.booking_list = combinedData.data;
      this.booking_list = this.booking_list?.sort(
        (a, b) => dayjs(a.time_start).unix() - dayjs(b.time_start).unix()
      );
      this.require_rollcall =
        JSON.parse(localStorage.getItem("companyData")).require_rollcall == 1;
    },
    
    async get_company() {
			const company = await UserService.get_user_profile();
            const businessType = await CompanyService.getBusinessId(company.company_id)
            if ( businessType.data === 2)
            {
                this.business = true
            }
        },
    async fetchBookingStatus() {
          try {
              const company = await UserService.get_user_profile();
              const bookingStatus = await SystemService.bookingStatus(company.company_id); 
              this.bookingStatusData = bookingStatus; // เก็บใน bookingData
          } catch (error) {
              console.error('Error fetching booking status:', error.message);
          }
      },
  },
  watch: {
    activeKey(newVal) {
      this.init_bookings(newVal);
    },
    require_rollcall(newVal) {
      if (newVal != null) {
        const { company_id } = JSON.parse(localStorage.getItem("user_profile"));
        SystemService.update_all(
          "Company",
          { data: { require_rollcall: newVal } },
          company_id
        ).then((res) => {
          const original = JSON.parse(localStorage.getItem("companyData"));
          original.require_rollcall = newVal;
          localStorage.setItem("companyData", JSON.stringify(original));
        });
      }
    },
  },
  unmounted() {
      // this.leaveRoom('booking');
  },
  async mounted() {
    this.connectSocket();
    this.companyId = await this.getCompanyId();
    this.filteredTabs = await this.getFilteredTabs();
    let data = await UserService.get_user_profile();
    let userCompanyId = data.company_id;
    let bid = await CompanyService.getBusinessId(userCompanyId);
    this.userBusinessId = bid.data
    this.filterTabs();
    this.filteredTabs;
    this.init_bookings();
    this.pay_method_list = await SystemService.get_all("PayMethod");
    this.car_list = await SystemService.get_all("Car");
    this.company_list = await SystemService.get_all("Client");
    this.booking_status_list = await SystemService.get_all("BookingStatusText");
    this.get_company();
    await this.fetchBookingStatus();
  },
};
</script>
<style>
  .ant-table-thead .center-header {
    text-align: center; /* จัดตำแหน่งหัวคอลัมน์ให้อยู่ตรงกลาง */
  }
</style>